import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx';

@Injectable({
  providedIn: 'root'
})
export class ExcelService {

  constructor() { }
  
    exportAsExcelFile(data: any[], fileName: string): void {
      // Convert data to worksheet
      const worksheet = XLSX.utils.json_to_sheet(data);
      
      // Create workbook and append worksheet
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
  
      // Write workbook to file
      XLSX.writeFile(workbook, `${fileName}.xlsx`);
    }
    
}
