import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/Authenticaton/auth.service';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
import { MessagingService } from 'src/app/service/messaging.service';
import Swal from 'sweetalert2';
import { UserService } from '../user/user.service';
import { AddressComponent } from '../address/address.component';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DatePipe } from '@angular/common';

import { EMPTY, Subject, throwError } from 'rxjs';
import { catchError, switchMap, takeUntil } from 'rxjs/operators';
import { ExcelService } from 'src/app/service/excel.service';
// import { AddressComponent } from '../../address/address.component';
// import { UserService } from '../../user/user.service';

@Component({
  selector: 'app-log-inout-reports',
  templateUrl: './log-inout-reports.component.html',
  styleUrls: ['./log-inout-reports.component.scss']
})
export class LogInoutReportsComponent implements OnInit {

  displayedColumns: string[] = ['locationId', 'latitude', 'workedhours', 'login data', 'longitude', 'logout', 'loginAddress', 'logoutAddress'];
  dataSource = new MatTableDataSource;
  isOrg: boolean = false
  isLoading: boolean = false
  @ViewChild(MatPaginator) paginator: any;
  currentPageOffset: number = 0;
  org: any;
  users: any;
  user1: any;
  allUsers: any[] = [];
  userId: any;
  userName: any;
  employeeId: any;
  detailsFilterForm: FormGroup;
  isAttendance: boolean;
  attendanceType: string;
  private unsubscribe$: Subject<void> = new Subject<void>();
  employeeData: string;
  employeeNumber: any;

  user: any;
  searchEmployees: any[] = [];
  employeeNotFound: boolean = false;
  employeelistsub: any;
  userTypeId: any;
  p: any;
  isPageRefreshed: boolean = false;
  getTodaysDate: string;
  getYesterdayDate: string;
  fromDateValidation: boolean = false;
  toDateValidation: boolean = false;
  dataExport: any[] = [];
  exportingData: any[] = [];
  exported: any;
  filterData: any[] = [];

  constructor(public dialog: MatDialog, private gs: UserService, private Gs: GlobalserviceService, private auth: AuthService, private router: Router, private messagingService: MessagingService,
    private formbuilder: FormBuilder,
    private datepipe: DatePipe,
    private es: ExcelService,

  ) { }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  ngOnInit(): void {
    this.isPageRefreshed = performance.navigation.type === performance.navigation.TYPE_RELOAD;
    console.log('Is page refreshed:', this.isPageRefreshed);

    this.detailsFilterForm = this.formbuilder.group({
      employeeId: [null],
      fromDate: [null],
      toDate: [null]
    })
    let user = this.auth.getLocalStorageData();
    console.log('user', user);
    this.isAttendance = user.organization.attendance;
    this.attendanceType = user.organization.attendanceType;
    this.org = user.organization.organizationId;
    this.user1 = user.userId;
    console.log(this.user1);
    this.userTypeId = user.userType[0].userTypeId;
    console.log(this.userTypeId);
    this.employeeId = user?.employee?.employeeId;
    console.log('empId', this.employeeId);

    // this.users=user.userType[0].userTypeId
    this.users = [];
    user.userType.forEach((type) => {
      this.users.push(type.userTypeId);
      this.allUsers.push(type);
    });
    console.log(user);
    console.log(this.org, this.users)

    // this.Gs.get_location(this.user1).subscribe((a: any) => {
    //   this.dataSource = new MatTableDataSource(a);
    //   console.log(a)
    //   this.dataSource.paginator = this.paginator;
    // })
    this.fetchReports();
    if (this.auth.getLocal_Org()?.organizationId) {
      let id = this.auth.getLocal_Org().organizationId
      // console.log(userdata .userId)
      let userdata = this.auth.getLocalStorageData();
      this.Gs.get_user_org(id).subscribe((a: any) => {
        a.map(
          (e: any) => {
            if (userdata.userType[0].userTypeName == "BUDDY USER") {
              if (e.userId != userdata.userId && (e.userType[0].userTypeName != "PRIMARY USER" && userdata.userType[0].userTypeName == "BUDDY USER")
                && e.buddyUser?.userId == userdata.userId) {
                console.log(e);
                this.searchEmployees.push(e);
              }
            }
            else {
              if (e.userId != userdata.userId) {
                console.log(e)
                this.searchEmployees.push(e);
              }
            }
          }
        )
      })
    } else {

      let id = this.auth.getLocalStorageData()?.userId;
      this.Gs.get_primary_users_user(id).subscribe((a: any) => {
        a.map(
          (e: any) => {
            if (e.userId != id) {
              //  console.log(e)
              this.searchEmployees.push(e);
            }
          }
        )
        // this.dataSource = new MatTableDataSource(this.userlist);
        // this.dataSource.paginator = this.paginator;
        // console.log(a)
      })
    }
  }


  formatTime(totalSeconds: number | null | undefined): string {
    if (totalSeconds == null || isNaN(totalSeconds)) return '00:00:00';

    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    return `${this.pad(hours)}:${this.pad(minutes)}:${this.pad(seconds)}`;
  }

  private pad(value: number): string {
    return value.toString().padStart(2, '0');
  }

  noEmployeeFound() {
    this.employeeNotFound = false;
    this.employeeId = undefined;
    // this.employee = undefined;
    this.employeeNotFound = false;
  }

  sdate(sdate) {

  }

  clear() {
    this.detailsFilterForm.reset();

    this.toDateValidation = false;
    this.fromDateValidation = false;
    if (this.userId === 1 && this.userName === 'PRIMARY USER' && this.isAttendance && this.attendanceType == 'Non Shift') {
      this.Gs.getNonShiftLoginDetailsforPrimaryUser(this.org).subscribe((d: any) => {
        console.log(d)


        // this.dataSource = new MatTableDataSource(d);
        // if (d && typeof d === 'object' && !Array.isArray(d)) {
        //   d = Object.values(d); // Convert object values to an array
        // }

        // if (d && typeof d !== 'object') {

        this.dataSource = new MatTableDataSource(d);
        // }
        setTimeout(() => {
          this.dataSource.paginator = this.paginator;
        }, 2);
        this.dataSource.filterPredicate = (data: any, filter: string) => {
          const dataStr = (data.loginTime + data.employee.user.name
            + data.loginDate + data.logoutTime).toLowerCase();
          return dataStr.includes(filter);
        };

        this.isLoading = false;
      })
    }

    if (this.userId === 1 && this.userName === 'PRIMARY USER' && this.isAttendance && this.attendanceType == 'Shift') {
      this.Gs.getShiftLoginDetailsforPrimaryUser(this.org).subscribe((d: any) => {
        console.log(d)


        // this.dataSource = new MatTableDataSource(d);
        // if (d && typeof d === 'object' && !Array.isArray(d)) {
        //   d = Object.values(d); // Convert object values to an array
        // }

        // if (d && typeof d !== 'object') {

        this.dataSource = new MatTableDataSource(d);
        // }
        setTimeout(() => {
          this.dataSource.paginator = this.paginator;
        }, 2);
        this.dataSource.filterPredicate = (data: any, filter: string) => {
          const dataStr = (data.loginTime + data.employee.user.name
            + data.loginDate + data.logoutTime).toLowerCase();
          return dataStr.includes(filter);
        };

        this.isLoading = false;
      })
    }

    if (this.userId === 2 && this.userName === 'BUDDY USER' && this.isAttendance && this.attendanceType == 'Non Shift') {
      console.log(this.user1);
      this.Gs.getNonShiftLoginDetailsforBuddyuser(this.user1).subscribe((d: any) => {
        console.log(d)



        this.dataSource = new MatTableDataSource(d);

        setTimeout(() => {
          this.dataSource.paginator = this.paginator;
        }, 2);
        this.dataSource.filterPredicate = (data: any, filter: string) => {
          const dataStr = (data.loginTime + data.employee.user.name
            + data.loginDate + data.logoutTime).toLowerCase();
          return dataStr.includes(filter);
        };

        this.isLoading = false;
      })
    }


    if (this.userId === 2 && this.userName === 'BUDDY USER' && this.isAttendance && this.attendanceType == 'Shift') {
      console.log(this.user1);
      this.Gs.getShiftLoginDetailsforBuddyuser(this.user1).subscribe((d: any) => {
        console.log(d)



        this.dataSource = new MatTableDataSource(d);

        setTimeout(() => {
          this.dataSource.paginator = this.paginator;
        }, 2);
        this.dataSource.filterPredicate = (data: any, filter: string) => {
          const dataStr = (data.loginTime + data.employee.user.name
            + data.loginDate + data.logoutTime).toLowerCase();
          return dataStr.includes(filter);
        };

        this.isLoading = false;
      })
    }

    if (this.userId === 3 && this.userName === 'SECONDARY USER' && this.isAttendance && this.attendanceType == 'Non Shift') {
      this.Gs.getNonShiftLoginDetailsforEmployee(this.employeeId).subscribe((d: any) => {
        console.log(d)

        this.dataSource = new MatTableDataSource(d);

        setTimeout(() => {
          this.dataSource.paginator = this.paginator;
        }, 2);
        this.dataSource.filterPredicate = (data: any, filter: string) => {
          const dataStr = (data.loginTime + data.employee.user.name
            + data.loginDate + data.logoutTime).toLowerCase();
          return dataStr.includes(filter);
        };
        this.isLoading = false;

      })
    }



    if (this.userId === 3 && this.userName === 'SECONDARY USER' && this.isAttendance && this.attendanceType == 'Shift') {
      this.Gs.getShiftLoginDetailsforEmployee(this.employeeId).subscribe((d: any) => {
        console.log(d)

        this.dataSource = new MatTableDataSource(d);

        setTimeout(() => {
          this.dataSource.paginator = this.paginator;
        }, 2);
        this.dataSource.filterPredicate = (data: any, filter: string) => {
          const dataStr = (data.loginTime + data.employee.user.name
            + data.loginDate + data.logoutTime).toLowerCase();
          return dataStr.includes(filter);
        };
        this.isLoading = false;

      })
    }

  }

  editLocation(element: any) {
    this.router.navigate(['edit-location', element.locationId])
  }


  viewaddress(element: any) {
    const dialogRef = this.dialog.open(AddressComponent, {
      disableClose: false,
      width: '400px',
      data: element,
    });
  }

  startDateChange(date: any) {
    console.log('dta', date);
    let Sdate: any = this.datepipe.transform(this.detailsFilterForm.value.fromDate, 'YYYY-MM-dd');
    let Edate: any = this.datepipe.transform(this.detailsFilterForm.value.toDate, 'YYYY-MM-dd');

    if (Sdate > Edate) {
      this.fromDateValidation = true;
      return;
    } else {
      this.fromDateValidation = false;
    }

  }

  endDateChange(date: any) {
    console.log('dta', date);
    let Sdate: any = this.datepipe.transform(this.detailsFilterForm.value.fromDate, 'YYYY-MM-dd');
    let Edate: any = this.datepipe.transform(this.detailsFilterForm.value.toDate, 'YYYY-MM-dd');

    if (Sdate > Edate) {
      this.fromDateValidation = true;
      return;
    } else {
      this.fromDateValidation = false;
    }

    if (Edate) {
      this.toDateValidation = false;
    }

  }

  searchFilters() {
    console.log('formValues', this.detailsFilterForm.value.employeeId, this.detailsFilterForm.value.date);


    let Sdate = this.datepipe.transform(this.detailsFilterForm.value.fromDate, 'YYYY-MM-dd');
    let Edate = this.datepipe.transform(this.detailsFilterForm.value.toDate, 'YYYY-MM-dd');

    console.log('Sdate', Sdate);
    console.log('Edate', Edate);

    if (Sdate != null && Edate == null) {
      this.toDateValidation = true;
      return;
    }


    if (Sdate == null && Edate != null) {
      this.fromDateValidation = true;
      return;
    }

    if (Sdate != null && Edate != null && this.detailsFilterForm.value.employeeId == null) {
      //--------------------Primary User---------------------------
      if (this.userId === 1 && this.userName === 'PRIMARY USER' && this.isAttendance && this.attendanceType == 'Non Shift') {
        this.Gs.searchFromDateToDateNonShiftBased(this.org, Sdate, Edate).subscribe((d: any) => {
          console.log(d);
          this.filterData = [];
          this.filterData.push(d);
          this.dataSource = new MatTableDataSource(d);

          setTimeout(() => {
            this.dataSource.paginator = this.paginator;
          }, 2);
          this.dataSource.filterPredicate = (data: any, filter: string) => {
            const dataStr = (data.loginTime + data.employee.user.name
              + data.loginDate + data.logoutTime).toLowerCase();
            return dataStr.includes(filter);
          };
          this.isLoading = false;

        })
      }
      if (this.userId === 1 && this.userName === 'PRIMARY USER' && this.isAttendance && this.attendanceType == 'Shift') {
        this.Gs.searchFromDateToDateShiftBased(this.org, Sdate, Edate).subscribe((d: any) => {
          // console.log(d)
          this.filterData = [];
          this.filterData.push(d);
          this.dataSource = new MatTableDataSource(d);

          setTimeout(() => {
            this.dataSource.paginator = this.paginator;
          }, 2);
          this.dataSource.filterPredicate = (data: any, filter: string) => {
            const dataStr = (data.loginTime + data.employee.user.name
              + data.loginDate + data.logoutTime).toLowerCase();
            return dataStr.includes(filter);
          };
          this.isLoading = false;

        })
      }

      //--------------------Buddy User---------------------------
      if (this.userId === 2 && this.userName === 'BUDDY USER' && this.isAttendance && this.attendanceType == 'Non Shift') {
        this.Gs.filterUseridfromDateToDateNonShiftBased(this.user1, Sdate, Edate).subscribe((d: any) => {
          console.log(d)
          this.filterData = [];
          this.filterData.push(d);
          this.dataSource = new MatTableDataSource(d);

          setTimeout(() => {
            this.dataSource.paginator = this.paginator;
          }, 2);
          this.dataSource.filterPredicate = (data: any, filter: string) => {
            const dataStr = (data.loginTime + data.employee.user.name
              + data.loginDate + data.logoutTime).toLowerCase();
            return dataStr.includes(filter);
          };
          this.isLoading = false;

        })
      }
      if (this.userId === 2 && this.userName === 'BUDDY USER' && this.isAttendance && this.attendanceType == 'Shift') {
        this.Gs.filterUseridfromDateToDateShiftBased(this.user1, Sdate, Edate).subscribe((d: any) => {
          console.log(d)
          this.filterData = [];
          this.filterData.push(d);
          this.dataSource = new MatTableDataSource(d);

          setTimeout(() => {
            this.dataSource.paginator = this.paginator;
          }, 2);
          this.dataSource.filterPredicate = (data: any, filter: string) => {
            const dataStr = (data.loginTime + data.employee.user.name
              + data.loginDate + data.logoutTime).toLowerCase();
            return dataStr.includes(filter);
          };
          this.isLoading = false;

        })
      }

      //------------------Secondary User ---------------------------

      if (this.userId === 3 && this.userName === 'SECONDARY USER' && this.isAttendance && this.attendanceType == 'Non Shift') {
        const trimmedSdate = Sdate.trim();
        const trimmedEdate = Edate.trim();
        this.Gs.filterEmpIdfromDateToDateNonShiftBased(this.employeeId, trimmedSdate, trimmedEdate).subscribe((d: any) => {
          console.log(d)
          this.filterData = [];
          this.filterData.push(d);
          this.dataSource = new MatTableDataSource(d);

          setTimeout(() => {
            this.dataSource.paginator = this.paginator;
          }, 2);
          this.dataSource.filterPredicate = (data: any, filter: string) => {
            const dataStr = (data.loginTime + data.employee.user.name
              + data.loginDate + data.logoutTime).toLowerCase();
            return dataStr.includes(filter);
          };
          this.isLoading = false;

        })
      }
      if (this.userId === 3 && this.userName === 'SECONDARY USER' && this.isAttendance && this.attendanceType == 'Shift') {
        this.Gs.filterEmpIdfromDateToDateShiftBased(this.employeeId, Sdate, Edate).subscribe((d: any) => {
          console.log(d)
          this.filterData = [];
          this.filterData.push(d);
          this.dataSource = new MatTableDataSource(d);

          setTimeout(() => {
            this.dataSource.paginator = this.paginator;
          }, 2);
          this.dataSource.filterPredicate = (data: any, filter: string) => {
            const dataStr = (data.loginTime + data.employee.user.name
              + data.loginDate + data.logoutTime).toLowerCase();
            return dataStr.includes(filter);
          };
          this.isLoading = false;

        })
      }

    }
    else if (Sdate != null && Edate != null && this.detailsFilterForm.value.employeeId != null) {

      if (this.userId === 1 && this.userName === 'PRIMARY USER' && this.isAttendance && this.attendanceType == 'Non Shift') {
        this.Gs.searchfromDateToDateandEmployeeNonShiftBased(this.org, this.user, Sdate, Edate).subscribe((d: any) => {
          console.log(d)
          this.filterData = [];
          this.filterData.push(d);
          this.dataSource = new MatTableDataSource(d);

          setTimeout(() => {
            this.dataSource.paginator = this.paginator;
          }, 2);
          this.dataSource.filterPredicate = (data: any, filter: string) => {
            const dataStr = (data.loginTime + data.employee.user.name
              + data.loginDate + data.logoutTime).toLowerCase();
            return dataStr.includes(filter);
          };
          this.isLoading = false;

        })
      }
      if (this.userId === 1 && this.userName === 'PRIMARY USER' && this.isAttendance && this.attendanceType == 'Shift') {
        this.Gs.searchfromDateToDateandEmployeeShiftBased(this.org, this.user, Sdate, Edate).subscribe((d: any) => {
          console.log(d);
          this.filterData = [];
          this.filterData.push(d);
          this.dataSource = new MatTableDataSource(d);

          setTimeout(() => {
            this.dataSource.paginator = this.paginator;
          }, 2);
          this.dataSource.filterPredicate = (data: any, filter: string) => {
            const dataStr = (data.loginTime + data.employee.user.name
              + data.loginDate + data.logoutTime).toLowerCase();
            return dataStr.includes(filter);
          };
          this.isLoading = false;

        })
      }

      //--------------------Buddy User---------------------------
      if (this.userId === 2 && this.userName === 'BUDDY USER' && this.isAttendance && this.attendanceType == 'Non Shift') {
        this.Gs.filterUserIdfromDateToDateEmpNumNonShiftBased(this.user1, this.user, Sdate, Edate).subscribe((d: any) => {
          console.log(d)
          this.filterData = [];
          this.filterData.push(d);
          this.dataSource = new MatTableDataSource(d);

          setTimeout(() => {
            this.dataSource.paginator = this.paginator;
          }, 2);
          this.dataSource.filterPredicate = (data: any, filter: string) => {
            const dataStr = (data.loginTime + data.employee.user.name
              + data.loginDate + data.logoutTime).toLowerCase();
            return dataStr.includes(filter);
          };
          this.isLoading = false;

        })
      }
      if (this.userId === 2 && this.userName === 'BUDDY USER' && this.isAttendance && this.attendanceType == 'Shift') {
        this.Gs.filterUserIdfromDateToDateEmpNumShiftBased(this.user1, this.user, Sdate, Edate).subscribe((d: any) => {
          console.log(d)
          this.filterData = [];
          this.filterData.push(d);
          this.dataSource = new MatTableDataSource(d);

          setTimeout(() => {
            this.dataSource.paginator = this.paginator;
          }, 2);
          this.dataSource.filterPredicate = (data: any, filter: string) => {
            const dataStr = (data.loginTime + data.employee.user.name
              + data.loginDate + data.logoutTime).toLowerCase();
            return dataStr.includes(filter);
          };
          this.isLoading = false;

        })
      }

    } else if (Sdate == null && Edate == null && this.detailsFilterForm.value.employeeId != null) {

      if (this.userId === 1 && this.userName === 'PRIMARY USER' && this.isAttendance && this.attendanceType == 'Non Shift') {
        this.Gs.searchEmployeeNumberornamebasedNonshiftandOrg(this.org, this.user).subscribe((d: any) => {
          console.log(d)
          this.filterData = [];
          this.filterData.push(d);
          this.dataSource = new MatTableDataSource(d);

          setTimeout(() => {
            this.dataSource.paginator = this.paginator;
          }, 2);
          this.dataSource.filterPredicate = (data: any, filter: string) => {
            const dataStr = (data.loginTime + data.employee.user.name
              + data.loginDate + data.logoutTime).toLowerCase();
            return dataStr.includes(filter);
          };
          this.isLoading = false;

        })
      }
      if (this.userId === 1 && this.userName === 'PRIMARY USER' && this.isAttendance && this.attendanceType == 'Shift') {
        this.Gs.searchEmployeeNumberornamebasedShiftandOrg(this.org, this.user).subscribe((d: any) => {
          console.log(d)
          this.filterData = [];
          this.filterData.push(d);
          this.dataSource = new MatTableDataSource(d);

          setTimeout(() => {
            this.dataSource.paginator = this.paginator;
          }, 2);
          this.dataSource.filterPredicate = (data: any, filter: string) => {
            const dataStr = (data.loginTime + data.employee.user.name
              + data.loginDate + data.logoutTime).toLowerCase();
            return dataStr.includes(filter);
          };
          this.isLoading = false;

        })
      }

      //--------------------Buddy User---------------------------
      if (this.userId === 2 && this.userName === 'BUDDY USER' && this.isAttendance && this.attendanceType == 'Non Shift') {
        this.Gs.filterUserIdEmpNumNonShiftBased(this.user1, this.user).subscribe((d: any) => {
          console.log(d)
          this.filterData = [];
          this.filterData.push(d);
          this.dataSource = new MatTableDataSource(d);

          setTimeout(() => {
            this.dataSource.paginator = this.paginator;
          }, 2);
          this.dataSource.filterPredicate = (data: any, filter: string) => {
            const dataStr = (data.loginTime + data.employee.user.name
              + data.loginDate + data.logoutTime).toLowerCase();
            return dataStr.includes(filter);
          };
          this.isLoading = false;

        })
      }
      if (this.userId === 2 && this.userName === 'BUDDY USER' && this.isAttendance && this.attendanceType == 'Shift') {
        this.Gs.filterUserIdEmpNumShiftBased(this.user1, this.user).subscribe((d: any) => {
          console.log(d)
          this.filterData = [];
          this.filterData.push(d);
          this.dataSource = new MatTableDataSource(d);

          setTimeout(() => {
            this.dataSource.paginator = this.paginator;
          }, 2);
          this.dataSource.filterPredicate = (data: any, filter: string) => {
            const dataStr = (data.loginTime + data.employee.user.name
              + data.loginDate + data.logoutTime).toLowerCase();
            return dataStr.includes(filter);
          };
          this.isLoading = false;

        })
      }

    }

  }


  todaysDate() {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0');
    const yyyy = today.getFullYear();
    // return `${yyyy}-${mm}-${dd}`; 

    this.getTodaysDate = `${yyyy}-${mm}-${dd}`


    //--------------------Primary User---------------------------
    if (this.userId === 1 && this.userName === 'PRIMARY USER' && this.isAttendance && this.attendanceType == 'Non Shift') {
      this.detailsFilterForm.get('fromDate')?.setValue(this.getTodaysDate);
      this.detailsFilterForm.get('toDate')?.setValue(this.getTodaysDate);

      this.Gs.searchDateNonShiftBased(this.org, this.getTodaysDate).subscribe((d: any) => {
        console.log(d);

        this.dataSource = new MatTableDataSource(d);

        setTimeout(() => {
          this.dataSource.paginator = this.paginator;
        }, 2);
        this.dataSource.filterPredicate = (data: any, filter: string) => {
          const dataStr = (data.loginTime + data.employee.user.name
            + data.loginDate + data.logoutTime).toLowerCase();
          return dataStr.includes(filter);
        };
        this.isLoading = false;

      })
    }
    if (this.userId === 1 && this.userName === 'PRIMARY USER' && this.isAttendance && this.attendanceType == 'Shift') {
      this.detailsFilterForm.get('fromDate')?.setValue(this.getTodaysDate);
      this.detailsFilterForm.get('toDate')?.setValue(this.getTodaysDate);

      this.Gs.searchDateShiftBased(this.org, this.getTodaysDate).subscribe((d: any) => {
        console.log(d)

        this.dataSource = new MatTableDataSource(d);

        setTimeout(() => {
          this.dataSource.paginator = this.paginator;
        }, 2);
        this.dataSource.filterPredicate = (data: any, filter: string) => {
          const dataStr = (data.loginTime + data.employee.user.name
            + data.loginDate + data.logoutTime).toLowerCase();
          return dataStr.includes(filter);
        };
        this.isLoading = false;

      })
    }

    //--------------------Buddy User---------------------------
    if (this.userId === 2 && this.userName === 'BUDDY USER' && this.isAttendance && this.attendanceType == 'Non Shift') {
      this.detailsFilterForm.get('fromDate')?.setValue(this.getTodaysDate);
      this.detailsFilterForm.get('toDate')?.setValue(this.getTodaysDate);
      this.Gs.filterUseridDateNonShiftBased(this.getTodaysDate, this.user1).subscribe((d: any) => {
        console.log(d)

        this.dataSource = new MatTableDataSource(d);

        setTimeout(() => {
          this.dataSource.paginator = this.paginator;
        }, 2);
        this.dataSource.filterPredicate = (data: any, filter: string) => {
          const dataStr = (data.loginTime + data.employee.user.name
            + data.loginDate + data.logoutTime).toLowerCase();
          return dataStr.includes(filter);
        };
        this.isLoading = false;

      })
    }
    if (this.userId === 2 && this.userName === 'BUDDY USER' && this.isAttendance && this.attendanceType == 'Shift') {
      this.detailsFilterForm.get('fromDate')?.setValue(this.getTodaysDate);
      this.detailsFilterForm.get('toDate')?.setValue(this.getTodaysDate);
      this.Gs.filterUseridDateShiftBased(this.getTodaysDate, this.user1).subscribe((d: any) => {
        console.log(d)

        this.dataSource = new MatTableDataSource(d);

        setTimeout(() => {
          this.dataSource.paginator = this.paginator;
        }, 2);
        this.dataSource.filterPredicate = (data: any, filter: string) => {
          const dataStr = (data.loginTime + data.employee.user.name
            + data.loginDate + data.logoutTime).toLowerCase();
          return dataStr.includes(filter);
        };
        this.isLoading = false;

      })
    }

    //------------------Secondary User ---------------------------

    if (this.userId === 3 && this.userName === 'SECONDARY USER' && this.isAttendance && this.attendanceType == 'Non Shift') {
      this.detailsFilterForm.get('fromDate')?.setValue(this.getTodaysDate);
      this.detailsFilterForm.get('toDate')?.setValue(this.getTodaysDate);
      this.Gs.filterEmpIdDateNonShiftBased(this.getTodaysDate, this.employeeId).subscribe((d: any) => {
        console.log(d)

        this.dataSource = new MatTableDataSource(d);

        setTimeout(() => {
          this.dataSource.paginator = this.paginator;
        }, 2);
        this.dataSource.filterPredicate = (data: any, filter: string) => {
          const dataStr = (data.loginTime + data.employee.user.name
            + data.loginDate + data.logoutTime).toLowerCase();
          return dataStr.includes(filter);
        };
        this.isLoading = false;

      })
    }
    if (this.userId === 3 && this.userName === 'SECONDARY USER' && this.isAttendance && this.attendanceType == 'Shift') {
      this.detailsFilterForm.get('fromDate')?.setValue(this.getTodaysDate);
      this.detailsFilterForm.get('toDate')?.setValue(this.getTodaysDate);
      this.Gs.filterEmpIdDateShiftBased(this.getTodaysDate, this.employeeId).subscribe((d: any) => {
        console.log(d)

        this.dataSource = new MatTableDataSource(d);

        setTimeout(() => {
          this.dataSource.paginator = this.paginator;
        }, 2);
        this.dataSource.filterPredicate = (data: any, filter: string) => {
          const dataStr = (data.loginTime + data.employee.user.name
            + data.loginDate + data.logoutTime).toLowerCase();
          return dataStr.includes(filter);
        };
        this.isLoading = false;

      })
    }
  }

  yesterdayDate() {
    const today = new Date();
    today.setDate(today.getDate() - 1); // Subtract 1 day
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0
    const yyyy = today.getFullYear();
    // return `${yyyy}-${mm}-${dd}`; // Format: YYYY-MM-DD
    this.getYesterdayDate = `${yyyy}-${mm}-${dd}`;
    //--------------------Primary User---------------------------
    if (this.userId === 1 && this.userName === 'PRIMARY USER' && this.isAttendance && this.attendanceType == 'Non Shift') {
      this.detailsFilterForm.get('fromDate')?.setValue(this.getYesterdayDate);
      this.detailsFilterForm.get('toDate')?.setValue(this.getYesterdayDate);
      this.Gs.searchDateNonShiftBased(this.org, this.getYesterdayDate).subscribe((d: any) => {
        console.log(d);

        this.dataSource = new MatTableDataSource(d);

        setTimeout(() => {
          this.dataSource.paginator = this.paginator;
        }, 2);
        this.dataSource.filterPredicate = (data: any, filter: string) => {
          const dataStr = (data.loginTime + data.employee.user.name
            + data.loginDate + data.logoutTime).toLowerCase();
          return dataStr.includes(filter);
        };
        this.isLoading = false;

      })
    }
    if (this.userId === 1 && this.userName === 'PRIMARY USER' && this.isAttendance && this.attendanceType == 'Shift') {
      this.detailsFilterForm.get('fromDate')?.setValue(this.getYesterdayDate);
      this.detailsFilterForm.get('toDate')?.setValue(this.getYesterdayDate);
      this.Gs.searchDateShiftBased(this.org, this.getYesterdayDate).subscribe((d: any) => {
        console.log(d)

        this.dataSource = new MatTableDataSource(d);

        setTimeout(() => {
          this.dataSource.paginator = this.paginator;
        }, 2);
        this.dataSource.filterPredicate = (data: any, filter: string) => {
          const dataStr = (data.loginTime + data.employee.user.name
            + data.loginDate + data.logoutTime).toLowerCase();
          return dataStr.includes(filter);
        };
        this.isLoading = false;

      })
    }

    //--------------------Buddy User---------------------------
    if (this.userId === 2 && this.userName === 'BUDDY USER' && this.isAttendance && this.attendanceType == 'Non Shift') {
      this.detailsFilterForm.get('fromDate')?.setValue(this.getYesterdayDate);
      this.detailsFilterForm.get('toDate')?.setValue(this.getYesterdayDate);
      this.Gs.filterUseridDateNonShiftBased(this.getYesterdayDate, this.user1).subscribe((d: any) => {
        console.log(d)

        this.dataSource = new MatTableDataSource(d);

        setTimeout(() => {
          this.dataSource.paginator = this.paginator;
        }, 2);
        this.dataSource.filterPredicate = (data: any, filter: string) => {
          const dataStr = (data.loginTime + data.employee.user.name
            + data.loginDate + data.logoutTime).toLowerCase();
          return dataStr.includes(filter);
        };
        this.isLoading = false;

      })
    }
    if (this.userId === 2 && this.userName === 'BUDDY USER' && this.isAttendance && this.attendanceType == 'Shift') {
      this.detailsFilterForm.get('fromDate')?.setValue(this.getYesterdayDate);
      this.detailsFilterForm.get('toDate')?.setValue(this.getYesterdayDate);
      this.Gs.filterUseridDateShiftBased(this.getYesterdayDate, this.user1).subscribe((d: any) => {
        console.log(d)

        this.dataSource = new MatTableDataSource(d);

        setTimeout(() => {
          this.dataSource.paginator = this.paginator;
        }, 2);
        this.dataSource.filterPredicate = (data: any, filter: string) => {
          const dataStr = (data.loginTime + data.employee.user.name
            + data.loginDate + data.logoutTime).toLowerCase();
          return dataStr.includes(filter);
        };
        this.isLoading = false;

      })
    }

    //------------------Secondary User ---------------------------

    if (this.userId === 3 && this.userName === 'SECONDARY USER' && this.isAttendance && this.attendanceType == 'Non Shift') {
      this.detailsFilterForm.get('fromDate')?.setValue(this.getYesterdayDate);
      this.detailsFilterForm.get('toDate')?.setValue(this.getYesterdayDate);
      this.Gs.filterEmpIdDateNonShiftBased(this.getYesterdayDate, this.employeeId).subscribe((d: any) => {
        console.log(d)

        this.dataSource = new MatTableDataSource(d);

        setTimeout(() => {
          this.dataSource.paginator = this.paginator;
        }, 2);
        this.dataSource.filterPredicate = (data: any, filter: string) => {
          const dataStr = (data.loginTime + data.employee.user.name
            + data.loginDate + data.logoutTime).toLowerCase();
          return dataStr.includes(filter);
        };
        this.isLoading = false;

      })
    }
    if (this.userId === 3 && this.userName === 'SECONDARY USER' && this.isAttendance && this.attendanceType == 'Shift') {
      this.detailsFilterForm.get('fromDate')?.setValue(this.getYesterdayDate);
      this.detailsFilterForm.get('toDate')?.setValue(this.getYesterdayDate);
      this.Gs.filterEmpIdDateShiftBased(this.getYesterdayDate, this.employeeId).subscribe((d: any) => {
        console.log(d)

        this.dataSource = new MatTableDataSource(d);

        setTimeout(() => {
          this.dataSource.paginator = this.paginator;
        }, 2);
        this.dataSource.filterPredicate = (data: any, filter: string) => {
          const dataStr = (data.loginTime + data.employee.user.name
            + data.loginDate + data.logoutTime).toLowerCase();
          return dataStr.includes(filter);
        };
        this.isLoading = false;

      })
    }
  }

  // empSearch(event, event1: KeyboardEvent): void {
  //   event1.preventDefault();
  //   // console.log('');

  //   if (event1.key === '') {
  //     // this.employeeNotFound = false;
  //     // Handle the space key press
  //     console.log('Space key pressed');

  //     // Optionally, prevent the default behavior
  //     event1.preventDefault();
  //   } else {


  //     if (this.isAttendance && this.attendanceType == 'Non Shift') {
  //       console.log(event.target.value);
  //       let employeenumber = event.target.value;
  //       employeenumber = employeenumber.replace(/-.*/, '');
  //       console.log(employeenumber);

  //       // Cancel the previous API request
  //       this.unsubscribe$.next();
  //       this.unsubscribe$.complete();
  //       this.unsubscribe$ = new Subject<void>();

  //       if (employeenumber.length == 0) {
  //         // this.employeeNotFound = false;
  //         console.log('enterd into the condition!!');
  //         this.employeeId = undefined;
  //         // this.employeeData = undefined;
  //       }
  //       // Employee List Api
  //       if (employeenumber.length >= 2) {
  //         this.Gs
  //           .searchEmployeeNumberornamebasedNonshiftandOrg(this.org, employeenumber)
  //           .pipe(
  //             takeUntil(this.unsubscribe$), // Cancel previous request
  //             switchMap(() => this.Gs.searchEmployeeNumberornamebasedNonshiftandOrg(this.org, employeenumber)),
  //             catchError((err) => {
  //               if (err.error.status === 500) {
  //                 // this.projectId = [];
  //                 // this.employeeNotFound = true;
  //               }
  //               console.error('Error fetching employees:', err);
  //               // Handle the error here, for example, showing an alert
  //               // alert('Error fetching employees. Please try again later.');
  //               return throwError(err); // Rethrow the error to propagate it downstream
  //             })
  //           )
  //           .subscribe((result: any) => {
  //             console.log('result', result);

  //             if (result.statusCode === 204) {
  //               // this.employeeNotFound = true;
  //             }
  //             // this.employeeProjects.length = 0;

  //             result.forEach((element) => {
  //               // let employee = {
  //               //   employee: {
  //               //     employeeId: element.employeeId,
  //               //     firstName: element.firstName,
  //               //     employeeNumber: element.employeeNumber,
  //               //   },
  //               // };
  //               this.searchEmployees.push(element.employee)
  //               // this.employeeProjects.push(employee);
  //               // console.log('this.employeeProjects', this.employeeProjects);
  //             });

  //             // if (this.selectedemployee.length >= 1) {
  //             //   this.employeeSelected = true;
  //             // }

  //             if (result.errorCode === 204) {
  //               // alert('No Employee Found');
  //               // this.employeeNotFound = true;
  //               // this.employeeProjects.length = 0;
  //               // this.employeeProjects.push('No Employee Found');
  //               // this.selectedemployeeId = null; // Set selectedemployeeId to null if no employee is found
  //             }
  //           });
  //       }
  //     }
  //     if (this.isAttendance && this.attendanceType == 'Shift') {
  //       console.log(event.target.value);
  //       let employeenumber = event.target.value;
  //       employeenumber = employeenumber.replace(/-.*/, '');
  //       console.log(employeenumber);

  //       // Cancel the previous API request
  //       this.unsubscribe$.next();
  //       this.unsubscribe$.complete();
  //       this.unsubscribe$ = new Subject<void>();

  //       if (employeenumber.length == 0) {
  //         // this.employeeNotFound = false;
  //         console.log('enterd into the condition!!');
  //         this.employeeId = undefined;
  //         // this.employeeData = undefined;
  //       }
  //       // Employee List Api
  //       if (employeenumber.length >= 2) {
  //         this.Gs
  //           .searchEmployeeNumberornamebasedShiftandOrg(this.org, employeenumber)
  //           .pipe(
  //             takeUntil(this.unsubscribe$), // Cancel previous request
  //             switchMap(() => this.Gs.searchEmployeeNumberornamebasedShiftandOrg(this.org, employeenumber)),
  //             catchError((err) => {
  //               if (err.error.status === 500) {
  //                 // this.projectId = [];
  //                 // this.employeeNotFound = true;
  //               }
  //               console.error('Error fetching employees:', err);
  //               // Handle the error here, for example, showing an alert
  //               // alert('Error fetching employees. Please try again later.');
  //               return throwError(err); // Rethrow the error to propagate it downstream
  //             })
  //           )
  //           .subscribe((result: any) => {
  //             console.log('result', result);

  //             if (result.statusCode === 204) {
  //               // this.employeeNotFound = true;
  //             }
  //             // this.employeeProjects.length = 0;

  //             result.forEach((element) => {
  //               // let employee = {
  //               //   employee: {
  //               //     employeeId: element.employeeId,
  //               //     firstName: element.firstName,
  //               //     employeeNumber: element.employeeNumber,
  //               //   },
  //               // };
  //               this.searchEmployees.push(element.employee)
  //               // this.employeeProjects.push(employee);
  //               // console.log('this.employeeProjects', this.employeeProjects);
  //             });

  //             // if (this.selectedemployee.length >= 1) {
  //             //   this.employeeSelected = true;
  //             // }

  //             if (result.errorCode === 204) {
  //               // alert('No Employee Found');
  //               // this.employeeNotFound = true;
  //               // this.employeeProjects.length = 0;
  //               // this.employeeProjects.push('No Employee Found');
  //               // this.selectedemployeeId = null; // Set selectedemployeeId to null if no employee is found
  //             }
  //           });
  //       }
  //     }
  //   }
  // }

  empSearch(event) {
    this.employeeNotFound = false; // Reset the no employee flag
    console.log(event.target.value);
    let employeenumber = event.target.value;
    let userdata = this.auth.getLocalStorageData();
    // Employee List API
    if (employeenumber.length >= 2) {
      if (this.employeelistsub) {
        this.employeelistsub.unsubscribe();
      }

      // if (this.isAttendance && this.attendanceType == 'Non Shift') {
      //   this.employeelistsub = this.Gs.searchEmployeeNumberornamebasedNonshiftandOrg(this.org,employeenumber)
      //     .pipe(
      //       takeUntil(this.unsubscribe$), // Cancel previous request
      //       switchMap(() => this.Gs.searchEmployeeNumberornamebasedNonshiftandOrg(this.org,employeenumber))
      //     )
      //     .subscribe((result: any) => {
      //       console.log("result", result);
      //       this.searchEmployees = [];

      //       if (result.statusCode) {
      //         console.log(result.statusCode);
      //         this.searchEmployees = [];
      //         this.employeeNotFound = true; // Set the flag if status code indicates no employees
      //       } else {
      //         result.map(
      //           (e: any) => {
      //             if (userdata.userType[0].userTypeName == "BUDDY USER") {
      //               if (e.userId != userdata.userId && (e.userType[0].userTypeName != "PRIMARY USER" && userdata.userType[0].userTypeName == "BUDDY USER")
      //                 && e.buddyUser?.userId == userdata.userId) {
      //                 console.log(e);
      //                 this.searchEmployees.push(e);
      //               }
      //             }
      //             else {
      //               if (e.userId != userdata.userId) {
      //                 console.log(e)
      //                 this.searchEmployees.push(e);
      //               }
      //             }
      //           }
      //         )

      //         if (this.searchEmployees.length === 0) {
      //           this.employeeNotFound = true; // Set the flag if the list is empty
      //         }
      //       }


      //     },
      //     (error:any)=>{
      //       console.log(error.statusCode);
      //       this.searchEmployees = [];
      //       this.employeeNotFound = true;
      //     }
      //   )
      // }
      // if (this.isAttendance && this.attendanceType == 'Shift') {
      this.employeelistsub = this.Gs.usersSearch(this.org, employeenumber)
        .pipe(
          takeUntil(this.unsubscribe$), // Cancel previous request
          switchMap(() => this.Gs.usersSearch(this.org, employeenumber))
        )
        .subscribe((result: any) => {
          console.log("result", result);
          this.searchEmployees = [];

          if (result.statusCode) {
            console.log(result.statusCode);
            this.searchEmployees = [];
            this.employeeNotFound = true; // Set the flag if status code indicates no employees
          } else {
            result.map(
              (e: any) => {
                if (userdata.userType[0].userTypeName == "BUDDY USER") {
                  if (e.userId != userdata.userId && (e.userType[0].userTypeName != "PRIMARY USER" && userdata.userType[0].userTypeName == "BUDDY USER")
                    && e.buddyUser?.userId == userdata.userId) {
                    console.log(e);
                    this.searchEmployees.push(e);
                  }
                }
                else {
                  if (e.userId != userdata.userId) {
                    console.log(e)
                    this.searchEmployees.push(e);
                  }
                }
              }
            )

            if (this.searchEmployees.length === 0) {
              this.employeeNotFound = true; // Set the flag if the list is empty
            }
          }


        },
          (error: any) => {
            console.log(error.statusCode);
            this.searchEmployees = [];
            this.employeeNotFound = true;
          }
        )
      // }
    }
    else {
      if (this.auth.getLocal_Org()?.organizationId) {
        let id = this.auth.getLocal_Org().organizationId;
        console.log(userdata.userId)
        if (this.employeelistsub) {
          this.employeelistsub.unsubscribe();
        }
        this.employeelistsub = this.Gs.get_user_org(id)
          .pipe(
            takeUntil(this.unsubscribe$), // Cancel previous request
            switchMap(() => this.Gs.get_user_org(id))
          )
          .subscribe((a: any) => {

            // this.gs.get_user_org(id).subscribe((a: any) => {
            console.log(a);
            //  this.userNameList=a;
            //  console.log(this.userNameList);
            this.searchEmployees = [];
            a.map(
              (e: any) => {
                if (userdata.userType[0].userTypeName == "BUDDY USER") {
                  if (e.userId != userdata.userId && (e.userType[0].userTypeName != "PRIMARY USER" && userdata.userType[0].userTypeName == "BUDDY USER")
                    && e.buddyUser?.userId == userdata.userId) {
                    console.log(e);
                    this.searchEmployees.push(e);
                  }
                }
                else {
                  if (e.userId != userdata.userId) {
                    console.log(e)
                    this.searchEmployees.push(e);
                  }
                }
              }
            )
          },
            (error: any) => {
              console.log(error.statusCode);
              this.searchEmployees = [];
              this.employeeNotFound = true;
            })
      } else {

        let id = this.auth.getLocalStorageData()?.userId;
        this.Gs.get_primary_users_user(id).subscribe((a: any) => {
          a.map(
            (e: any) => {
              if (e.userId != id) {
                this.searchEmployees.push(e);
              }
            }
          )
        })
      }
    }
  }


  empSelect(emp) {
    console.log('emp', emp);

    this.employeeId = emp.employee.employeeId;
    // this.employeeData =
    //   emp.employeeNumber + '-' + emp?.user?.name;
    this.employeeData = emp?.employee?.employeeNumber + '-' + emp?.name;
    // this.employeeId = emp?.employee?.employeeNumber + '-' + emp?.name;
    this.employeeNumber = emp?.employee?.employeeNumber;

    this.user = emp?.employee?.employeeNumber;

    console.log('this.employeeId', this.employeeId);

    console.log('this.employeeData', this.employeeData);

    // Patch the form with employee data
    this.detailsFilterForm.patchValue({
      employeeId: this.employeeData,
    });

  }

  validateInput(event: Event) {
    const input = event.target as HTMLInputElement;
    let value = input.value;

    // Remove leading spaces
    if (value.startsWith(' ')) {
      value = value.trimStart();
    }

    // Remove spaces in between
    value = value.replace(/\s+/g, '');

    input.value = value;
  }

  handleBackspace(event): void {
    // const value = this.taskForm.get('employee').value;
    // this.employeetaskexist = false;
    const inputValue = event.target.value;

    if (event.key === 'Backspace' && inputValue.length <= 0) {
      // this.selectedemployeeId = undefined;
      // this.employeeId = undefined;
      // this.employeeProjects.length = 0;
      // this.taskForm.reset();
      // this.employeetaskexist = false;
      // this.projectList = [];
      // this.taskList = [];
      // this.employeetaskexist = false;
      // this.employee = undefined;
      this.employeeNumber = undefined;
      // this.selectedemployee = undefined;
      // this.employeeNotFound = false; // Set employeeNotFound to false
    }

    // if (
    //   event.key === 'Backspace' &&
    //   !value &&
    //   !this.taskForm?.get('employee').value.trim()
    // ) {
    //   // this.employeeNotFound = true;
    //   // this.taskForm.reset();
    //   // this.projectList = [];
    //   // this.employeetaskexist = false;
    //   // this.employeetaskexist = false;
    // } else {
    //   // this.employeeNotFound = false;
    // }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    console.log(filterValue)
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  onPageChange(event: any) {
    this.currentPageOffset = event.pageIndex * event.pageSize;
  }

  fetchReports(): void {
    this.isLoading = true;
    this.allUsers.forEach((a) => {
      console.log(a);
      this.userId = a.userTypeId;
      this.userName = a.userTypeName;

    })


    //-----------------old All data    shift and non-shift-------------------------------------------

    // if (this.userId === 1 && this.userName === 'PRIMARY USER') {
    //   this.Gs.getLoginLogoutReportsforPrimaryUser(this.org).subscribe((d: any) => {
    //     console.log(d)


    //     this.dataSource = new MatTableDataSource(d);
    //     setTimeout(() => {
    //       this.dataSource.paginator = this.paginator;
    //     }, 2);
    //     this.dataSource.filterPredicate = (data: any, filter: string) => {
    //       const dataStr = (data.loginTime + data.employee.user.name
    //         + data.loginDate + data.logoutTime).toLowerCase();
    //       return dataStr.includes(filter);
    //     };

    //     this.isLoading = false;
    //   })
    // }

    // if (this.userId === 2 && this.userName === 'BUDDY USER') {
    //   console.log(this.user1);
    //   this.Gs.getLoginLogoutReportsforBuddyUser(this.user1).subscribe((d: any) => {
    //     console.log(d)



    //     this.dataSource = new MatTableDataSource(d);

    //     setTimeout(() => {
    //       this.dataSource.paginator = this.paginator;
    //     }, 2);
    //     this.dataSource.filterPredicate = (data: any, filter: string) => {
    //       const dataStr = (data.loginTime + data.employee.user.name
    //         + data.loginDate + data.logoutTime).toLowerCase();
    //       return dataStr.includes(filter);
    //     };

    //     this.isLoading = false;
    //   })
    // }

    // if (this.userId === 3 && this.userName === 'SECONDARY USER') {
    //   this.Gs.getLoginLogoutReportsforEmployee(this.employeeId).subscribe((d: any) => {
    //     console.log(d)

    //     this.dataSource = new MatTableDataSource(d);

    //     setTimeout(() => {
    //       this.dataSource.paginator = this.paginator;
    //     }, 2);
    //     this.dataSource.filterPredicate = (data: any, filter: string) => {
    //       const dataStr = (data.loginTime + data.employee.user.name
    //         + data.loginDate + data.logoutTime).toLowerCase();
    //       return dataStr.includes(filter);
    //     };
    //     this.isLoading = false;

    //   })
    // }

    // --------------------------non-shift-based and user-based------------------------


    if (this.userId === 1 && this.userName === 'PRIMARY USER' && this.isAttendance && this.attendanceType == 'Non Shift') {
      this.Gs.getNonShiftLoginDetailsforPrimaryUser(this.org).subscribe((d: any) => {
        console.log(d);


        // this.dataSource = new MatTableDataSource(d);
        // if (d && typeof d === 'object' && !Array.isArray(d)) {
        //   d = Object.values(d); // Convert object values to an array
        // }

        // if (d && typeof d !== 'object') {
        this.filterData = [];
        this.filterData.push(d);
        console.log('filterData', this.filterData);

        this.dataSource = new MatTableDataSource(d);
        // }
        setTimeout(() => {
          this.dataSource.paginator = this.paginator;
        }, 2);
        this.dataSource.filterPredicate = (data: any, filter: string) => {
          const dataStr = (data.loginTime + data.employee.user.name
            + data.loginDate + data.logoutTime).toLowerCase();
          return dataStr.includes(filter);
        };

        this.isLoading = false;
      })
    }

    if (this.userId === 1 && this.userName === 'PRIMARY USER' && this.isAttendance && this.attendanceType == 'Shift') {
      this.Gs.getShiftLoginDetailsforPrimaryUser(this.org).subscribe((d: any) => {
        console.log(d)


        // this.dataSource = new MatTableDataSource(d);
        // if (d && typeof d === 'object' && !Array.isArray(d)) {
        //   d = Object.values(d); // Convert object values to an array
        // }

        // if (d && typeof d !== 'object') {
        this.filterData = [];
        this.filterData.push(d);
        console.log('filterData', this.filterData);

        this.dataSource = new MatTableDataSource(d);
        // }
        setTimeout(() => {
          this.dataSource.paginator = this.paginator;
        }, 2);
        this.dataSource.filterPredicate = (data: any, filter: string) => {
          const dataStr = (data.loginTime + data.employee.user.name
            + data.loginDate + data.logoutTime).toLowerCase();
          return dataStr.includes(filter);
        };

        this.isLoading = false;
      })
    }

    if (this.userId === 2 && this.userName === 'BUDDY USER' && this.isAttendance && this.attendanceType == 'Non Shift') {
      console.log(this.user1);
      this.Gs.getNonShiftLoginDetailsforBuddyuser(this.user1).subscribe((d: any) => {
        console.log(d)

        this.filterData = [];
        this.filterData.push(d);

        console.log(' this.filterData', this.filterData);

        this.dataSource = new MatTableDataSource(d);

        setTimeout(() => {
          this.dataSource.paginator = this.paginator;
        }, 2);
        this.dataSource.filterPredicate = (data: any, filter: string) => {
          const dataStr = (data.loginTime + data.employee.user.name
            + data.loginDate + data.logoutTime).toLowerCase();
          return dataStr.includes(filter);
        };

        this.isLoading = false;
      })
    }


    if (this.userId === 2 && this.userName === 'BUDDY USER' && this.isAttendance && this.attendanceType == 'Shift') {
      console.log(this.user1);
      this.Gs.getShiftLoginDetailsforBuddyuser(this.user1).subscribe((d: any) => {
        console.log(d)

        this.filterData = [];
        this.filterData.push(d);
        console.log('filterData', d);

        this.dataSource = new MatTableDataSource(d);

        setTimeout(() => {
          this.dataSource.paginator = this.paginator;
        }, 2);
        this.dataSource.filterPredicate = (data: any, filter: string) => {
          const dataStr = (data.loginTime + data.employee.user.name
            + data.loginDate + data.logoutTime).toLowerCase();
          return dataStr.includes(filter);
        };

        this.isLoading = false;
      })
    }

    if (this.userId === 3 && this.userName === 'SECONDARY USER' && this.isAttendance && this.attendanceType == 'Non Shift') {
      this.Gs.getNonShiftLoginDetailsforEmployee(this.employeeId).subscribe((d: any) => {
        console.log(d)
        this.filterData = [];
        this.filterData.push(d);
        this.dataSource = new MatTableDataSource(d);

        setTimeout(() => {
          this.dataSource.paginator = this.paginator;
        }, 2);
        this.dataSource.filterPredicate = (data: any, filter: string) => {
          const dataStr = (data.loginTime + data.employee.user.name
            + data.loginDate + data.logoutTime).toLowerCase();
          return dataStr.includes(filter);
        };
        this.isLoading = false;

      })
    }



    if (this.userId === 3 && this.userName === 'SECONDARY USER' && this.isAttendance && this.attendanceType == 'Shift') {
      this.Gs.getShiftLoginDetailsforEmployee(this.employeeId).subscribe((d: any) => {
        console.log(d)
        this.filterData = [];
        this.filterData.push(d);
        this.dataSource = new MatTableDataSource(d);

        setTimeout(() => {
          this.dataSource.paginator = this.paginator;
        }, 2);
        this.dataSource.filterPredicate = (data: any, filter: string) => {
          const dataStr = (data.loginTime + data.employee.user.name
            + data.loginDate + data.logoutTime).toLowerCase();
          return dataStr.includes(filter);
        };
        this.isLoading = false;

      })
    }





    // this.Gs.get_loginReport(this.org,this.users).subscribe((d:any)=>{
    //   console.log(d)



    // this.dataSource = new MatTableDataSource(d);

    //     this.dataSource.paginator = this.paginator;
    //     this.dataSource.filterPredicate = (data: any, filter: string) => {
    //       const dataStr = (data.loginTime + data.employee.user.name
    //         + data.loginDate+data.logoutTime).toLowerCase();
    //       return dataStr.includes(filter);
    //     };


    // })

  }



  exportToExcel() {
    this.dataExport.length = 0;
    this.exportingData = this.filterData;
    console.log("this.exportingdata::", this.exportingData);

    for (let ele of this.exportingData) {
      ele.forEach(element => {


        console.log('element', element);
        console.log('element', element?.employee?.user?.name);
        this.exported = {
          EmployeeName: element?.employee?.user?.name ? element.employee?.user.name : '',
          EmployeeNo: element.employee?.employeeNumber ? element.employee?.employeeNumber : '',
          TotalWorkingHours: element?.totalWorkedTime ? element?.totalWorkedTime : '',
          LoginDate: element?.loginDate ? element?.loginDate : '',
          LoginTime: element?.loginTime ? element?.loginTime : '',
          LogoutTime: element?.logoutTime ? element?.logoutTime : '',
          LoginAddress: element?.loginAddress ? element?.loginAddress : '',
          LogoutAddress: element?.logoutAddress ? element?.logoutAddress : ''

        };

        this.dataExport.push(this.exported);
      });
    }
    console.log("this.dataExport", this.dataExport);
    this.es.exportAsExcelFile(this.dataExport, 'Export');
  }
}


