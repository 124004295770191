<div class="container">
  <div class="mat-elevation-z8">
    <form [formGroup]="reportForm">
      <!-- <h2 class="" style="padding-left: 12px; font-size: 18px">
        Track Report List
      </h2> -->
      <h2 class="pl-5 pt-3">Track Report List</h2>
      <div class="row" style="padding: 5px 5px 5px 5px">
        <div class="col-lg-2 col-md-2 col-sm-12">
          <mat-form-field
            class="example-full-width"
            appearance="outline"
            class="full-width"
          >
            <mat-label class=""
              >Choose User<span class="text-danger"> *</span></mat-label
            >
            <input
              matInput
              formControlName="user"
              placeholder="Select user"
              [matAutocomplete]="auto"
              (keyup)="empSearch($event)"
              #autoTrigger="matAutocompleteTrigger"
            />
            <mat-autocomplete
              #auto="matAutocomplete"
              class="custom-autocomplete"
            >
              <mat-option
                *ngFor="let emp of userlist"
                [value]="emp.employeeId"
                (click)="empSelect(emp)"
              >
                {{ emp?.employee?.employeeNumber }} - {{ emp?.name }}
              </mat-option>
              <mat-option
                [value]="null"
                *ngIf="employeeNotFound"
                (click)="noEmployeeFound()"
                >No user Found</mat-option
              >
            </mat-autocomplete>
          </mat-form-field>
          <span class="error_msg" *ngIf="user_required"
            >*Please select user</span
          >

          <!-- <mat-form-field class="example-full-width" appearance="outline" style="display: inline;">
              <mat-label>Choose User </mat-label> 
              <mat-select placeholder="Select User" formControlName="user"  (selectionChange)="isselected($event)">
                <mat-option value="0"> -- Select User -- </mat-option>
                <mat-option *ngFor="let user of userlist"  [value]="user.userId">{{user?.employee?.employeeNumber}} - {{user?.name}}</mat-option>
              </mat-select>
          </mat-form-field>
          <span class="error_msg" *ngIf="user_required">*Please select user</span>  -->
        </div>
        <div class="col-lg-2 col-md-2 col-sm-12">
          <mat-form-field
            class="example-full-width"
            appearance="outline"
            style="display: inline"
          >
            <mat-label>Start Date</mat-label>
            <input
              matInput
              class=""
              formControlName="startdate"
              placeholder="Choose a Date"
              (dateChange)="startdate($event)"
              [matDatepicker]="picker1"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="picker1"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
          </mat-form-field>
          <span class="error_msg" *ngIf="staDate_required"
            >*Please select startdate</span
          >
          <span class="error_msg" *ngIf="startDate_required"
            >*Please select vaild startdate</span
          >
        </div>
        <div class="col-lg-2 col-md-2 col-sm-12">
          <mat-form-field
            class="example-full-width"
            appearance="outline"
            style="display: inline"
          >
            <mat-label>End Date</mat-label>
            <input
              matInput
              formControlName="enddate"
              placeholder="Choose a Date"
              (dateChange)="enddate($event)"
              [min]="startDate"
              [matDatepicker]="picker2"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="picker2"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker2></mat-datepicker>
          </mat-form-field>
          <span class="error_msg" *ngIf="endDate_required"
            >*Please select enddate</span
          >
          <span class="error_msg" *ngIf="endDate_error"
            >Please select vaild enddate</span
          >
          <!-- <span class="error_msg" >Please select end date</span> 
          <span class="error_msg" >End date should be greater than start date</span>   -->
        </div>
        <!-- <div class="col-lg-2 col-md-2 col-sm-12">
          <div id="t1">
              <input matInput type="time" formControlName="starttime" id="time"  onkeydown="stime($event)" (input)="stime($event)"  class="timer">
          </div>
          <span class="error_msg" *ngIf="starttime_required">*Please select starttime</span> 
        </div> -->

        <div class="col-lg-2 col-md-2 col-sm-12">
          <mat-form-field appearance="outline" style="display: inline">
            <input
              matInput
              formControlName="starttime"
              (input)="stime($event)"
              type="time"
              placeholder="Choose a Date"
              onkeydown="stime($event)"
              style="text-align: start;"
            />
          </mat-form-field>
          <span class="error_msg" *ngIf="starttime_required"
            >*Please select starttime</span
          >
          <span class="error_msg" *ngIf="startTimeValid"
          >*Please select vaild starttime</span
        >
        </div>

        <div class="col-lg-2 col-md-2 col-sm-12">
          <mat-form-field appearance="outline" style="display: inline">
            <!-- <div id="t1"> -->
            <input
              matInput
              type="time"
              class="time-input"
              formControlName="endtime"
              onkeypress="endTime($event)"
              (click)="endTime($event)"
              (input)="endTime($event)"
            />
          </mat-form-field>
          <!-- </div>  -->
          <span class="error_msg" *ngIf="endtime_required"
            >*Please select endtime</span
          >
          <span class="error_msg" *ngIf="endTimeValid"
          >*Please select vaild endtime</span
        >
        </div>
        <!-- style="position: relative; top: 5px; font-size: 14px"
        class="btn btn-primary btm-sm" -->
        <div class="col-lg-1 col-md-1 col-sm-12 mt-1">
          <button
            type="button"
            mat-flat-button
            class="normal-button"
            (click)="search1()"
          >
            Search
          </button>
        </div>

        <div class="col-lg-1 col-md-2 col-sm-12 col-12 clear-m mt-1">
          <button
           type="button"
            mat-flat-button
            (click)="clear()"
            class="clear-button"
          >
            Clear
          </button>
        </div>
      </div>
      <div class="row header_action">
        <!-- <button mat-flat-button >
          {{selection.selected.length > 0 ? 'Selected User: '+ selection.selected.length : ''}}
        </button> -->

        <!-- <button *ngIf="selection.selected.length>0" mat-flat-button (click)="selectedUser()">
          <span class="material-icons">
            add
          </span>
          <span>View Selected User</span>
        </button> -->
        <div class="search row">
          <div class="col-3">
          <mat-form-field
            appearance="outline"
            class="d-inline-block "
            style="float: right"
            *ngIf="filterData.length > 5"
          >
            <mat-label>Search</mat-label>
            <mat-icon matPrefix>search</mat-icon>
            <input
              matInput
              (keyup)="applyFilter($event)"
              placeholder="search name/ email/ phone number"
              #input
            />
          </mat-form-field>
        </div>
        </div>
      </div>
      <!-- <div class="row dist"  *ngIf="filterData.length>0">
        <div class="" style="text-align: right;padding-right: 44px;">
          <span>TotalDistance:</span>{{totalDistance}}
        </div>
      </div> -->
      <div class="col-md-12" *ngIf="filterData.length > 0">
        <div class="" style="float: left">
          <!-- <span>TotalDistance:</span>{{totalDistance}} -->
        </div>
        <div class="" style="float: right; margin-top: 10px">
          <div class="row">
            <!-- <div class="col-md-6">
                    <button *ngIf="filterData?.length > 0 " mat-raised-button type="button" style="background: #c35e4c;color: #fff;height: 35px;" 
                        class="--btn --b1 download-button ml-2 exportDesign" (click)="viewLocation()" >View Location</button>
                </div> -->
            <div class="col-md-6">
              <button
                *ngIf="filterData.length > 0"
                style="
                  background: #c35e4c;
                  color: #fff;
                  height: 35px;
                  margin-left: 20px !important;
                "
                type="button"
                (click)="exportToExcel()"
                mat-raised-button
                class="download-button ml-2 exportDesign"
              >
                Export
              </button>
            </div>
          </div>
        </div>
      </div>

 
      <div class="p-3" *ngIf="nodata" style="margin-top: -10px;">
        <div class="no-data card text-center" style="text-align: center !important;
        background-color: #d44c39fa;
        border-radius: 5px;">
          <div class="card-body">No Data</div>
        </div>
      </div>

    </form>
    <!-- <div class="p-3 no-data" *ngIf="nodata">
      <div class="no-data card text-center">
        <div class="card-body">No Data</div>
      </div>
    </div> -->
    <!-- <div class="row topnav-right pagination-content">        
        <div class="col-md-12">
            <pagination-controls class="float-right pagination" style="float: right;"
            (pageChange)="p = $event; beforepage = $event"></pagination-controls>  
        </div> 
     </div>  -->
    <!-- <mat-paginator [length]="100"
                [pageSize]="10"
                [pageSizeOptions]="[5, 10, 25, 100]" (page)="onPaginateChange($event)"
                aria-label="Select page">
     </mat-paginator> -->
    <!-- <div> -->
    <!-- <div > -->
      <div
      style="overflow: scroll"
      class="table-responsive"
      *ngIf="filterData.length > 0 "
    >
      <table mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="select" stickyEnd align="end">
          <th mat-header-cell *matHeaderCellDef>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <mat-checkbox
              (change)="$event ? masterToggle() : null"
              [checked]="selection.hasValue() && isAllSelected()"
              color="primary"
              [indeterminate]="selection.hasValue() && !isAllSelected()"
              [aria-label]="checkboxLabel()"
            >
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <mat-checkbox
              (click)="$event.stopPropagation()"
              (change)="$event ? selection.toggle(row) : null"
              color="primary"
              [checked]="selection.isSelected(row)"
              [aria-label]="checkboxLabel(row)"
            >
            </mat-checkbox>
          </td>
        </ng-container>
        <div class="col-md-12">
          <!-- Position Column -->
          <!-- <ng-container matColumnDef="userId">
      <th mat-header-cell *matHeaderCellDef class="col-md-1 col-sm-1"> <b>Sl.No</b> </th>
      <td mat-cell *matCellDef="let element; let i =index"> {{i+1}}  </td> 
    </ng-container> -->
          <ng-container matColumnDef="userId">
            <th
              mat-header-cell
              *matHeaderCellDef
              class="col-md-1 col-sm-1 text-center"
            >
              <b>Sl.No</b>
            </th>
            <td
              mat-cell
              *matCellDef="let element; let i = index"
              class="text-center"
            >
              {{ currentPageOffset + i + 1 }}
            </td>
          </ng-container>

          <!-- Name Column -->
          <ng-container matColumnDef="employeeId">
            <th mat-header-cell *matHeaderCellDef class="col-md-2 col-sm-2">
              <b>Emp.No / Name</b>
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.user?.employee?.employeeNumber }} -
              {{ element.user?.name }}
            </td>
          </ng-container>
          <ng-container matColumnDef="empcontactNo">
            <th mat-header-cell *matHeaderCellDef class="col-md-1 col-sm-1">
              <b>Emp.Contact&nbsp;No</b>
            </th>
            <td mat-cell *matCellDef="let element">
              {{
                element.user?.phoneNumber ? element.user.phoneNumber : "-"
              }}
            </td>
          </ng-container>
          <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef class="col-md-1 col-sm-1">
              <b>Date</b>
            </th>
            <td
              mat-cell
              *matCellDef="let element"
              style="white-space: nowrap"
            >
              {{ element.date | date : "dd/MM/yyyy" }}
            </td>
          </ng-container>

          <ng-container matColumnDef="day">
            <th mat-header-cell *matHeaderCellDef class="col-md-1 col-sm-1">
              <b>Day</b>
            </th>
            <td mat-cell *matCellDef="let element">{{ element.day }}</td>
          </ng-container>

          <ng-container matColumnDef="time">
            <th mat-header-cell *matHeaderCellDef class="col-md-1 col-sm-1">
              <b>Time</b>
            </th>
            <td mat-cell *matCellDef="let element">{{ element.time }}</td>
          </ng-container>

          <!-- Weight Column -->
          <ng-container matColumnDef="latitude">
            <th mat-header-cell *matHeaderCellDef class="col-md-2 col-sm-1">
              <b>Location</b>
            </th>
            <td mat-cell *matCellDef="let element">
              <span style="white-space: nowrap">Lat&nbsp;:</span>
              {{ element.latitude }} <br /><span style="white-space: nowrap"
                >Long:</span
              >
              {{ element.longitude }}
            </td>
          </ng-container>

          <!-- Symbol Column -->
          <ng-container matColumnDef="address">
            <th mat-header-cell *matHeaderCellDef class="col-md-3 col-sm-3">
              <b>Address</b>
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.address }}
            </td>
          </ng-container>

          <ng-container matColumnDef="view">
            <th mat-header-cell *matHeaderCellDef class="col-md-1 col-sm-1">
              <!-- <b>View</b>  -->
            </th>
            <!-- <mat-icon class="material-symbols-outlined" (click) = "showmap()">
          home_pin
        </mat-icon> -->
            <td mat-cell *matCellDef="let element">
              <mat-icon
                class="material-symbols-outlined"
                (click)="showmap(element)"
              >
                location_on
              </mat-icon>
            </td>
          </ng-container>
        </div>
        <!-- Name Column -->
        <!-- <ng-container matColumnDef="userType">
      <th mat-header-cell *matHeaderCellDef> <b>USER TYPE</b> </th>
      <td mat-cell *matCellDef="let element"> {{element.userType[0].userTypeName}} </td>
    </ng-container> -->

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
          <td
            class="mat-cell"
            colspan="8"
            id="nodata"
            style="text-align: center"
          >
            No data found
          </td>
          <td></td>
        </tr>
      </table>
    
    </div>
    <mat-paginator
    [hidden]="filterData.length === 0"
    [length]="filterData.length"
    [pageSize]="5"
    [pageSizeOptions]="[5, 10, 20, 30, 50, 100]"
    showFirstLastButtons
    (page)="onPageChange($event)">
  </mat-paginator>
    <!-- </div> -->
    <!-- </div> -->
  </div>
</div>
