
import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { FormArray, FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/Authenticaton/auth.service';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
import { ViewTrackUserListComponent } from '../view-track-user-list/view-track-user-list.component';
import { DatePipe } from '@angular/common';
import Swal from 'sweetalert2';
import { UserLocationComponent } from '../user-location/user-location.component';

interface UserGeofence {
  userGeofenceId: number;
  status: {
    statusId: number;
    statusName: string;
  };
  user: {
    userId: number;
    name: string;
    // other user properties
  };
  geofence: {
    geofenaceId: number;
    geofenaceName: string;
    // other geofence properties
  };
}

@Component({
  selector: 'app-assign-geofence',
  templateUrl: './assign-geofence.component.html',
  styleUrls: ['./assign-geofence.component.scss']
})
export class AssignGeofenceComponent implements OnInit {
  AssigngeofenceForm: FormGroup;
  displayedColumns: string[] = ['sno', 'username', 'geofencename','startDate','endDate', 'status', 'view'];
  // displayedColumns: string[] = ['sno', 'startdate','enddate','username', 'geofencename','status','view'];
  //  dataSource = new MatTableDataSource<UserGeofence>;
  dataSource: MatTableDataSource<UserGeofence>;
  // dataSource = new MatTableDataSource<any>();
  // dataSource: MatTableDataSource<any>;
  selection: any = new SelectionModel<any>(true, []);
  @ViewChild(MatPaginator) paginator: MatPaginator;
  userlist: any = [];
  p: number = 1;
  user_required: boolean = false;
  startDate_required: boolean = false;
  staDate_required: boolean = false;
  endDate_required: boolean = false;
  endDate_error: boolean = false;
  starttime_required: boolean = false;
  endtime_required: boolean = false;
  user: any;
  startDate: any;
  endDate: any;
  stTime: any;
  edTime: any;
  userNameList: any = [];
  userData: any = [];
  filterData: any = [];
  nodata = false;
  totalDistance: string;
  distance: number;
  totDis: string;
  repostData: any = [];
  // pageNum: number = 1;
  mySelect: any = 5;
  // userlist: any=[];
  geoList: any = [];
  success = false;
  val: any;
  currentPageOffset: number = 0;
  userId: number;
  orgId: number;
  submitted: boolean;
  // beforesearchpage: any;
  paginatorShow:boolean= false;
  tableShow: boolean=false;

  constructor(private gs: GlobalserviceService,
    private fb: FormBuilder,
    private auth: AuthService,
    private router: Router,
    private datePipe: DatePipe,
    public dialog: MatDialog,
    private datepipe: DatePipe) {

    this.dataSource = new MatTableDataSource(this.filterData);

  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  ngOnInit(): void {
    let userdata = this.auth.getLocalStorageData();
    console.log(userdata);
    this.userId = userdata.userId;
    this.orgId = userdata.organization.organizationId;
    console.log("this.userId::", this.userId);
    console.log(userdata.organization.organizationId);
    let id = this.auth.getLocal_Org().organizationId
    console.log(userdata.userId)
    this.AssigngeofenceForm = this.fb.group({
      startdate: [null],
      enddate: [null],
      user: [null],
      geofenceName: [null]
    })

    // this.gs.get_User_list().subscribe((data: any) => {
    //   console.log(data);
    //   this.userList=data;
    //   console.log(this.userList);
    // })

    if (this.auth.getLocal_Org()?.organizationId) {
      let id = this.auth.getLocal_Org().organizationId
      console.log(userdata.userId)
      this.gs.get_user_org(id).subscribe((a: any) => {
        console.log(a);
        //  this.userlist=a
        this.userNameList = a;
        console.log(this.userNameList);
        a.map(
          (e: any) => {
            if (userdata.userType[0].userTypeName == "BUDDY USER") {
              if (e.userId != userdata.userId && (e.userType[0].userTypeName != "PRIMARY USER" && userdata.userType[0].userTypeName == "BUDDY USER")
                && e.buddyUser?.userId == userdata.userId) {
                console.log(e);
                this.userlist.push(e);
              }
            }
            else {
              if (e.userId != userdata.userId) {
                console.log(e)
                this.userlist.push(e);
              }
            }
          }
        )
        this.dataSource = new MatTableDataSource(this.userlist);
        this.dataSource.paginator = this.paginator;
        console.log(a)
      })
    } else {

      let id = this.auth.getLocalStorageData()?.userId;
      this.gs.get_primary_users_user(id).subscribe((a: any) => {
        a.map(
          (e: any) => {
            if (e.userId != id) {
              //  console.log(e)
              this.userlist.push(e);
            }
          }
        )
        // this.dataSource = new MatTableDataSource(this.userlist);
        // this.dataSource.paginator = this.paginator;
        // console.log(a)
      })
    }


    this.gs.get_Geofencelist(this.orgId).subscribe((d: any) => {
      console.log("this.geolist", d);
      this.geoList = d;
      this.geoList = Array.isArray(d) ? d : [d];
      console.log(this.geoList);
    })
  }


  startdate(event: any) {
    console.log('startDate', event.value);
    let startDate = this.datepipe.transform(event.value, 'YYYY-MM-dd');
    console.log(startDate);
    this.startDate = startDate;
    // this.startdate1 = startDate;
    console.log(this.startDate);
    console.log(this.endDate);
    if ((this.startDate != undefined && this.endDate != undefined) || (this.startDate != null && this.endDate != null)) {
      console.log('End Date not null');
      if (this.endDate >= this.startDate) {
        this.endDate_error = false;
        console.log('vaild Date');
      } else {
        this.endDate_error = true;
        console.log('Invaild Date');
      }
    }
  }

  enddate(event: any) {
    console.log('endDate', event.value);
    let endDate = this.datepipe.transform(event.value, 'YYYY-MM-dd');
    console.log(endDate);
    this.endDate = endDate;
    // this.enddate1 = endDate;
    console.log(this.endDate);
    if ((this.startDate != undefined && this.endDate != undefined) || (this.startDate != null && this.endDate != null)) {
      if (this.endDate >= this.startDate) {
        this.endDate_error = false;
        console.log('vaild Date');
      } else {
        this.endDate_error = true;
        console.log('Invaild Date');
      }
    }
  }

  get validate() { return this.AssigngeofenceForm.controls; }

  isselecteduser(event: any) {
    this.user_required = false;
    console.log("user", event.value);
    // this.submitted=false;
    this.user = event.value;
    console.log(this.user);
  }
  isselectedname(event: any) {
    console.log("selectGeofenceName");
  }
  EditGeofence() {



  }
  search() {
    console.log("submit");
    this.nodata =  false;

    let startDate = this.datepipe.transform(this.AssigngeofenceForm.value.startdate, 'YYYY-MM-dd');
    let endDate = this.datepipe.transform(this.AssigngeofenceForm.value.enddate, 'YYYY-MM-dd');
    console.log('StartDate', startDate, endDate);

    console.log(this.AssigngeofenceForm.value.user);
    console.log(this.AssigngeofenceForm.value.geofenceName);

    console.log('user', this.AssigngeofenceForm.value.user, this.AssigngeofenceForm.value.geofenceName, startDate, endDate);
    if (this.AssigngeofenceForm.value.user === null && this.AssigngeofenceForm.value.geofenceName === null && startDate === null && endDate === null) {
      console.log("user Invaild");
      alert("please Select user or Geofence Name");
    }

    if (this.AssigngeofenceForm.value.user === null && this.AssigngeofenceForm.value.geofenceName === null && startDate !== null && endDate === null) {
      console.log("user Invaild");
      alert("please Select End Date as well");
    }

    if (this.AssigngeofenceForm.value.user === null && this.AssigngeofenceForm.value.geofenceName === null && startDate === null && endDate !== null) {
      console.log("user Invaild");
      alert("please Select start Date as well");
    }

    if (this.AssigngeofenceForm.value.user === null && this.AssigngeofenceForm.value.geofenceName !== null && startDate !== null && endDate !== null) {
      console.log("user Invaild");
      alert("please Select User as well");
    }

    if (this.AssigngeofenceForm.value.user !== null && this.AssigngeofenceForm.value.geofenceName === null && startDate !== null && endDate !== null) {
      console.log("user Invaild");
      alert("please Select Geofence as well");
    }
    if (this.AssigngeofenceForm.value.user !== null && this.AssigngeofenceForm.value.geofenceName !== null && startDate == null && endDate !== null) {
      console.log("user Invaild");
      alert("please Select Start Date as well");
    }

    if (this.AssigngeofenceForm.value.user !== null && this.AssigngeofenceForm.value.geofenceName !== null && startDate !== null && endDate == null) {
      console.log("user Invaild");
      alert("please Select End Date as well");
    }



    else if (this.AssigngeofenceForm.value.user !== null && this.AssigngeofenceForm.value.geofenceName == null && startDate === null && endDate === null) {
      console.log("user only",);
      this.gs.get_usergeofencelist(this.AssigngeofenceForm.value.user)
        .subscribe((data: any) => {
          console.log(data);
          console.log(data.statusCode);
          if (data.errorCode == null) {
            this.nodata = false;
            this.filterData = data;
            this.paginatorShow =  true;
            console.log(this.filterData);
            this.dataSource = new MatTableDataSource(this.filterData);
            console.log(this.dataSource);
            this.dataSource.paginator = this.paginator;
            console.log(this.dataSource);
          }
          if (data.errorCode == 204) {
            console.log("204 No Data", this.filterData);
            this.filterData.length = 0;
            console.log(this.filterData);
            this.nodata = true;
            // this.dataSource = new MatTableDataSource(this.filterData);
            // console.log(this.dataSource);
            // this.dataSource.paginator = this.paginator;
            // console.log(this.dataSource);
          }
        },
          (error) => {
            console.log("error");
            if (error.error.errorCode == 204) {
              console.log("204 No Data", this.filterData);
              this.filterData.length = 0;
              console.log(this.filterData);
              this.nodata = true;
              this.dataSource = new MatTableDataSource(this.filterData);
              console.log(this.dataSource);
              this.dataSource.paginator = this.paginator;
              console.log(this.dataSource);
            }
          })
    }
    else if (this.AssigngeofenceForm.value.user == null && this.AssigngeofenceForm.value.geofenceName != null && startDate === null && endDate === null) {
      console.log("geofence only");
      this.gs.get_geofencelist(this.AssigngeofenceForm.value.geofenceName)
        .subscribe((data: any) => {
          console.log(data);
          if (data.errorCode == null) {
            this.nodata = false;
            this.filterData = data;
            this.paginatorShow =  true;
            console.log(this.filterData);
            this.dataSource = new MatTableDataSource(this.filterData);
            console.log(this.dataSource);
            this.dataSource.paginator = this.paginator;
            console.log(this.dataSource);
          }
          if (data.errorCode == 204) {
            console.log("204 No Data", this.filterData);
            this.filterData.length = 0;
            console.log(this.filterData);
            this.nodata = true;
            this.dataSource = new MatTableDataSource(this.filterData);
            console.log(this.dataSource);
            this.dataSource.paginator = this.paginator;
            console.log(this.dataSource);
          }
        },
          (error) => {
            console.log("error");
            if (error.error.errorCode == 204) {
              console.log("204 No Data", this.filterData);
              this.filterData.length = 0;
              console.log(this.filterData);
              this.nodata = true;
              this.dataSource = new MatTableDataSource(this.filterData);
              console.log(this.dataSource);
              this.dataSource.paginator = this.paginator;
              console.log(this.dataSource);
            }
          })
    }
    else if (this.AssigngeofenceForm.value.user !== null && this.AssigngeofenceForm.value.geofenceName !== null && startDate === null && endDate === null) {
      console.log("user and geofence both");
      this.gs.get_userandgeofencelist(this.AssigngeofenceForm.value.geofenceName, this.AssigngeofenceForm.value.user)
        .subscribe((data: any) => {
          console.log(data);
          if (data.errorCode == null) {
            this.nodata = false;
            this.filterData = data;
            this.paginatorShow =  true;
            console.log(this.filterData);
            this.dataSource = new MatTableDataSource(this.filterData);
            console.log(this.dataSource);
            this.dataSource.paginator = this.paginator;
            console.log(this.dataSource);
          }
          if (data.errorCode == 204) {
            console.log("204 No Data", this.filterData);
            this.filterData.length = 0;
            console.log(this.filterData);
            this.nodata = true;
            this.dataSource = new MatTableDataSource(this.filterData);
            console.log(this.dataSource);
            this.dataSource.paginator = this.paginator;
            console.log(this.dataSource);
          }
        },
          (error) => {
            console.log("error");
            if (error.error.errorCode == 204) {
              console.log("204 No Data", this.filterData);
              this.filterData.length = 0;
              console.log(this.filterData);
              this.nodata = true;
              this.dataSource = new MatTableDataSource(this.filterData);
              console.log(this.dataSource);
              this.dataSource.paginator = this.paginator;
              console.log(this.dataSource);
            }
          })
    }
    else if (startDate !== null && endDate !== null && this.AssigngeofenceForm.value.user === null && this.AssigngeofenceForm.value.geofenceName === null ) {
      console.log("user and geofence both");
      this.gs.filterGeofenceByDatesbasedOrg(startDate,endDate,this.orgId)
        .subscribe((data: any) => {
          console.log(data);
          if (data.errorCode == null) {
            this.nodata = false;
            this.filterData = data;
            this.paginatorShow =  true;
            console.log(this.filterData);
            this.dataSource = new MatTableDataSource(this.filterData);
            console.log(this.dataSource);
            this.dataSource.paginator = this.paginator;
            console.log(this.dataSource);
          }
          if (data.errorCode == 204) {
            console.log("204 No Data", this.filterData);
            this.filterData.length = 0;
            console.log(this.filterData);
            this.nodata = true;
            this.dataSource = new MatTableDataSource(this.filterData);
            console.log(this.dataSource);
            this.dataSource.paginator = this.paginator;
            console.log(this.dataSource);
          }
        },
          (error) => {
            console.log("error");
            if (error.error.errorCode == 204) {
              console.log("204 No Data", this.filterData);
              this.filterData.length = 0;
              console.log(this.filterData);
              this.nodata = true;
              this.dataSource = new MatTableDataSource(this.filterData);
              console.log(this.dataSource);
              this.dataSource.paginator = this.paginator;
              console.log(this.dataSource);
            }
          })
    }

    else if (startDate !== null && endDate !== null && this.AssigngeofenceForm.value.user !== null && this.AssigngeofenceForm.value.geofenceName !== null) {
      console.log("user and geofence both");
      this.gs.filterGeofenceByGeofenceIdUseridDatesBasedOrg( this.AssigngeofenceForm.value.user,this.AssigngeofenceForm.value.geofenceName, startDate, endDate,this.orgId)
        .subscribe((data: any) => {
          console.log(data);
          if (data.errorCode == null) {
            this.nodata = false;
            this.filterData = data;
            this.paginatorShow =  true;
            console.log(this.filterData);
            this.dataSource = new MatTableDataSource(this.filterData);
            console.log(this.dataSource);
            this.dataSource.paginator = this.paginator;
            console.log(this.dataSource);
          }
          if (data.errorCode == 204) {
            console.log("204 No Data", this.filterData);
            this.filterData.length = 0;
            console.log(this.filterData);
            this.nodata = true;
            this.dataSource = new MatTableDataSource(this.filterData);
            console.log(this.dataSource);
            this.dataSource.paginator = this.paginator;
            console.log(this.dataSource);
          }
        },
          (error) => {
            console.log("error");
            if (error.errorCode == 204) {
              console.log("204 No Data", this.filterData);
              this.filterData.length = 0;
              console.log(this.filterData);
              this.nodata = true;
              this.dataSource = new MatTableDataSource(this.filterData);
              console.log(this.dataSource);
              this.dataSource.paginator = this.paginator;
              console.log(this.dataSource);
            }
            if (error.error.errorCode == 204) {
              console.log("204 No Data", this.filterData);
              this.filterData.length = 0;
              console.log(this.filterData);
              this.nodata = true;
              this.dataSource = new MatTableDataSource(this.filterData);
              console.log(this.dataSource);
              this.dataSource.paginator = this.paginator;
              console.log(this.dataSource);
            }
          })
    }
  }

  // applyFilter(event: any) {
  //   console.log("search",event.target.value);
  //   const filterValue = (event.target as HTMLInputElement).value;
  //   console.log("filterValue",filterValue);
  //   this.dataSource.filter = filterValue.trim().toLowerCase();
  //   console.log(this.dataSource);
  //   console.log("dataSource Filter",this.dataSource.filter);
  //   console.log(this.dataSource.filter.length);
  // }
  //   applyFilter(searchTerm: string) 
  //   {
  //       searchTerm = searchTerm.toLowerCase();
  //       this.dataSource.filter = searchTerm;
  //     //  console.log(UserGeofence);
  //     // this.filterData.forEach(element => {
  //     //   console.log(element);
  //     //   this.val=element;
  //       // this.model(this.val);
  //       this.dataSource.filterPredicate = (data: UserGeofence, filter: string) => {
  //         const username = data.user.username.toLowerCase();
  //         const geofenceName = data.geofence.geofenceName.toLowerCase();
  //         return username.includes(filter) || geofenceName.includes(filter);
  //       };
  //   //  });
  // }

  applyFilter(event: any) {
    console.log("SEarch");
    const value = event.target.value.toLowerCase().trim();
    console.log(value);
    this.dataSource.filter = value;
    console.log(this.dataSource.filter);
    //  console.log(filter);
    this.dataSource.filterPredicate = (data: UserGeofence, filter: string) => {
      const username = data.user.name.toLowerCase();
      const geofenceName = data.geofence.geofenaceName.toLowerCase();
      return username.includes(filter) || geofenceName.includes(filter);
    }

  }
  // model(val)
  // {
  //   interface UserGeofence {
  //     userGeofenceId: number;
  //     status: {
  //       statusId: number;
  //       statusName: string;
  //     };
  //     user: {
  //       username: string;
  //       // other user properties
  //     };
  //     geofence: {
  //       geofenceName: string;
  //       // other geofence properties
  //     };
  //   }
  //   return UserGeofence;
  // }

  updateStatus(id: any, status: any) {
    console.log("Status", id, status);
    this.gs.updateAssignGeofenceStatus(id, status).subscribe((result: any) => {
      if (result.statusCode == 200) {
        // this.submitted=false;
        Swal.fire({
          text: result.description,
          icon: 'success',
          timer: 2000,
        })
        setTimeout(() => {
          this.router.navigateByUrl('/assign-geofence');
        }, 2000)
        this.search();
      }
      if (result.statusCode == 409) {
        Swal.fire({
          text: result.description,
          icon: 'error',
          timer: 2000
        })
        setTimeout(() => {
        }, 2000)
      }
    },
      err => {
        console.log("Error");
        Swal.fire({
          text: err.error.description,
          icon: 'error',
          timer: 2000
        })
        setTimeout(() => {
        }, 2000)
      }
    )
  }

  onPageChange(event: any) {
    console.log("index", event.pageIndex);
    console.log("size", event.pageSize);
    this.currentPageOffset = event.pageIndex * event.pageSize;
  }

  clear(){
    this.nodata = false;
    this.filterData = [];
  }

}
