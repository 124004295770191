import { Component, OnInit, ViewChild } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/Authenticaton/auth.service';
import { MessagingService } from 'src/app/service/messaging.service';
import { MatDialog } from '@angular/material/dialog';
import { SettingviewComponent } from 'src/app/Components/settingview/settingview.component';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { BehaviorSubject } from 'rxjs'
import { ToastrService } from 'ngx-toastr';
import { isJSDocThisTag } from 'typescript';
import { CookieService } from 'ngx-cookie-service';
import { ProfileViewComponent } from 'src/app/profile-view/profile-view.component';



@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {
  isShow: boolean | undefined
  isExpand:boolean | undefined
  @ViewChild('main') main:any;
  userTypeName:any
  localstorage:any;
  message :any = {};
  open = false;
  organizationName: string = 'JesperApps'
  myList = null
  notificationdata : any = [];
  notificationlist :any = [];
  freetraildata :any;
  isPrimaryUser: boolean = false
  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );
  enableAtt: any;
  enableTrack: any;
  enableAttType: any;
  track: any;

  constructor(private breakpointObserver: BreakpointObserver, private router: Router, private auth: AuthService  , private messagingService: MessagingService , private dialog : MatDialog , private Gs: GlobalserviceService ,private angularFireMessaging: AngularFireMessaging , private toastr: ToastrService ,
     private messagingservice : MessagingService ,  private cookieService: CookieService ) { }

  ngOnInit() {
    let userdata = this.auth.getLocalStorageData();
    console.log(userdata);
    console.log(userdata.organization);
    this.enableAtt=userdata.organization.attendance;
    this.enableAttType=userdata.organization.attendanceType;
    this.enableTrack=userdata.organization.track;
    console.log(this.enableAtt);
    console.log(this.enableAttType);
    console.log(this.enableTrack);
    this.notificationdata.length = 0;
    this.organizationName = this.auth.getLocal_Org().organizationName ? this.auth.getLocal_Org().organizationName : 'JesperApps'
    console.log(this.organizationName);
    let userTypeId = this.auth.get_local_userType()
    console.log("user type", this.auth.get_local_userType())
    this.userTypeName = this.auth.get_local_userTypeName()
    console.log(this.userTypeName);
    this.track=userdata.organization.track;
    console.log(this.track);
    this.localstorage = this.auth.getLocalStorageData()
    console.log(this.localstorage);

    if (userTypeId == 1) this.isPrimaryUser = true
     else this.isPrimaryUser = false


     this.angularFireMessaging.messages.subscribe(
      (payload: any) => {
          //alert("angular fire messaging ")
        console.log("new message received. ", payload);
      
        let x: any = localStorage.getItem("notifications");
        let y = JSON.parse(x)
         //  console.log("notifications");
           console.log(x);   

              if(x !=  null){
                if(x.length != 0)
                {
                 // alert("x.lengtj " + y.length)
                 this.notificationlist = y;
                }
              
              }

           
              this.toastr.show(payload.notification.body, payload.notification.title);
           
              
        console.log("message data")
        console.log(payload.data);
        console.log(payload.notification);

              let paydata = 
              {
                  data : JSON.parse(payload.data.data),
                  notification : payload.notification
              }

              this.notificationlist.push(paydata);
              this.notificationdata.push(paydata);

        localStorage.setItem('notifications', JSON.stringify( this.notificationlist)); 
      })

    let x: any = localStorage.getItem("notifications");
    let y = JSON.parse(x)
       console.log("notifications");
       console.log(x);
          if(y != null)
          {
            //this.notificationdata = y;

        y.filter(
              (e: any)=>{

                this.Gs.get_dont_track_byid(e.data.trackId).subscribe((dontnottrack: any) => {
                  console.log(dontnottrack)
                     
                  //  console.log("dontnottrack.approvalStatus.approvalStatusId"  + dontnottrack.approvalStatus.approvalStatusId)
                  if(dontnottrack.approvalStatus.approvalStatusId == 1)
                  {
                    
                    this.notificationdata.push(e);
                      //alert("has updated")
                  }
                    })
                 
             
             }
           )
          }else{
             this.notificationdata.length = 0;
          }
      
        // this.freetraildata = JSON.parse(y.data.data)
        // console.log(this.freetraildata);



       
       
  }


  showtrackdetail(id : any)
  {
      //alert("id" +id);
      let dontnottrackid = {
             id : id
          };
  
           console.log("object id "+  dontnottrackid.id)
      const dialogRef = this.dialog.open( SettingviewComponent, {
        width: '800px',
        disableClose: false,
        data : dontnottrackid,
      });
  }

  toggle() {
    this.isShow = !this.isShow
  }

  expand(){
    this.isExpand = !this.isExpand
  }

  logout() {
    Swal.fire({
      title: 'Are you sure? Do you want to logout',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3f51b5',
      cancelButtonColor: '#f44336',
      confirmButtonText: 'Logout'
    }).then((result) => {
      if (result.value) {
           this.messagingService.deletetoken();
           this.cookieService.deleteAll();
        localStorage.clear();
        sessionStorage.clear();
        this.router.navigate(['/login'])
        // Swal.fire(
        //   'Done!'
        // )
      }
      //
    })
  }

  profile(){
     const dialogRef = this.dialog.open(ProfileViewComponent,{
          disableClose: true,
          // data : a,
          width: '600px',
        })
  }
}
