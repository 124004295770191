<!-- <div *ngFor="let a  of  viewLocationDetails[0].locationDetails">
   <span class="fw-bold mr-2 mt-2">* </span>   <span class="fw-bold mr-2">Alias Name:</span>{{a?.aliasName}} -<span class="fw-bold ml-2">Location:</span>  {{a?.address}}


</div> -->
<div class="table-wrapper" *ngIf="dataSource.filteredData.length > 0; else noData">
  <div style="overflow-x: auto;">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 custom-table">
      <!-- Sl.No Column -->
      <ng-container matColumnDef="sino">
        <th mat-header-cell *matHeaderCellDef class="text-center align-middle">
          <b>Sl.No</b>
        </th>
        <td mat-cell *matCellDef="let element; let i = index" class="text-center align-middle">
          {{ currentPageOffset + i + 1 }}
        </td>
      </ng-container>

      <!-- Alias Name Column -->
      <ng-container matColumnDef="aliasname">
        <th mat-header-cell *matHeaderCellDef class="text-center align-middle">
          <b>Alias Name</b>
        </th>
        <td mat-cell *matCellDef="let element" class="align-middle">
          {{ element.aliasName }}
        </td>
      </ng-container>

      <!-- Address Column -->
      <ng-container matColumnDef="address">
        <th mat-header-cell *matHeaderCellDef class="text-center align-middle">
          <b>Address</b>
        </th>
        <td mat-cell *matCellDef="let element" class="align-middle">
          {{ element.address }}
        </td>
      </ng-container>

      <!-- Header and Row Definitions -->
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>

  <!-- Paginator -->
  <div *ngIf="dataSource.filteredData.length > 5">
    <mat-paginator
      (page)="onPageChange($event)"
      [pageSizeOptions]="[5, 10]"
      showFirstLastButtons>
    </mat-paginator>
  </div>
</div>


<ng-template #noData>
  <div class="p-3">
    <div class="no-data card text-center" style="text-align: center !important;
    background-color: #d44c39fa;
    border-radius: 5px;">
      <div class="card-body">No Location</div>
    </div>
  </div>
</ng-template>
  