<!-- <div>
    <ul style="list-style-type: none;">
      <li class="m-3" *ngFor="let location of data?.locationDetails; let i = index">
        <div *ngIf="location?.address">
          <span class="mr-3 font-weight-bold fs-4">*</span>{{ location?.address }}
        </div>
      </li>
    </ul>
</div> -->

<div *ngIf="dataSource.filteredData.length>0;else noData" >
  <table mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="sino">
          <th mat-header-cell *matHeaderCellDef class="text-center align-middle m-4"> <b class="ml-3">Sl.No</b> </th>
          <td mat-cell *matCellDef="let element; let i =index" class="text-center align-middle"> {{ currentPageOffset + i + 1 }}</td>
        </ng-container>
        
        <ng-container matColumnDef="address" class="ml-4">
          <th mat-header-cell *matHeaderCellDef class="pr-4 align-middle text-center"> <!-- Added class here for spacing -->
            <b class="mr-4 ml-4 align-middle text-center">Address</b>
          </th>
          <td mat-cell *matCellDef="let element" class="border pl-4"> <!-- Added class here for spacing -->
            {{element.address}}
          </td>
        </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <div *ngIf="dataSource.filteredData.length>5">
      <mat-paginator (page)="onPageChange($event)" [pageSizeOptions]="[5, 10]" showFirstLastButtons></mat-paginator>
    </div>
  </div>
  <ng-template #noData>
    <div class="p-3">
      <div class="no-data card text-center" style="text-align: center !important;
      background-color: #d44c39fa;
      border-radius: 5px;">
        <div class="card-body">No Location</div>
      </div>
    </div>
  </ng-template>
    