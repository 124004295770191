<div class="container">
  <div class="mat-elevation-z8">
    <form [formGroup]="AssigngeofenceForm">
      <div class="col-md-12" style="white-space: nowrap">
        <h2 class="pl-5 pt-3 float-left">Assign Geofence</h2>
        <div class="float-right pt-3">
          <!-- style="background: #b0636a !important;color: white;height: 39px!important
        ;" -->
          <button class="normal-button"  mat-flat-button [routerLink]="['/assign']">
            <!-- <span class="material-icons"> add </span> -->
            <span>Assign Geofence</span>
          </button>
        </div>
      </div>
      <div>
        <div class="col-md-12">
          <div class="float-right col-md-12" *ngIf="filterData.length > 0">
            <!-- <mat-form-field appearance="outline" style="width: 100%; ">
                            <mat-icon matPrefix>search</mat-icon>
                            <mat-label>Search</mat-label>
                           
                            <input matInput (input)="applyFilter($event)"  placeholder="Search UserName/Geofenacename">
                        </mat-form-field> -->
            <mat-form-field appearance="outline">
              <mat-icon matPrefix>search</mat-icon>
              <mat-label>Search</mat-label>
              <input
                matInput
                (input)="applyFilter($event)"
                placeholder="Search UserName/Geofenacename"
              />
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="row pl-5" style="">
        <div class="col-md-2">
          <mat-form-field
            class="example-full-width custom-mat-form-field"
            appearance="outline"
            style="display: inline"
          >
            <mat-label>Start Date</mat-label>
            <input
              matInput
              formControlName="startdate"
              (dateChange)="startdate($event)"
              placeholder="Choose a Date"
              [matDatepicker]="picker1"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="picker1"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
          </mat-form-field>
          <div
            class="valid-msg"
            style="position: relative; top: -15px"
            *ngIf="
              (validate.startdate.invalid && validate.startdate.touched) ||
              validate.startdate.dirty ||
              submitted
            "
          >
            <span
              *ngIf="validate.startdate.errors?.required"
              class="text-danger"
              >Please select start date</span
            >
            <!-- <span *ngIf="" class="text-danger">Please select vaild start date</span> -->
          </div>
        </div>
        <div class="col-md-2">
          <mat-form-field
            class="example-full-width"
            appearance="outline"
            style="display: inline"
          >
            <mat-label>End Date</mat-label>
            <input
              matInput
              formControlName="enddate"
              (dateChange)="enddate($event)"
              placeholder="Choose a Date"
              [min]="startDate"
              [matDatepicker]="picker2"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="picker2"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker2></mat-datepicker>
          </mat-form-field>
          <div
            class="valid-msg"
            style="position: relative; top: -15px"
            *ngIf="
              (validate.enddate.invalid && validate.enddate.touched) ||
              validate.enddate.dirty ||
              submitted
            "
          >
            <span *ngIf="validate.enddate.errors?.required" class="text-danger"
              >Please select end date</span
            >
            <span *ngIf="endDate_error" class="text-danger"
              >Please select vaild end date</span
            >
          </div>
        </div>

        <div class="col-lg-2 col-md-2 col-sm-12">
          <mat-form-field
            class="example-full-width"
            appearance="outline"
            style="display: inline"
          >
            <mat-label>Choose User </mat-label>
            <mat-select
              placeholder="Select User"
              formControlName="user"
              (selectionChange)="isselecteduser($event)"
            >
              <mat-option value="0"> -- Select User -- </mat-option>
              <mat-option *ngFor="let user of userlist" [value]="user.userId">{{
                user.name
              }}</mat-option>
            </mat-select>
          </mat-form-field>
          <!-- <span class="error_msg" *ngIf="user_required">*Please select user</span>  -->
        </div>
        <div class="col-lg-2 col-md-2 col-sm-12">
          <mat-form-field
            class="example-full-width"
            appearance="outline"
            style="display: inline"
          >
            <mat-label>Choose Geofence Name</mat-label>
            <mat-select
              placeholder="Select Geofence Name"
              formControlName="geofenceName"
              (selectionChange)="isselectedname($event)"
            >
              <mat-option value="0"> -- Select Geofence Name -- </mat-option>
              <mat-option
                *ngFor="let geofence of geoList"
                [value]="geofence.geofenaceId"
                >{{ geofence.geofenaceName }}</mat-option
              >
            </mat-select>
          </mat-form-field>
          <!-- <span class="error_msg" *ngIf="user_required">*Please select Geofence Name</span>  -->
        </div>
        <div class="col-lg-1 col-md-1 col-sm-12 ml-3 mt-2">
          <!-- class="btn btn-primary btm-sm" -->
          <!-- style="position: relative; top: 5px; font-size: 14px" -->
          <button
            type="button"
            class="normal-button"
            (click)="search()"
           
            mat-flat-button
          >
            Search
          </button>
        </div>
        <!-- class="btn btn-primary btm-sm"
        style="position: relative; top: 5px; font-size: 14px" -->
        <div class="col-lg-1 col-md-1 col-sm-12 mt-2" style="margin-left: -3px">
          <button
          mat-flat-button
            type="reset"
          class="clear-button"
          (click)="clear()"
          >
            Clear
          </button>
        </div>
      </div>
      <div class="table-responsive" *ngIf="filterData.length > 0">
        <table mat-table [dataSource]="dataSource">
          <div class="col-md-12">
            <!-- <ng-container matColumnDef="sno">
                <th mat-header-cell *matHeaderCellDef class="col-md-1 col-sm-1"> <b>Sl.No</b> </th>
                <td mat-cell  *matCellDef="let element; let i =index"> {{i+1}}  </td> 
            </ng-container> -->
            <ng-container matColumnDef="sno">
              <th mat-header-cell *matHeaderCellDef class="col-md-1 col-sm-1">
                <b>SI.NO</b>
              </th>
              <td mat-cell *matCellDef="let element; let i = index">
                {{ currentPageOffset + i + 1 }}
              </td>
            </ng-container>

            <!-- <ng-container matColumnDef="startdate" > -->
            <!-- <th mat-header-cell *matHeaderCellDef class="col-md-2 col-sm-2"> <b>Start Date</b> </th> -->
            <!-- <td mat-cell  *matCellDef="let element" style="white-space: nowrap;"> {{element.user.name}}</td> -->
            <!-- </ng-container> -->
            <!-- <ng-container matColumnDef="enddate" > -->
            <!-- <th mat-header-cell *matHeaderCellDef class="col-md-2 col-sm-2"> <b>End Date</b> </th> -->
            <!-- <td mat-cell  *matCellDef="let element" style="white-space: nowrap;"> {{element.user.name}}</td> -->
            <!-- </ng-container> -->

            <ng-container matColumnDef="username">
              <th mat-header-cell *matHeaderCellDef class="col-md-2 col-sm-2">
                <b>USER NAME</b>
              </th>
              <td
                mat-cell
                *matCellDef="let element"
                style="white-space: nowrap"
              >
                {{ element.user.name }}
              </td>
            </ng-container>

            <ng-container matColumnDef="geofencename">
              <th mat-header-cell *matHeaderCellDef class="col-md-2 col-sm-2">
                <b>GEOFENCE NAME</b>
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.geofence.geofenaceName }}
              </td>
            </ng-container>
            <ng-container matColumnDef="startDate">
              <th mat-header-cell *matHeaderCellDef class="col-md-2 col-sm-2">
                <b>Start Date </b>
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.fromDate | date : "dd/MM/yyyy" }}
              </td>
            </ng-container>
            <ng-container matColumnDef="endDate">
              <th mat-header-cell *matHeaderCellDef class="col-md-2 col-sm-2">
                <b>End Date</b>
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.toDate | date : "dd/MM/yyyy" }}
              </td>
            </ng-container>
            <!-- <ng-container matColumnDef="clientName">
                <th mat-header-cell *matHeaderCellDef  class="col-md-3 col-sm-3"> <b>CLIENT NAME</b> </th>
                <td>Clicnt name here</td>
            </ng-container>
            <ng-container matColumnDef="clientLocation">
              <th mat-header-cell *matHeaderCellDef  class="col-md-3 col-sm-3"> <b>CLIENT GEO LOCATION</b> </th>
               <td>client location</td>
          </ng-container> -->

            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef class="col-md-2 col-sm-2">
                <b>STATUS</b>
              </th>
              <td
                mat-cell
                *matCellDef="let element"
                [ngClass]="{
                  'active-status': element.status.statusName === 'Active',
                  'inactive-status': element.status.statusName === 'InActive'
                }"
              >
                {{ element.status.statusName }}
              </td>
            </ng-container>
            <ng-container matColumnDef="view">
              <th mat-header-cell *matHeaderCellDef class="col-md-1 col-sm-1">
                <b>ACTION</b>
              </th>
              <td mat-cell *matCellDef="let element">
                <button mat-icon-button [matMenuTriggerFor]="beforeMenu">
                  <mat-icon>more_vert</mat-icon>
                </button>
                <div class="side_setting">
                  <mat-menu #beforeMenu="matMenu" xPosition="after">
                    <!-- <a  mat-menu-item    [routerLink]="['/assign']">  -->
                    <!-- <span class="material-icons v"
                          style="    transform: translateY(7px);font-size: 22px;margin-right:3px">edit</span> -->
                    <!-- Edit  -->
                    <!-- Geofence</a> -->
                    <button
                      mat-menu-item
                      *ngIf="element.status.statusId == 1"
                      (click)="updateStatus(element.userGeofenceId, 2)"
                    >
                      InActive
                    </button>
                    <button
                      mat-menu-item
                      *ngIf="element.status.statusId == 2"
                      (click)="updateStatus(element.userGeofenceId, 1)"
                    >
                      Active
                    </button>
                  </mat-menu>
                </div>
              </td>
            </ng-container>
          </div>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

          <tr class="mat-row" *matNoDataRow>
            <td class="pl-3 pr-3" colspan="7" align="center">
              <!-- style="height: 60px; width: 115%" -->
              <div class="alert alert-secondary">
                <h4 class="pt-2">No Assign Geofence List Found</h4>
              </div>
            </td>
          </tr>
          <!-- <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="6" id="nodata" style="text-align: center;">No data found
                <td>
            </tr> -->
        </table>
      </div>
    </form>
    <div class="p-3" *ngIf="nodata">
      <div class="no-data card text-center">
        <!-- <tr class="mat-row" *matNoDataRow> -->
        <td colspan="6" class="card-body">No Data</td>
        <!-- </tr> -->
      </div>
    </div>
    <!-- <mat-paginator
      *ngIf="filterData.length > 0"
      (page)="onPageChange($event)"
      [pageSizeOptions]="[5, 10, 20, 30, 50, 100]"
    ></mat-paginator> -->
    <div>
      <!-- <mat-paginator  [pageSizeOptions]="[5,10,20,30,50,100]"   showFirstLastButtons ></mat-paginator>  -->
      <mat-paginator
        [hidden]="filterData.length === 0"
        [length]="filterData.length"
        [pageSize]="5"
        [pageSizeOptions]="[5, 10, 20, 30, 50, 100]"
        showFirstLastButtons
        (page)="onPageChange($event)"
      >
      </mat-paginator>
    </div>
  </div>
</div>
