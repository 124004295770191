<ng-container *ngIf="loading">
    <div class="loader">
      <app-loading></app-loading>
    </div>
  </ng-container>
  <!-- <mat-card class="col-md-8 scrollable-form" > -->
  <mat-card class="card">
    <br />
    <h2 class="pl-3 "> Assign Location to Client</h2>
    <!-- <mat-card-title> Assign Location to Client </mat-card-title> -->
    <br />
  
    <form     action="" [formGroup]="AddLocationForm" (ngSubmit)="addLocation()">
      <div>
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12">
                <mat-form-field  class="example-full-width" appearance="outline" style="display: inline;">
                    <mat-label>Choose Client </mat-label> 
                    <mat-select (selectionChange)="getClient()" placeholder="Select Client" formControlName="user"  >
                        <mat-option value="0"> -- Select Client -- </mat-option>
                        <mat-option  (click)="getClientName(client)"    *ngFor="let client of clientsList" value="{{client.clientId}}" >{{client.clientName}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <!-- <span class="error_msg" *ngIf="user_required">*Please select user</span>  -->
           </div>
          <!-- <div class="form-group col-lg-4">
            <label> Client Code</label>
            <input
              type="text"
              placeholder="Enter Client Code"
              formControlName="clientcode"
              class="form-control"
            />
            <mat-error *ngIf="f.clientcode.hasError('required')">
              Please select <strong>Client Code</strong>
            </mat-error>
            <mat-error *ngIf="f.clientcode.hasError('pattern')">
              Please enter a valid Client Code
            </mat-error>
          </div>
          <div class="form-group col-lg-4">
            <label> Client Name</label>
            <input
              type="text"
              placeholder="Enter Client Name"
              formControlName="clientname"
              class="form-control"
            />
            <mat-error *ngIf="f.clientname.hasError('required')">
              Please select <strong>Client Name</strong>
            </mat-error>
            <mat-error *ngIf="f.clientname.hasError('pattern')">
              Please enter a valid Client Name
            </mat-error>
          </div> -->
  
          <!-- <div class="col-lg-1 col-md-1 col-sm-12 mt-2">
              <button type="button" class="btn btn-primary btm-sm"   style="position: relative;bottom:-12px;font-size: 14px;height: 25px;">Create Client</button>
          </div> -->
          <!-- <div class="col-lg-1 mt-3" style="margin-top: inherit;">
            <button class="btn btn-primary btn-sm fs-4" style="height: 30px;">Assign Client Location</button>

          </div> -->
        </div>
<!--   
        <div class="form-group">
          <label>Search Address</label>
          <input
            type="text"
            id="address"
            formControlName="AddressName"
            class="form-control"
            (keydown.enter)="$event.preventDefault()"
            (keyup)="getaddress()"
            placeholder="Search Location"
            autocorrect="off"
            autocapitalize="off"
            spellcheck="off"
            type="text"
            #search
            style="font-size: 14px;"
          />
          <mat-error *ngIf="f.AddressName.hasError('required')">
            Please enter search address
          </mat-error>
          <mat-error *ngIf="f.AddressName.hasError('pattern')">
            Please enter a valid search address
          </mat-error>
        </div> -->
        <div class="form-group">
          <label>Search Address</label>
          <input
            type="text"
            id="address"
            formControlName="AddressName"
            class="form-control"
            (keydown.enter)="$event.preventDefault()"
            (keyup)="getaddress()"
            placeholder="Search Location"
            autocorrect="off"
            autocapitalize="off"
            spellcheck="off"
            type="text"
            #search
            style="font-size: 14px;"
          />
          <mat-error *ngIf="f.AddressName.hasError('required')">
            Please enter search address
          </mat-error>
          <mat-error *ngIf="f.AddressName.hasError('pattern')">
            Please enter a valid search address
          </mat-error>
        </div>
  
        <br />
        <agm-map *ngIf="canReloadMap"
          [latitude]="latitude"
          [longitude]="longitude"
          [zoom]="zoom"
          (mapClick)="mapClicked($event)">
          <!-- <agm-marker
            *ngFor="let marker of markers;let i = index"
            [latitude]="latitude"
            [longitude]="longitude"
            (dragEnd)="markerDragEnd($event,i)"
            (mapReady)="onMapReady($event)"
            [markerDraggable]="true"
          ></agm-marker> -->
           <agm-marker
              *ngFor="let marker of markers; let i = index"
              [latitude]="marker.lat"
              [longitude]="marker.lng"
              (dragEnd)="markerDragEnd($event, i)"
              (mouseOver)="infoWindow.open()"
              (mouseOut)="infoWindow.close()"
              [markerDraggable]="marker.draggable">
              <agm-info-window  [disableAutoPan]="false" #infoWindow>
                <div style="background-color: #e60707; color: white; padding: 10px; border-radius: 5px;" *ngIf="marker.aliaName=='Default Drag Marker';else temp">
                     <span>{{marker.aliaName}}</span>
                </div>
                <ng-template #temp>
                     <span>Alias Name:{{marker.aliaName}}</span>
                </ng-template>
              </agm-info-window>
              <!-- <agm-info-window>
                <strong>{{ marker.aliaName }}</strong>
              </agm-info-window> -->
           </agm-marker>
        </agm-map>
  
        <!-- <h5>Address: {{address}}</h5> -->
        <div><span class="fw-bold">Latitude:</span> {{ latitude }}</div>
        <div class="mt-2"><span class="fw-bold ">Longitude:</span> {{ longitude }}</div>
        <div class="mt-2" *ngIf="listClientName"><span class="fw-bold ">Client:</span>  {{listClientName}}</div>
        <div class="mt-2" *ngFor="let a  of  locationDetails">
          <span class="fw-bold mr-2 mt-2">Alias Name:</span>{{a?.aliasName}} -<span class="fw-bold ml-2">Location:</span>  {{a?.address}}
        </div>
        <!-- <div class="row mb-2" *ngFor="let a  of  locationDetails">
          <div class="col-md-4">
              <span class="fw-bold mr-2 mt-2">Alias Name:</span>
              <input type="text" formControlName="aliasName" placeholder="Enter AliasName">
          </div>
          <div class="col-md-8">
            <span class="fw-bold ml-2">Location:</span>  {{a?.address}}
          </div>
        </div> -->
      </div>
  
      <div style="color: red" *ngIf="f.address.invalid">
        Please select the location to track
      </div>
      <div class="mb-5">
        <button
          mat-stroked-button
          color="warn"
          style="width: 100%; margin: 20px 0 0 8px"
          type="submit"
          mat-button
        >
          Create Location
        </button>
      </div>
    </form>
  </mat-card>
  