import { Injectable, Output, EventEmitter } from '@angular/core';
import { globalTimeOut, url } from './properties';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, catchError, timeout, switchMap } from 'rxjs/operators';
import { identifierModuleUrl } from '@angular/compiler';
// import { ErrorObservable } from 'rxjs/observable/ErrorObservable';
// import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class GlobalserviceService {



  myFile: any;


  constructor(private http: HttpClient) { }
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  //---------------------getmethod sample---------------------------------

  // usertype
  get_usertype() {
    return this.http.get<any[]>(`${url}user-type`)
  }
  get_user_org(org_id: any) {
    return this.http.get<any[]>(`${url}user/organization/${org_id}`)
  }
  getClientbyOragnization(org_id: any) {
    return this.http.get<any[]>(`${url}client/organization/${org_id}`)
  }

  getClientbyClientId(ClinetId: any) {
    return this.http.get<any[]>(`${url}client/${ClinetId}`)
  }
  updateClient(ClinetId: any, data: any) {
    return this.http.put<any[]>(`${url}client/${ClinetId}`, data)
  }


  get_client_org(org_id: any) {
    return this.http.get<any[]>(`${url}client/organization/${org_id}`)
  }
  assign_Employee_shifts(data: any) {
    return this.http.post(`${url}shift-employee-assign/multiple`, data);
  }
  assign_OneEmployee_shift(data: any) {
    return this.http.post(`${url}shift-employee-assign/`, data);

  }
  get_primary_users_user(primaryUserId: any) {
    return this.http.get<any[]>(`${url}user/primaryUser/${primaryUserId}`)
  }

  getLoginLogoutReportsforPrimaryUser(orgId: any) {
    return this.http.get<any[]>(`${url}employee_shift_login_details/login/logout/report/org/${orgId}`)
  }

  getLoginLogoutReportsforEmployee(empId: any) {
    return this.http.get<any[]>(`${url}employee_shift_login_details/login/logout/report/employee/${empId}`)
  }

  getLoginLogoutReportsforBuddyUser(userId: any) {
    return this.http.get<any[]>(`${url}employee_shift_login_details/login/logout/report/buddyUser/${userId}`)
  }


  //-----------------------------------Non-shift-based-Login-logout-reports----------------------------

  //----------------------Primary User------------------------------------------

  //-------------Non Shift-----------------------------------
  getNonShiftLoginDetailsforPrimaryUser(orgId: number) {
    return this.http.get<any[]>(`${url}employee_shift_login_details/login/logout/report/org/non-shift/${orgId}`);
  }

  //------------------------- Shift-----------------------------------

  getShiftLoginDetailsforPrimaryUser(orgId: number) {
    return this.http.get<any[]>(`${url}employee_shift_login_details/login/logout/report/org/shift/${orgId}`);
  }


  //----------------------Buddy User------------------------------------------

  //-------------Non Shift-----------------------------------
  getNonShiftLoginDetailsforBuddyuser(userId: number) {
    return this.http.get<any[]>(`${url}employee_shift_login_details/login/logout/report/buddyUser/non-shift/${userId}`);
  }
  //------------------------- Shift-----------------------------------
  getShiftLoginDetailsforBuddyuser(userId: number) {
    return this.http.get<any[]>(`${url}employee_shift_login_details/login/logout/report/buddyUser/shift/${userId}`);
  }
  //----------------------Employee User--------------------------------------------

  //-------------Non Shift-----------------------------------
  getNonShiftLoginDetailsforEmployee(empId: number) {
    return this.http.get<any[]>(`${url}employee_shift_login_details/login/logout/report/employee/non-shift/${empId}`);
  }
  //------------------------- Shift-----------------------------------

  getShiftLoginDetailsforEmployee(empId: number) {
    return this.http.get<any[]>(`${url}employee_shift_login_details/login/logout/report/employee/shift/${empId}`);
  }

  //--------------------------search loginlogout reports based on date-----------------


  searchLoginReportsbasedOnDates(date: any) {
    return this.http.get<any[]>(`${url}employee_shift_login_details/login/logout/report/logindate/${date}`);
  }

  //--------------------------search loginlogout reports based on employee number or user name ----------------------


  searchLoginReportsbasedOnUser(user: any) {
    return this.http.get<any[]>(`${url}employee_shift_login_details/login/logout/report/filter/${user}`);
  }



  //--------------------------search loginlogout reports based on employee number or user name ----------------------

  searchLoginReportsbasedOnUserandDate(user: any, date: any) {
    return this.http.get<any[]>(`${url}employee_shift_login_details/login/logout/report/loginDate/filter/${user}/${date}`);
  }

  //http://localhost:6002/trackingsupervisor/employee_shift_login_details/login/logout/report/loginDate/filter/jo/2024-10-07


  getCurrentLocation(createdByUser: any) {
    return this.http.get<any[]>(`${url}livelocation/reports/createdByUser/${createdByUser}`)
  }

  getLoginLogoutReports(orgid: any, userid: any) {
    return this.http.get<any[]>(`${url}employee_shift_login_details/login/logout/report/${orgid}/${userid}`)
  }
  // -------------------------------Search Employee numm or name based org shift based-----------------------------
  searchEmployeeNumberornamebasedShiftandOrg(orgId: number, user: any) {
    return this.http.get<any[]>(`${url}employee_shift_login_details/login/logout/report/filter/shift/org/${orgId}/${user}`);
  }

  // -------------------------------Search Employee numm or name based org non-shift based-----------------------------

  searchEmployeeNumberornamebasedNonshiftandOrg(orgId: number, user: any) {
    return this.http.get<any[]>(`${url}employee_shift_login_details/login/logout/report/filter/non-Shift/org/${orgId}/${user}`);
  }

  //-------------------shift date based----------------------------------

  searchDateShiftBased(orgId: number, date: any) {
    return this.http.get<any[]>(`${url}employee_shift_login_details/login/logout/report/logindate/shift/org/${orgId}/${date}`);
  }

   //-------------------shift fromDate , toDate based primary user, org based----------------------------------

   searchFromDateToDateShiftBased(orgId: number, startDate: any, endDate:any) {
    return this.http.get<any[]>(`${url}employee_shift_login_details/login/logout/report/date/org/Shift/${orgId}/${startDate}/${endDate}`);
  }

    //-------------------non-shift fromDate , toDate based ,based primary user, org based----------------------------------

    searchFromDateToDateNonShiftBased(orgId: number, startDate: any, endDate:any) {
      return this.http.get<any[]>(`${url}employee_shift_login_details/login/logout/report/date/org/Non-Shift/${orgId}/${startDate}/${endDate}`);
    }



  //-------------------Non-shift date based----------------------------------

  searchDateNonShiftBased(orgId: number, date: any) {
    return this.http.get<any[]>(`${url}employee_shift_login_details/login/logout/report/logindate/non-shift/org/${orgId}/${date}`);
  }

  // -------Non shift employee and Date primary user----------------

  searchDateandEmployeeNonShiftBased(orgId: number, employee: any, date: any) {
    return this.http.get<any[]>(`${url}employee_shift_login_details/login/logout/report/loginDate/filter/non-shift/org/${orgId}/${employee}/${date}`);
  }

   // -------Non shift employee and fromDate , toDate primary user----------------

   searchfromDateToDateandEmployeeNonShiftBased(orgId: number, keywords: any, fromDate: any,toDate:any) {
    return this.http.get<any[]>(`${url}employee_shift_login_details/login/logout/report/Date/filter/non-shift/org/${orgId}/${keywords}/${fromDate}/${toDate}`);
  }

   // -------Non shift employee and fromDate , toDate primary user----------------

   searchfromDateToDateandEmployeeShiftBased(orgId: number, keywords: any, fromDate: any,toDate:any) {
    return this.http.get<any[]>(`${url}employee_shift_login_details/login/logout/report/Date/filter/shift/org/${orgId}/${keywords}/${fromDate}/${toDate}`);
  }


  // http://localhost:6002/trackingsupervisor/employee_shift_login_details/login/logout/report/Date/filter/non-shift/org/30/Ky/2024-10-21/2024-12-26

  // http://localhost:6002/trackingsupervisor/employee_shift_login_details/login/logout/report/Date/filter/buddyUser/non-shift/94/Ky/2024-10-21/2024-12-26


  //------------------Shift Employee and Date --------------------------
  searchDateandEmployeeShiftBased(orgId: number, employee: any, date: any) {
    return this.http.get<any[]>(`${url}employee_shift_login_details/login/logout/report/loginDate/filter/shift/org/${orgId}/${employee}/${date}`);
  }

  //userId,Date based shift Filter Buddy user
  filterUseridDateShiftBased( date: any,userid:number) {
    return this.http.get<any[]>(`${url}employee_shift_login_details/login/logout/report/logindate/buddyUser/shift/${date}/${userid}`);
  }

  //userId,fromdate,sdate filter buudyuser shift
  filterUseridfromDateToDateShiftBased( userId:number,fromDate:string,toDate:string) {
    return this.http.get<any[]>(`${url}http://localhost:6002/trackingsupervisor/employee_shift_login_details/login/logout/report/date/buddyUser/Shift/${userId}/${fromDate}/${toDate}
`);
  }

    //userId,fromdate,sdate filter buudyuser nonshift
    filterUseridfromDateToDateNonShiftBased( userId:number,fromDate:string,toDate:string) {
      return this.http.get<any[]>(`${url}employee_shift_login_details/login/logout/report/date/buddyUser/NonShift/${userId}/${fromDate}/${toDate}`);
    }
  



    //userId,Date based no-shift Filter Buddy user
    filterUseridDateNonShiftBased( date: any,userid:number) {
      return this.http.get<any[]>(`${url}employee_shift_login_details/login/logout/report/logindate/buddyUser/non-shift/${date}/${userid}`);
    }

      //userId,Date based shift Filter employee user
      filterEmpIdDateShiftBased( date: any,empId:number) {
        return this.http.get<any[]>(`${url}employee_shift_login_details/login/logout/loginDate/employee/shift/${date}/${empId}`);
      }

        //userId,  fromDate todate  based shift Filter employee user
        filterEmpIdfromDateToDateShiftBased( empId:number,fromDate:string,toDate:string) {
          return this.http.get<any[]>(`${url}employee_shift_login_details/login/logout/report/date/emp/Shift/${empId}/${fromDate}/${toDate}`);
        }

             //userId,  fromDate todate  based non shift Filter employee user
             filterEmpIdfromDateToDateNonShiftBased( empId:number,fromDate:any,toDate:any) {
              return this.http.get<any[]>(`${url}employee_shift_login_details/login/logout/report/date/emp/NonShift/${empId}/${fromDate}/${toDate}`);
            }

        // http://localhost:6002/trackingsupervisor/employee_shift_login_details/login/logout/report/date/emp/Shift/60/2024-10-21/2024-12-26
        //userId,Date based shift Filter employee user
        filterEmpIdDateNonShiftBased( date: any,empId:number) {
          return this.http.get<any[]>(`${url}employee_shift_login_details/login/logout/loginDate/employee/non-shift/${date}/${empId}`);
        }

          //userId,Emp Name 0r number, Date based shift Filter buddy user
          filterUserIdDateEmpNumShiftBased(userId:number,empId:number,date: any) {
            return this.http.get<any[]>(`${url}employee_shift_login_details/login/logout/report/loginDate/filter/buddyUser/shift/${userId}/${empId}/${date}`);
          }

             //userId,Emp Name 0r number, Date based non shift Filter buddy user
             filterUserIdDateEmpNumNonShiftBased(userId:number,empId:number,date: any) {
              return this.http.get<any[]>(`${url}employee_shift_login_details/login/logout/report/loginDate/filter/buddyUser/non-shift/${userId}/${empId}/${date}`);
            }


                  //userId,Emp Name 0r number, fromDate , toDate based non shift Filter buddy user
                  filterUserIdfromDateToDateEmpNumNonShiftBased(userId:number,keywords:number,fromdate: any,toDate:any) {
                    return this.http.get<any[]>(`${url}employee_shift_login_details/login/logout/report/Date/filter/buddyUser/non-shift/${userId}/${keywords}/${fromdate}/${toDate}`);
                  }
                 
                  filterUserIdfromDateToDateEmpNumShiftBased(userId:number,keywords:number,fromdate: any,toDate:any) {
                    return this.http.get<any[]>(`${url}employee_shift_login_details/login/logout/report/Date/filter/buddyUser/Shift/${userId}/${keywords}/${fromdate}/${toDate}`);
                  }
                  // http://localhost:6002/trackingsupervisor/employee_shift_login_details/login/logout/report/Date/filter/buddyUser/Shift/9/RA/2024-10-21/2024-12-26


            //userId,Emp Name 0r number, Date based non shift Filter buddy user
            filterUserIdEmpNumNonShiftBased(userId:number,empId:number) {
              return this.http.get<any[]>(`${url}employee_shift_login_details/login/logout/report/filter/buddyUser/non-shift/${userId}/${empId}`);
            }
               //userId,Emp Name 0r number, Date based  shift Filter buddy user
               filterUserIdEmpNumShiftBased(userId:number,empId:number) {
                return this.http.get<any[]>(`${url}employee_shift_login_details/login/logout/report/filter/buddyUser/shift/${userId}/${empId}`);
              }
            // http://localhost:6002/trackingsupervisor/employee_shift_login_details/login/logout/report/filter/buddyUser/shift/69/10
            // http://localhost:6002/trackingsupervisor/employee_shift_login_details/login/logout/report/filter/buddyUser/non-shift/69/10
  assignClientLocations(clientId: number, body: any) {
    return this.http.post<any[]>(`${url}client/${clientId}/assign-locations`, body)
  }
  // localhost:6008/trackingsupervisor/client/1/assign-locations
  usersSearch(orgId: number, emp: any) {
    return this.http.get<any[]>(`${url}user/filter/keyword/empNum/name/${emp}/${orgId}`);

  }

  // user/filter/keyword/empNum/name/ma/10
  // Filter User Api
  get_userlistv2(userid: any) {
    return this.http.get(`${url}v2/reports/${userid}`)
  }
  // Filter User and sD and ED Api
  get_userandDatelistv2(startDate: any, endDate: any, userid: any) {
    return this.http.get(`${url}v2/reports/${startDate}/${endDate}/${userid}`)
  }
  // Filter User and sD and ED and STime and ETime Api
  get_userandTimelistv2(startDate: any, endDate: any, startTime: any, endTime: any, userid: any) {
    return this.http.get(`${url}reports/date/time/${startDate}/${endDate}/${startTime}/${endTime}/${userid}`)
  }

  // Filter User Api
  get_userlist(userid: any) {
    return this.http.get(`${url}reports/${userid}`)
  }
  // Filter User and sD and ED Api
  get_userandDatelist(startDate: any, endDate: any, userid: any) {
    return this.http.get(`${url}reports/${startDate}/${endDate}/${userid}`)
  }
  // Filter User and sD and ED and STime and ETime Api
  get_userandTimelist(startDate: any, endDate: any, startTime: any, endTime: any, userid: any) {
    return this.http.get(`${url}reports/date/time/${startDate}/${endDate}/${startTime}/${endTime}/${userid}`)
  }

  get_user(userid: any) {
    return this.http.get(`${url}/${userid}`)
  }


  Get_Country() {
    return this.http.get<any[]>(`${url}country`)
  }
  getEmployeeBusinessTravelRequest_EmailID(reportingto: any, businesssTravelStatus: any) {
    return this.http.get(`${url}business_travel/reportingto/${reportingto}/${businesssTravelStatus}`)
  }

  attendanceReports(fromDate: any, toDate: any) {

    return this.http.get(`${url}employee_shift_login_details/v2/attendance/report/${fromDate}/${toDate}`)
  }
  attendanceReportsUser(fromDate: any, toDate: any, userId: any) {
    return this.http.get(`${url}employee_shift_login_details/v2/attendance/report/user/${fromDate}/${toDate}/${userId}`)
  }
  attendanceReportsPrimary(fromDate: any, toDate: any, org: any) {
    return this.http.get(`${url}employee_shift_login_details/v2/attendance/report/org/${fromDate}/${toDate}/${org}`)
  }
  attendanceReportsSecondary(fromDate: any, toDate: any, userId: any) {
    return this.http.get(`${url}employee_shift_login_details/v2/attendance/report/user/${fromDate}/${toDate}/${userId}`)
  }
  assignGeofenceattendanceReportsUser(fromDate: any, toDate: any, userId: any) {
    return this.http.get(`${url}report/${fromDate}/${toDate}/${userId}`)
  }
  assignGeofenceattendanceReportsPrimary(fromDate: any, toDate: any, org: any) {
    return this.http.get(`${url}report/org/${fromDate}/${toDate}/${org}`)
  }
  // https://test-printlok.jesperapps.com:6002/trackingsupervisor/employee_shift_login_details/v2/attendance/report/2024-09-26/2024-09-28
  getCountry() {
    return this.http.get(`${url}country`);
  }
  getState(countryId: any) {
    return this.http.get(`${url}state/${countryId}`);
  }
  getCity(stateId: any) {
    return this.http.get(`${url}city/${stateId}`);
  }
  getIndustryType() {
    return this.http.get(`${url}industry-type`);
  }
  getUser_by_Id(id: any) {
    return this.http.get(`${url}user/${id}`);
  }
  get_organziation() {
    return this.http.get(`${url}organization`);
  }
  getUser_by_location(primaryUserId: any, userId: any) {
    return this.http.get(`${url}work-place/${primaryUserId}/${userId}`);
  }

  addOrganization(data: any) {
    return this
      .http
      .post(`${url}organization/superadmin/registration`, data);
  }
  updateOrganization(orgId:any,data: any) {
    return this
      .http
      .put(`${url}superadmin/organization/${orgId}`, data);
  } 
  // org List
  get_organziationUser(orgId:any) {
    return this.http.get(`${url}organization/${orgId}`);
  }

  adduser(data: any) {
    return this
      .http
      .post(`${url}superadmin/user`, data);
  }

  forgetpassword(data: any) {
    return this
      .http
      .post(`${url}forgotpassword`, data);
  }

  forgetpasswordcode(data: any) {
    return this
      .http
      .post(`${url}login/forgotpassword`, data);
  }

  uploaduser(formData: FormData) {
    return this
      .http
      .post(`${url}user/uploadfile`, formData);
  }
  multipleuser(final_json: any) {
    return this
      .http
      .post(`${url}multiple/user`, final_json);
  }

  getuserlocation(userId: any) {

    return this
      .http
      .get(`${url}livelocation/${userId}`);


  }


  RegisetrOrgUser(data: any) {
    return this
      .http
      .post(`${url}organization/user/registration`, data);
  }

  Create_Org_User(data: any) {
    return this
      .http
      .post(`${url}user`, data);
  }


  createClient(data: any) {

    return this.http.post(`${url}client/`, data)
  }

  // 192.168.0.197:6008/trackingsupervisor/client/
  Assign_Location(data: any) {
    return this
      .http
      .post(`${url}multiple/work-place`, data);
  }

  Assign_Instant_Location(f_d: any) {
    return this
      .http
      .post(`${url}multiple/instant/work-place`, f_d);
  }

  get_user_induvar() {
    return this.http.get(`${url}primaryuser`);
  }


  get_location(userId: any) {
    return this.http.get(`${url}locationDetails/user/${userId}`);
  }
  get_location_byClient(clientId: any) {
    return this.http.get(`${url}locationDetails/client/${clientId}`);

  }

  get_loginReport(organizationId: any, userId: any) {
    return this.http.get(`${url}employee_shift_login_details/login/logout/report/${organizationId}/${userId}`);
  }

  filterGeofenceByDates(startDate: any, endDate: any) {
    return this.http.get(`${url}userGeofence/date/${startDate}/${endDate}`);
  }
  //----------------------Dates Filter Based on Organization ------------------------------------
  filterGeofenceByDatesbasedOrg(startDate: any, endDate: any, orgId: number) {
    return this.http.get(`${url}userGeofence/date/org/${startDate}/${endDate}/${orgId}`);
  }

  // http://localhost:6002/trackingsupervisor/userGeofence/date/org/2024-11-01/2024-11-30/1

  filterGeofenceByGeofenceIdUseridDates(userId: number, geofenceId: number, startDate: any, endDate: any) {
    return this.http.get(`${url}userGeofence/user/geofence/date/${userId}/${geofenceId}/${startDate}/${endDate}`);
  }
  //------------------search emp ,geofenceBased on userid,geofenceid,dates, Org --------
  filterGeofenceByGeofenceIdUseridDatesBasedOrg(userId: number, geofenceId: number, startDate: any, endDate: any, orgId: number) {
    return this.http.get(`${url}userGeofence/user/geofence/date/org/${userId}/${geofenceId}/${startDate}/${endDate}/${orgId}`);
  }
  // http://localhost:6002/trackingsupervisor/userGeofence/user/geofence/date/org/47/13/2024-11-01/2024-11-30/1
  filterTrackReportsbyStartEndTimeanduserId(sTime: any, eTime: any, userId: number) {
    return this.http.get(`${url}reports/time/${sTime}/${eTime}/${userId}`);
  }

  filterTrackReportsByDatesTimeUserId(sDate: any, eDate: any, sTime: string, eTime: string, userId: number) {

    return this.http.get(`${url}reports/date/time/${sDate}/${eDate}/${sTime}/${eTime}/${userId}`)
  }

  // http://localhost:6002/trackingsupervisor/reports/date/time/2024-10-01/2024-10-23/10:35:24/10:35:54/38



  // http://localhost:6002/trackingsupervisor/reports/time/10:35:24/10:35:54/38








  // leaveType

  // getWFHRequest(applyTo, categoryId, status) {
  //   return this.http.get(`${url}wfh/${applyTo}/${categoryId}/${status}`)
  // }
  getWFHRequest(reportingTo: any, status: any) {
    return this.http.get(`${url}wfh/reportingto/${reportingTo}/${status}`)
  }
  getPermission_Manage(id: any) {
    return this.http.get(`${url}permissions/pending/reporting/${id}`)
  }
  // get travel mode
  getTravel_Mode() {
    return this.http.get(`${url}travelmode`);
  }
  // get business travel
  getMyBusinessTravel_List(empId: any, categoryId: any) {
    return this.http.get(`${url}business_travel/${empId}/${categoryId}`)
  }

  getListTypeById(listTypeId: any) {
    return this.http.get(`${url}list-type-values/${listTypeId}`)
  }
  getAdjustment() {
    return this.http.get(`${url}leave_adjustment/organization/1`);
  }
  getOrganization() {
    return this.http.get(`${url}organization`);
  }
  //--------------------------------post------------------------------------
  // business apply
  Apply_Business_Travel_Request(data: any) {
    return this.http.post(`${url}business_travel`, data)
  }
  // action
  //
  createLeaveType(data: any) {
    return this.http.post(`${url}leave_type`, data)
  }
  // withdraw
  withdraw(data: any) {
    return this.http.post(`${url}leave_withdraw`, data)
  }
  //leave Balance
  leaveBalance(data: any) {
    return this.http.post(`${url}leave_balance`, data)
  }
  // employee
  CreateEmployee(Form: FormData) {
    return this
      .http
      .post(`${url}employee`, Form);
  }
  addLeave(Form: any) {
    return this
      .http
      .post(`${url}leave_request`, Form);
  }
  applyPermission(Form: any) {
    return this
      .http
      .post(`${url}permissions`, Form);
  }
  // wrok from home
  addWfm(Data: any) {
    return this
      .http
      .post(`${url}wfh`, Data);
  }
  addAdjustment(Data: any) {
    return this
      .http
      .post(`${url}leave_adjustment`, Data);
  }

  //--------------------------------update------------------------------------
  //  leave status update
  UpdateLeaveStatus(id: any, Form: any) {
    return this
      .http
      .put(`${url}leave_request_status/${id}`, Form);
  }
  updateLeaveType(id: any, data: any) {
    return this.http.put(`${url}leave_type/${id}`, data)
  }
  UpdateEmployee(id: any, data: FormData) {
    return this.http.put(`${url}employee/${id}`, data)
  }

  UpdateEmployeeProfile(id: any, data: any) {
    return this.http.put(`${url}employee_image/${id}`, data)
  }
  // update Password
  updateAccountSettings(id: any, Form: any) {
    return this
      .http
      .put(`${url}change_password/${id}`, Form);
  }
  Actionwfh(id: any, data: any) {
    return this
      .http
      .put(`${url}wfh_status/${id}`, data);
  }
  AcctionBusiness(id: any, data: any) {
    return this
      .http
      .put(`${url}business_travel_status/${id}`, data);
  }
  //--------------------------------delete------------------------------------
  deleteLeaveType(id: any) {
    return this.http.delete(`${url}leave_type/${id}`)
  }
  deleteEmployee(employeeId: number) {
    return this
      .http
      .delete<any>(`${url}employee/${employeeId}`);
  }
  deleteLeave(leaveId: any, data: any) {
    return this
      .http
      .put(`${url}leave_request_status/${leaveId}`, data);
  }
  // +++++++++++++++++++++++++++++ withdraw ++++++++++++++++++++++++++++++++++++++++++++++//

  employee_withdraw_list_action(empId: any, mail: any) {
    return this.http.get(`${url}leave_withdraw/employee/${empId}/${mail}`)

  }
  withdrawAction(id: any, data: any) {
    return this.http.put(`${url}leave_withdraw_status/${id}`, data)
  }

  Permission_Status(id: any, data: any) {
    return this.http.put(`${url}permissions_status/${id}`, data)
  }

  getAll_withdrawList(mail: any) {
    return this.http.get(`${url}leave_withdraw/all/${mail}`)

  }

  post_subscribe(updatetoken: any) {
    return this
      .http
      .post(`${url}doNotTrack/subscribe`, updatetoken);
  }

  Create_Location(locationdata: any) {
    return this
      .http
      .post(`${url}locationDetails/user`, locationdata);
  }

  getLocation_by_ID(id: any) {
    return this.http.get(`${url}locationDetails/location/${id}`)

  }

  Update_Location(locationdata: any) {
    return this
      .http
      .put(`${url}locationDetails/${locationdata.locationId}`, locationdata);
  }

  get_freetrial() {
    return this.http.get(`${url}freetrial/`)
  }

  get_freetrial_default() {
    return this.http.get(`${url}freetrial/isdefault`);
  }

  get_free_trail() {
    return this.http.get(`${url}freetrial`);
  }
  get_free_trailid(freetrialId: any) {
    return this.http.get(`${url}freetrial/${freetrialId}`);
  }
  put_free_trailid(freetrialId: any, msg: any) {
    return this.http.put(`${url}freetrial/${freetrialId}`, msg);
  }

  addtrial(data: any) {
    return this
      .http
      .post(`${url}freetrial`, data);
  }

  put_free_update(freetrialId: any, msg: any) {
    return this.http.put(`${url}freetrial/update/${freetrialId}`, msg);
  }

  get_dont_track_byid(id: any) {
    return this.http.get<any>(`${url}track/${id}`)
  }

  get_dont_track(primaryUserId: any) {
    return this.http.get<any[]>(`${url}track/byprimaryuser/${primaryUserId}`)
  }
  put_dont_track(trackId: any, msg: any) {
    return this.http.put<any>(`${url}track/${trackId}`, msg)
  }

  Update_Org_User(z: any, userId: any) {
    return this.http.put<any>(`${url}user/${userId}`, z)
  }

  get_report_byuser(fromDate: any, toDate: any, id: any) {
    return this.http.get<any[]>(`${url}reports/${fromDate}/${toDate}/${id}`)
  }

  get_current_workplace(today: string | null, id: any) {
    return this.http.get<any[]>(`${url}work-place/current/${today}/${id}`)
  }
  // Manage Geofence
  get_Gefence_list(userId: number) {
    return this.http.get<any[]>(`${url}geofence/user/${userId}`)
  }
  // Manage Geofence List
  get_Geofencelist(orgId: number) {
    return this.http.get<any[]>(`${url}geofence/org/${orgId}`)
  }
  // Manage Geofence List
  get_ActiveGeofencelist(orgId: number) {
    return this.http.get<any[]>(`${url}v2/geofence/org/${orgId}`)
  }
  // add Geofence
  createGeofence(data: any) {
    return this.http.post(`${url}geofence`, data)
  }
  // tatus active and inactive api
  updateGeofenceStatus(id: any, status: any) {
    return this
      .http
      .put(`${url}geofence/status/${id}/${status}`, status);
  }
  userupdateStatus(id: any, status: any) {
    return this
      .http
      .put(`${url}user/deactivate/${id}/${status}`, status);
  }

  updateGeofence(geofenaceId: any, updateData: any) {
    return this.http.put<any>(`${url}geofence/${geofenaceId}`, updateData)
  }

  getGeofenceById(geofenaceId: number) {
    return this.http.get(`${url}geofence/${geofenaceId}`)
  }
  // Assign Geofence
  get_User_list() {
    return this.http.get<any[]>(`${url}user`)
  }
  get_Geofence_list(userId: number) {
    return this.http.get<any[]>(`${url}geofence/user/status/${userId}`)
  }
  get_employee_byClient_list(storeClientId: any) {
    return this.http.get<any[]>(`${url}client/${storeClientId}/employees`)
  }
  get_user_AllemployeeList(orgId: any) {
    return this.http.get<any[]>(`${url}user/organization/${orgId}`)

  }
  get_shift_byClient_list() {
    return this.http.get<any[]>(`${url}shift/`)
  }
  getEmployeeNameList(keywords: any) {
    return this.http.get(`${url}employee/filter/keyword/${keywords}`)
      .pipe(map(data => data));
  }
  getUserNameList(keywords: any) {
    return this.http.get(`${url}user/filter/keyword/${keywords}`)
      .pipe(map(data => data));
  }
  get_usergeofencelist(userid: any) {
    return this.http.get<any>(`${url}userGeofence/user/${userid}`)
  }
  get_geofencelist(geofenceId: any) {
    return this.http.get<any>(`${url}userGeofence/geofence/${geofenceId}`)
  }
  get_EmployeeOnly(empId: any) {
    return this.http.get(`${url}employee/${empId}`)

  }

  get_userId(id: any) {
    return this.http.get(`${url}user/${id}`)

  }
  get_userandgeofencelist(geofenceId: any, userid: any) {
    return this.http.get<any>(`${url}userGeofence/geofence/user/${geofenceId}/${userid}`)
  }
  // Assign Geofence 
  createassignGeofence(data: any) {
    return this.http.post(`${url}userGeofence/multiple`, data)
  }
  createClientAssign(clientId: any, data: any) {
    return this.http.post(`${url}client/${clientId}/assign-employees`, data)
  }
  // active and inactive status
  // updateAssignGeofenceStatus(id: any, status: any) {
  //   let result =  this.http.put<any>(`${url}employeeclientassign/deactive/${id}/${status}`).pipe(map(data=>data))
  //   return result;
  // }
  updateAssignGeofenceStatus(id: any, status: any) {
    return this
      .http
      .put(`${url}userGeofence/status/${id}/${status}`, status);
  }

  // Get Client List
  getClientList(organizationId: any) {
    return this.http.get<any[]>(`${url}client/organization/${organizationId}`)
  }
  // ClientId based get Client Details
  getClientId(clientId) {
    return this.http.get<any>(`${url}client/${clientId}`)
  }
  getclientByOrg(orgId: any) {
    return this.http.get<any[]>(`${url}client/organization/${orgId}`)
  }
  getemployeeseachbyEmpNoKeyword(employeecode: any, orgId: any) {
    return this.http.get<any[]>(`${url}user/filter/keyword/empNum/${employeecode}/${orgId}`)
  }
  getEmployeebyempcode(employeecode: any) {
    return this.http.get<any[]>(`${url}employee/filter/keyword/${employeecode}`)
  }
  getclientEmployeebyempcode(employeecode: any, clientId) {
    return this.http.get<any[]>(`${url}employee/employeenumber/client/${employeecode}/${clientId}`)
  }
  getbuddyuserByorg(usertype: any, orgId: any) {
    return this.http.get<any[]>(`${url}user/usertype/${usertype}/organization/${orgId}`)
  }
  AssignEmployeeOT(payload: any) {
    return this.http.post<any[]>(`${url}ot-shift-assignment/multiple`, payload)
  }
  // get_empOTAssignlistold(assigneduserId:any){
  //   return this.http.get<any[]>(`${url}ot-shift-assignment/assignedBy/${assigneduserId}`)
  // }
  // get_EmpOTAssignbydateold(date:any){
  //   return this.http.get<any[]>(`${url}ot-shift-assignment/date/${date}`)
  // }
  get_empOTAssignlist(orgId: any) {
    return this.http.get<any[]>(`${url}ot-shift-assignment/organization/${orgId}`)
  }
  get_EmpOTAssignbydate(date: any, orgId: any) {
    return this.http.get<any[]>(`${url}ot-shift-assignment/date/${date}/organization/${orgId}`)
  }
  get_empOTAssignlistbuddyuser(buddyuserId: any) {
    return this.http.get<any[]>(`${url}ot-shift-assignment/buddyUser/${buddyuserId}`)
  }
  get_EmpOTAssignbydatebuddyuser(date: any, buddyuserId: any) {
    return this.http.get<any[]>(`${url}ot-shift-assignment/buddyUser/date/${date}/${buddyuserId}`)
  }
  getshiftemployeeassignbyEmp(empId: any) {
    return this.http.get<any[]>(`${url}shift-employee-assign/employee/${empId}/current`)
  }
  getshiftemployeeassignbyEmpanddate(date: any, empId: any) {
    return this.http.get<any[]>(`${url}ot-shift-assignment/employee/date/${date}/${empId}`)
  }
  getshiftemployeeassignbydateandEmp(empId: any, date: any) {
    return this.http.get<any[]>(`${url}shift-employee-assign/employee/${empId}/${date}`)
  }
  getdesignation() {
    return this.http.get<any[]>(`${url}designation`)
  }

  get_Client_only(client: any) {
    return this.http.get<any[]>(`${url}client/${client}`)

  }
  get_ClientEmployee(client: any, employeeID: any) {
    return this.http.get<any[]>(`${url}client/employee/${client}/${employeeID}`)

  }
  //..........................shift......................................................................................................
  // shift_All_fieldsold(clientId:any,shiftId:any,location:any,startdate:any,enddate:any){
  //   return this.http.get<any[]>(`${url}shift-employee-assign/client/shift/location/start-date/end-date/${clientId}/${shiftId}/${location}/${startdate}/${enddate}`)
  //   //shift-employee-assign/client/shift/location/start-date/end-date/1/1/2/2024-11-01/2024-11-30
  // }
  // shift_onlyClient_fieldsold(clientId:any){
  //   return this.http.get<any[]>(`${url}shift-employee-assign/client/${clientId}`)
  // }
  // shift_onlyDate_fieldsold(startdate:any,enddate:any){
  //   return this.http.get<any[]>(`${url}shift-employee-assign/start-date/end-date/${startdate}/${enddate}`)
  //   //shift-employee-assign/start-date/end-date/2024-11-01/2024-11-30
  // }
  // shift_ClientDate_fieldsold(clientId:any,startdate:any,enddate:any){
  //   return this.http.get<any[]>(`${url}shift-employee-assign/client/start-date/end-date/${clientId}/${startdate}/${enddate}`)
  //   //shift-employee-assign/client/start-date/end-date/1/2024-10-01/2024-10-31
  // }
  // get_onlyShiftold(shiftId:any){
  //   return this.http.get<any[]>(`${url}shift-employee-assign/shift/${shiftId}`)
  //   // shift-employee-assign/shift/1
  // }
  // get_shift_with_datesold(shiftId:any,startdate:any,enddate:any){
  //   return this.http.get<any[]>(`${url}shift-employee-assign/shift/start-date/end-date/${shiftId}/${startdate}/${enddate}`)
  //   //shift-employee-assign/shift/start-date/end-date/1/2024-11-01/2024-11-30
  // }
  // get_shift_with_dates_clientold(clientId:any,shiftId:any,startDate:any,enddate:any){
  //   return this.http.get<any[]>(`${url}shift-employee-assign/client/shift/start-date/end-date/${clientId}/${shiftId}/${startDate}/${enddate}`)
  //   // shift-employee-assign/client/shift/start-date/end-date/1/1/2024-11-01/2024-11-30
  // }
  // get_client_locationold(clientId:any,location:any){
  //   return this.http.get<any[]>(`${url}shift-employee-assign/client/location/${clientId}/${location}`)
  // ///shift-employee-assign/client/location/1/2
  // }
  // get_shift_with__client_locationold(clientId:any,shiftId:any,location:any){
  //   return this.http.get<any[]>(`${url}shift-employee-assign/client/shift/location/${clientId}/${shiftId}/${location}`)
  // //shift-employee-assign/client/shift/location/1/1/2
  // }
  // get_client_dates_locationold(clientId:any,location:any,startDate:any,endDate:any){
  //   return this.http.get<any[]>(`${url}shift-employee-assign/client/location/start-date/end-date/${clientId}/${location}/${startDate}/${endDate}`)
  // //shift-employee-assign/client/location/start-date/end-date/1/2/2024-10-01/2024-10-31
  // }
  // get_Client_Shiftold(clientdId:any,shiftId:any){
  //   return this.http.get<any[]>(`${url}shift-employee-assign/client/shift/${clientdId}/${shiftId}`)
  //   //shift-employee-assign/client/shift/1/1
  // }
  //..........................shift with organization......................................................................................................
  shift_All_fields(clientId: any, shiftId: any, location: any, startdate: any, enddate: any, orgId: any) {
    return this.http.get<any[]>(`${url}shift-employee-assign/client/shift/location/start-date/end-date/${clientId}/${shiftId}/${location}/${startdate}/${enddate}/organization/${orgId}`)
    //shift-employee-assign/client/shift/location/start-date/end-date/1/1/2/2024-11-01/2024-11-30
  }
  shift_onlyClient_fields(clientId: any) {
    return this.http.get<any[]>(`${url}shift-employee-assign/client/${clientId}`)
  }
  shift_onlyDate_fields(startdate: any, enddate: any, orgId: any) {
    return this.http.get<any[]>(`${url}shift-employee-assign/start-date/end-date/${startdate}/${enddate}/organization/${orgId}`)
    //shift-employee-assign/start-date/end-date/2024-11-01/2024-11-30
  }
  shift_ClientDate_fields(clientId: any, startdate: any, enddate: any, orgId: any) {
    return this.http.get<any[]>(`${url}shift-employee-assign/client/start-date/end-date/${clientId}/${startdate}/${enddate}/organization/${orgId}`)
    //shift-employee-assign/client/start-date/end-date/1/2024-10-01/2024-10-31
  }
  get_onlyShift(shiftId: any, orgId: any) {
    return this.http.get<any[]>(`${url}shift-employee-assign/shift/${shiftId}/organization/${orgId}`)
    // shift-employee-assign/shift/1
  }
  get_shift_with_dates(shiftId: any, startdate: any, enddate: any, orgId: any) {
    return this.http.get<any[]>(`${url}shift-employee-assign/shift/start-date/end-date/${shiftId}/${startdate}/${enddate}/organization/${orgId}`)
    //shift-employee-assign/shift/start-date/end-date/1/2024-11-01/2024-11-30
  }
  get_shift_with_dates_client(clientId: any, shiftId: any, startDate: any, enddate: any, orgId: any) {
    return this.http.get<any[]>(`${url}shift-employee-assign/client/shift/start-date/end-date/${clientId}/${shiftId}/${startDate}/${enddate}/organization/${orgId}`)
    // shift-employee-assign/client/shift/start-date/end-date/1/1/2024-11-01/2024-11-30
  }
  get_client_location(clientId: any, location: any, orgId: any) {
    return this.http.get<any[]>(`${url}shift-employee-assign/client/location/${clientId}/${location}/organization/${orgId}`)
    ///shift-employee-assign/client/location/1/2
  }
  get_shift_with__client_location(clientId: any, shiftId: any, location: any, orgId: any) {
    return this.http.get<any[]>(`${url}shift-employee-assign/client/shift/location/${clientId}/${shiftId}/${location}/organization/${orgId}`)
    //shift-employee-assign/client/shift/location/1/1/2
  }
  get_client_dates_location(clientId: any, location: any, startDate: any, endDate: any, orgId: any) {
    return this.http.get<any[]>(`${url}shift-employee-assign/client/location/start-date/end-date/${clientId}/${location}/${startDate}/${endDate}/organization/${orgId}`)
    //shift-employee-assign/client/location/start-date/end-date/1/2/2024-10-01/2024-10-31
  }
  get_Client_Shift(clientdId: any, shiftId: any, orgId: any) {
    return this.http.get<any[]>(`${url}shift-employee-assign/client/shift/${clientdId}/${shiftId}/organization/${orgId}`)
    //shift-employee-assign/client/shift/1/1
  }

  //..........................shift with buddyuser......................................................................................................
  shift_All_fieldsbuddyuser(clientId: any, shiftId: any, location: any, startdate: any, enddate: any, buddyuserId: any) {
    return this.http.get<any[]>(`${url}shift-employee-assign/buddyUser/client/shift/location/start-date/end-date/${clientId}/${shiftId}/${location}/${startdate}/${enddate}/${buddyuserId}`)
  }
  shift_onlyClient_fieldsbuddyuser(clientId: any, buddyuserId: any) {
    return this.http.get<any[]>(`${url}shift-employee-assign/buddyUser/client/${clientId}/${buddyuserId}`)
  }
  shift_onlyDate_fieldsbuddyuser(startdate: any, enddate: any, buddyuserId: any) {
    return this.http.get<any[]>(`${url}shift-employee-assign/buddyUser/start-date/end-date/${startdate}/${enddate}/${buddyuserId}`)
  }
  shift_ClientDate_fieldsbuddyuser(clientId: any, startdate: any, enddate: any, buddyuserId: any) {
    return this.http.get<any[]>(`${url}shift-employee-assign/buddyUser/client/start-date/end-date/${clientId}/${startdate}/${enddate}/${buddyuserId}`)
  }
  get_onlyShiftbuddyuser(shiftId: any, buddyuserId: any) {
    return this.http.get<any[]>(`${url}shift-employee-assign/buddyUser/shift/${shiftId}/${buddyuserId}`)
  }
  get_shift_with_datesbuddyuser(shiftId: any, startdate: any, enddate: any, buddyuserId: any) {
    return this.http.get<any[]>(`${url}shift-employee-assign/buddyUser/shift/start-date/end-date/${shiftId}/${startdate}/${enddate}/${buddyuserId}`)
  }
  get_shift_with_dates_clientbuddyuser(clientId: any, shiftId: any, startDate: any, enddate: any, buddyuserId: any) {
    return this.http.get<any[]>(`${url}shift-employee-assign/buddyUser/client/shift/start-date/end-date/${clientId}/${shiftId}/${startDate}/${enddate}/${buddyuserId}`)
  }
  get_client_locationbuddyuser(clientId: any, location: any, buddyuserId: any) {
    return this.http.get<any[]>(`${url}shift-employee-assign/buddyUser/client/location/${clientId}/${location}/${buddyuserId}`)
  }
  get_shift_with__client_locationbuddyuser(clientId: any, shiftId: any, location: any, buddyuserId: any) {
    return this.http.get<any[]>(`${url}shift-employee-assign/buddyUser/client/shift/location/${clientId}/${shiftId}/${location}/${buddyuserId}`)
  }
  get_client_dates_locationbuddyuser(clientId: any, location: any, startDate: any, endDate: any, buddyuserId: any) {
    return this.http.get<any[]>(`${url}shift-employee-assign/buddyUser/client/location/start-date/end-date/${clientId}/${location}/${startDate}/${endDate}/${buddyuserId}`)
  }
  get_Client_Shiftbuddyuser(clientdId: any, shiftId: any, buddyuserId: any) {
    return this.http.get<any[]>(`${url}shift-employee-assign/buddyUser/client/shift/${clientdId}/${shiftId}/${buddyuserId}`)
  }
  updateDeviceId(userId: any, OrganizationId: any, payload) {
    return this.http.put<any[]>(`${url}user/deviceId/${userId}/${OrganizationId}`, payload)
  }

  getProfileDetails(userId:number){
    return this.http.get<any[]>(`https://test-printlok.jesperapps.com:6002/trackingsupervisor/user/${userId}`);
  }
}


