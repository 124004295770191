import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators, FormGroupDirective, NgForm, FormArray,AbstractControl, ValidationErrors } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { MatSelectChange } from '@angular/material/select';



export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-organziation-register',
  templateUrl: './organziation-register.component.html',
  styleUrls: ['./organziation-register.component.scss']
})
export class OrganziationRegisterComponent implements OnInit {

  matcher = new MyErrorStateMatcher();
  OrganziationForm!: FormGroup
  hide = true;
  loading = false;
  freetriallist : any = [];
  selectedValue: any;
  attType: any;
  trackType: any;
  startTime: any;
  endTime: any;
  intervalTime: any;
  photoCap: any;
  wrongTime: boolean=false;
  isInvalidInterval: boolean=false;
  constructor(private fb: FormBuilder , private gs : GlobalserviceService , private router: Router) { }

  ngOnInit(): void {

    this.OrganziationForm = this.fb.group({
      organizationName: ['', Validators.required],
      // contactEmail: ['', [Validators.required ,Validators.pattern(/^[a-z]+[a-z0-9._]+@[a-z]+\.[a-z.]{2,5}$/)]],
      contactEmail: ['', [Validators.required, Validators.pattern(/^[a-zA-Z][a-zA-Z0-9._]+@[a-z]+\.[a-z.]{2,5}$/)]],
      contactPhoneNumber: ['', Validators.required],     
      name: ['', Validators.required],
      // email: ['',[ Validators.required , Validators.pattern(/^[a-z]+[a-z0-9._]+@[a-z]+\.[a-z.]{2,5}$/)]], 
      email: ['', [Validators.required, Validators.pattern(/^[a-zA-Z][a-zA-Z0-9._]+@[a-z]+\.[a-z.]{2,5}$/)]],
      phoneNumber: ['', Validators.required],   
          // password : ['', Validators.required] , 
      password: [
            '', 
            [
              Validators.required,
              Validators.minLength(8),  
              Validators.maxLength(12),
              Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&#])[A-Za-z\\d@$!%*?&#]{8,12}$')
            ]
          ],
          // freetrial :  this.fb.group({
          freetrialId : ['', Validators.required] , 
          // }),
          attendence:['',Validators.required],
          attendenceType:['',Validators.required],
          trackType:['',Validators.required],
          startTime:['',Validators.required],
          endTime:['',Validators.required],
          intervalTime: ['', [Validators.required, this.minTimeValidator('00:15')]],
          // intervalTime:['',Validators.required],
          photoCapture:['',Validators.required]
    });

    this.gs.get_freetrial().subscribe((a: any) => {
      this.freetriallist = a;
})
    
  }
  get f() {
    return this.OrganziationForm.controls;
  }

  minTimeValidator(minTime: string) {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null; // Skip validation if value is empty
      }
      const [minHours, minMinutes] = minTime.split(':').map(Number);
      const [inputHours, inputMinutes] = control.value.split(':').map(Number);

      const minTotalMinutes = minHours * 60 + minMinutes;
      const inputTotalMinutes = inputHours * 60 + inputMinutes;

      return inputTotalMinutes < minTotalMinutes
        ? { minTime: { requiredTime: minTime } }
        : null;
    };
  }

  attendence(event: MatSelectChange): void {
     this.selectedValue = event.value;
    console.log('Selected attendence:', this.selectedValue);
  }

  attendenceType(event: MatSelectChange): void {
    this.attType = event.value;
   console.log('Selected Attendence Type:', this.attType);
  }

  tracking(event: MatSelectChange) :void
  {
    this.trackType = event.value;
    console.log('Selected track Type:', this.trackType);
  }
  onStartTimeChange(event)
  {
    const inputElement = event.target as HTMLInputElement;
    // const selectedTime = inputElement.value;
    // console.log(event);
     this.startTime=inputElement.value;
     console.log(this.startTime);
     if(this.startTime&&this.endTime&&this.endTime<=this.startTime)
      {
        this.f.endTime.setErrors({ invalidTime: true });
        console.log("Invaild Time");
        // this.wrongTime=true;
      }
      else
      {
        this.f.endTime.setErrors(null);
        console.log("Vaild Time");
        // this.wrongTime=false;
      }
  }
  onEndTimeChange(event)
  {
    const endElement = event.target as HTMLInputElement;
    this.endTime=endElement.value;
    console.log(this.endTime);
    if(this.startTime&&this.endTime&&this.endTime<=this.startTime)
    {
      this.f.endTime.setErrors({ invalidTime: true });
      // this.wrongTime=true;
      console.log("Invaild Time");
    }
    else
    {
      // this.wrongTime=false;
      this.f.endTime.setErrors(null);
      console.log("Vaild Time");
    }
  }
  onIntervalTimeChange(event)
  {
    const intervalElement = event.target as HTMLInputElement;
    this.intervalTime=intervalElement.value;
    console.log(this.intervalTime);
    // this.validateIntervalTime();
  }
  // validateIntervalTime(): void {
  //   // Parse the selected time into hours and minutes
  //   const [hours, minutes] = this.intervalTime.split(':').map(Number);
  //   const totalMinutes = hours * 60 + minutes;

  //   // Check if the selected time is at least 15 minutes
  //   this.isInvalidInterval = totalMinutes < 15;
  //   console.log(this.isInvalidInterval);
  // }
  photoCapture(event: MatSelectChange): void {
    this.photoCap = event.value;
   console.log('Selected photoCapture:', this.photoCap);
  }

  markFormGroupTouched(formGroup: FormGroup): void {
    Object.keys(formGroup.controls).forEach((key) => {
      const control = formGroup.get(key);
      if (control instanceof FormControl) {
        control.markAsTouched();
      } else if (control instanceof FormGroup) {
        this.markFormGroupTouched(control); // Recursive call for nested FormGroup
      }
      // } else if (control instanceof FormArray) {
      //   control.controls.forEach((arrayControl) => {
      //     if (arrayControl instanceof FormControl || arrayControl instanceof FormGroup) {
      //       this.markFormGroupTouched(arrayControl);
      //     }
      //   });
      // }
    });
  }
  
  createorganization() {
    console.log(this.OrganziationForm.value)
    let x = this.OrganziationForm.value;
    this.markFormGroupTouched(this.OrganziationForm);
    // this.OrganziationForm.get('password')?.markAsTouched();
    // this.OrganziationForm.get('phoneNumber')?.markAsTouched();
       let userdata = {	
        name:x.name,
        email:x.email,
        phoneNumber:x.phoneNumber,
        password: x.password
      }	;
      // if(x.trackType==1)
      // {

      // }
      // else if(x.trackType==0)
      // {

      // }
    // let final_data = {
    //   organizationName: x.organizationName,	
    //   contactEmail: x.contactEmail,	
    //   contactPhoneNumber:x.contactPhoneNumber,	
    //   fromTime:x.startTime,
    //   toTime:x.endTime,
    //   timeInterval:x.intervalTime,
    //   attendance:x.attendence,
    //   track:x.trackType,
    //   attendanceType:x.attendenceType,
	  //   attendanceImage:x.photoCapture,
    //   user:userdata,
    //   freeTrial : {
    //     freeTrialId : x.freetrial.freetrialId
    //   }
    // }
    let final_data: any = {
      organizationName: x.organizationName,
      contactEmail: x.contactEmail,
      contactPhoneNumber: x.contactPhoneNumber,
      // attendanceImage:x.photoCapture,
      user: userdata,
      freeTrial: {
        freeTrialId: x.freetrialId,
      },
    };
    
    if (x.attendence===true) {
      console.log("True");
      this.OrganziationForm.get('attendenceType')?.setValidators([Validators.required]);
      this.OrganziationForm.get('attendenceType')?.updateValueAndValidity();
      this.OrganziationForm.get('photoCapture')?.setValidators([Validators.required]);
      this.OrganziationForm.get('photoCapture')?.updateValueAndValidity();
      final_data.attendance = x.attendence;
      final_data.attendanceType = x.attendenceType;
      final_data.attendanceImage= x.photoCapture
    } else if(x.attendence===false) {
      console.log("false");
      this.OrganziationForm.get('attendenceType')?.clearValidators();
      this.OrganziationForm.get('attendenceType')?.updateValueAndValidity();
      this.OrganziationForm.get('photoCapture')?.clearValidators();
      this.OrganziationForm.get('photoCapture')?.updateValueAndValidity();
      final_data.attendance = x.attendence;
    }
    
    if (x.trackType===true) {
      console.log("TTrue");
      this.OrganziationForm.get('startTime')?.setValidators([Validators.required]);
      this.OrganziationForm.get('endTime')?.setValidators([Validators.required]);
      this.OrganziationForm.get('intervalTime')?.setValidators([Validators.required]);

      // Update validators after setting
      this.OrganziationForm.get('startTime')?.updateValueAndValidity();
      this.OrganziationForm.get('endTime')?.updateValueAndValidity();
      this.OrganziationForm.get('intervalTime')?.updateValueAndValidity();
      final_data.track = x.trackType;
      final_data.fromTime = x.startTime;
      final_data.toTime = x.endTime;
      final_data.timeInterval = x.intervalTime;
    } else if(x.trackType===false){
      console.log("Tfalse");
    this.OrganziationForm.get('startTime')?.clearValidators();
    this.OrganziationForm.get('endTime')?.clearValidators();
    this.OrganziationForm.get('intervalTime')?.clearValidators();

    // Update validators after clearing
    this.OrganziationForm.get('startTime')?.updateValueAndValidity();
    this.OrganziationForm.get('endTime')?.updateValueAndValidity();
    this.OrganziationForm.get('intervalTime')?.updateValueAndValidity();
      final_data.track = x.trackType;
    }
    console.log(final_data);
    console.log(JSON.stringify(final_data))
    if (this.OrganziationForm.invalid ) {
      console.log("Invaild");
      return
    }
    this.loading = true
    this.gs.addOrganization(final_data).subscribe(
      (d: any) => {
        console.log(d)
        this.loading = false;
        Swal.fire({
          text: d.message,
          icon: 'success',
          timer: 5000,
        })
        this.router.navigate(['/org-list']);
      },
      err => {
        this.loading = false
        console.log(err)
        let x = err.error.message
        if (x == undefined) {
          Swal.fire(
            "Check Net:: Connection :: err"
          )
        } else {
          console.log(err.error.message)
          if (err) {
            Swal.fire({
              text: err.error.message,
              icon: 'error'
            })
          }
        }
      },
      () => {
        console.log('done')
      }
    )
  }

  omit_special_char(event: { keyCode: any; charCode: any; }) {
    let k;
    let n;
    n = event.keyCode;
    k = event.charCode;
    return ((n > 'A' && n < 'z') || (k > 64 && k < 91) || (k > 96 && k < 123) || k === 8 || k === 32 || (k >= 48 && k <= 57) || (k === 1));
  }

  keyPress(event: any) {
    const pattern = /^[a-zA-Z ]*$/;
    const inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  noNumber(event: any) {
    const pattern = /[0-9]|\./;
    const inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }


  onPasswordInput(): void {
    const passwordControl = this.OrganziationForm.get('password');
    if (passwordControl) {
      passwordControl.markAsTouched(); // Mark the control as touched to show validation messages immediately
    }
  }
  
}
