import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
import Swal from 'sweetalert2';
import { AuthService } from '../Authenticaton/auth.service';
@Component({
  selector: 'app-profile-view',
  templateUrl: './profile-view.component.html',
  styleUrls: ['./profile-view.component.scss']
})
export class ProfileViewComponent implements OnInit {

 profileImage: any;
 userId:number;
 data:any;
//  @Inject(MAT_DIALOG_DATA) public data:any,
   constructor(
     public matdilaog:MatDialog,
   
     private matdialogRef:MatDialogRef<ProfileViewComponent>,
     public gs:GlobalserviceService,
     private auth: AuthService
 
   ) { }
 
   ngOnInit(): void {
    console.log(this.data);
    let  userdata = this.auth.getLocalStorageData();
    this.userId = userdata.userId;
    console.log('userdata',userdata);
    
    // this.profileImage = this.data.attachment.fileViewUrl
    console.log("this.profileImage",this.profileImage);
    // console.log("deviceID",this.data.deviceId);

    this.gs.getProfileDetails( this.userId).subscribe((response)=>{
      console.log('response', response);
      this.data = response;
      this.profileImage = this.data.attachment.fileViewUrl
    })
    
   }
   close(){
     this.matdialogRef.close();
   }
   deviceIdReset(){
     let payload={
       userId: this.data.userId
     }
    //  this.gs.updateDeviceId(this.data.userId,this.data.organization.organizationId,payload).subscribe(
    //  (data:any)=>{
    //    console.log(data);
    //    this.matdialogRef.close("updated");
    //    Swal.fire(
    //      data.description,
    //      "",
    //      'success'
    //    )
    //  },
    //  err => {
    //    Swal.fire(
    //      err.error.message,
    //      "",
    //      'error'
    //    )
    //  })  
   }

}
