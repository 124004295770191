<ng-container *ngIf="loader">
  <div class="loading-wrapper">
    <div class="loading">
      <app-loading></app-loading>
      <br />
      <h1>Please Wait Loading...</h1>
    </div>
  </div>
</ng-container>
<div class="container">
  <div class="mat-elevation-z8">
    <form [formGroup]="AssigngeofenceForm">
      <div class="col-md-12 col-sm-12  col-xs-12  pr-5 pt-3 pb-3" style="white-space: nowrap">
        <h2 class="pl-5 pt-3 float-left">Assign Client Employees</h2>
        <div class="float-right pt-3">
          <button
            class="normal-button"
            mat-flat-button
            [routerLink]="['/create-client-assign']"
          >
 
            <span>Assign Client Employees</span>
          </button>
        </div>
      </div>
      <div></div>
      <div class="row pl-5 pr-md-0 pr-3" >
        <!-- col-lg-3 col-md-3 col-sm-3 col-xs-3 -->
        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
          <mat-form-field
            class="example-full-width"
            appearance="outline"
            style="display: inline"
          >
            <mat-label>Choose Client </mat-label>
            <mat-select
              placeholder="Select Client"
              formControlName="client"
              (selectionChange)="isselecteduser($event)"
            >
              <mat-option value="0"> -- Select Client -- </mat-option>
              <ng-container *ngIf="setBoolean">
                <mat-option
                  *ngFor="let users of clientList"
                  [value]="users.clientId"
                >
                  {{ users.clientCode }} - {{ users.clientName }}
                </mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>

        </div>
        <!-- col-lg-1 col-md-1 col-sm-2 mt-2 -->
        <div class=" search col-lg-1 col-md-1 col-sm-2 mt-2">

          <button
            type="button"
            (click)="search()"
            mat-flat-button
            class="normal-button"
          >
            Search
          </button>
        </div>
        <!-- clear-m -->
        <!-- col-md-1 -->
        <!-- col-sm-2  -->
        <!-- col-lg-1 -->
        <div class=" col-lg-1 col-md-1 col-sm-2 clear  mt-2">
          <button
            type="reset"
            mat-flat-button
            (click)="clear()"
            class="clear-button"
          >
            Clear
          </button>
        </div>
      </div>
      <div class="col-md-8 float-right">
        <div
          class="float-right"
          *ngIf="filterData.length > 0"
          style="width: 100%; display: flex; justify-content: flex-end"
        >
          <mat-form-field appearance="outline">
            <mat-icon matPrefix>search</mat-icon>
            <mat-label>Search</mat-label>
            <!-- <input  matInput (keyup)="applyFilter($event)"  placeholder="search geofenacename" #input> -->
            <input
              matInput
              (input)="applyFilter($event)"
              placeholder="Search Client Code/Client Name"
            />
          </mat-form-field>
        </div>
      </div>
      <div class="table-responsive">
        <table
          mat-table
          [dataSource]="dataSource"
          *ngIf="filterData?.length != 0 && tableShow"
        >
          <div class="col-md-12">
            <!-- <ng-container matColumnDef="sno">
                <th mat-header-cell *matHeaderCellDef class="col-md-1 col-sm-1"> <b>Sl.No</b> </th>
                <td mat-cell  *matCellDef="let element; let i =index"> {{i+1}}  </td> 
            </ng-container> -->
            <ng-container matColumnDef="sno">
              <th mat-header-cell *matHeaderCellDef class="col-md-1 col-sm-1">
                <b>SI.NO</b>
              </th>
              <td mat-cell *matCellDef="let element; let i = index">
                {{ currentPageOffset + i + 1 }}
              </td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="clientCode">
              <th mat-header-cell *matHeaderCellDef class="col-md-2 col-sm-2">
                <b>CLIENT CODE</b>
              </th>
              <td
                mat-cell
                *matCellDef="let element"
                style="white-space: nowrap"
              >
                {{ element.clientCode }}
              </td>
            </ng-container>
            <ng-container matColumnDef="clientName">
              <th mat-header-cell *matHeaderCellDef class="col-md-2 col-sm-2">
                <b>CLIENT NAME</b>
              </th>
              <td
                mat-cell
                *matCellDef="let element"
                style="white-space: nowrap"
              >
                {{ element.clientName }}
              </td>
            </ng-container>
           
            <ng-container matColumnDef="EmployeeName">
              <th
                mat-header-cell
                *matHeaderCellDef
                class="col-md-3 col-sm-3"
                style="text-align: left"
              >
                <b>EMPLOYEE NAME</b>
              </th>
              <td
                mat-cell
                *matCellDef="let element"
                style="--padding-left: 14%"
              >
                <button
                  *ngIf="element?.employees?.length != 0; else nodata"
                  type="button"
                  class="btn view-employees-btn"
                  style="font-size: 12px; display: block; margin: 0 auto"
                  (click)="viewEmployees(element.employees)"
                >
                  View Employees
                </button>
                <ng-template #nodata
                  ><div style="padding-right: 80%">--</div></ng-template
                >
              </td>
            </ng-container>

            <!-- <ng-container matColumnDef="view">
                <th mat-header-cell *matHeaderCellDef class="col-md-1 col-sm-1"> <b>ACTION</b> </th>
                  <td mat-cell *matCellDef="let element">
                    <button mat-icon-button  [matMenuTriggerFor]="beforeMenu">
                      <mat-icon>more_vert</mat-icon>
                    </button>
                    <div class="side_setting">
                        
                      <mat-menu #beforeMenu="matMenu" xPosition="after">
                        <a  mat-menu-item    [routerLink]="['/create-client-assign']"> 
                          
                          Edit 
                          Client Assign</a>
                        </mat-menu>
                    </div>
                  </td>
            </ng-container> -->
          </div>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

          <!-- <tr class="mat-row" *matNoDataRow>
                <td class="pl-3 pr-3" colspan="4" align="center">
                    <div class="alert alert-secondary" style="height: 60px;width: 115%;">
                      <h4 class="pt-2">No Client Assign List Found</h4>
                    </div>
                </td>
            </tr> -->
          <tr class="p-3" *ngIf="nodata">
            <td class="no-data card text-center">
              <div class="card-body">No Client Assign List Found</div>
            </td>
          </tr>
          <!-- <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="6" id="nodata" style="text-align: center;">No data found
                <td>
            </tr> -->
        </table>
      </div>
    </form>
    <div class="p-3" *ngIf="nodata">
      <div class="no-data card text-center">
        <div class="card-body">No Data</div>
      </div>
    </div>
    <mat-paginator
      *ngIf="dataSource.data.length > 5"
      (page)="onPageChange($event)"
      [pageSizeOptions]="[5, 10, 20, 30, 50, 100]"
    ></mat-paginator>
    <!-- <mat-paginator  [pageSizeOptions]="[5,10,20,30,50,100]"   showFirstLastButtons ></mat-paginator>  -->
  </div>
</div>
