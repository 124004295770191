<ng-container *ngIf="isLoading; else elseTemplate">
    <div class="loading">
      <app-loading></app-loading>
      <br />
      <h1>Please Wait Loading...</h1>
    </div>
</ng-container>
<ng-template #elseTemplate>
    <div class="container-fluid">
      <form [formGroup]="geofenceattendenceform">
        <div class="mat-elevation-z8" style="padding: 15px;">
          <h2 class="pl-5 pt-3">Attendance Report</h2>
          <div class="row ml-3" style="padding: 5px 5px 5px 5px">
            <div class="col-lg-2 col-md-2 col-sm-12">
              <mat-form-field
                class="example-full-width"
                appearance="outline"
                style="display: inline"
              >
                <mat-label>Start Date</mat-label>
                <input
                  matInput
                  placeholder="Choose a Date"
                  [matDatepicker]="picker1"
                  formControlName="fromDate"
                  [max]="maxDate"
                  (dateChange)="sdate($event)"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="picker1"
                ></mat-datepicker-toggle>
                <mat-datepicker #picker1></mat-datepicker>
               
              </mat-form-field>
              <span class="error_msg " *ngIf="startDate">*Please select start date</span>  
              
            </div>
            <div class="col-lg-2 col-md-2 col-sm-12">
              <mat-form-field
                class="example-full-width"
                appearance="outline"
                style="display: inline"
              >
                <mat-label>End Date</mat-label>
                <input
                  matInput
                  placeholder="Choose a Date"
                  [matDatepicker]="picker2"
                  formControlName="todate"
                  [min]="minDate"
                  [max]="emaxDate" 
                  (dateChange)="edate($event)"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="picker2"
                ></mat-datepicker-toggle>
                <mat-datepicker #picker2 class="mb-3"></mat-datepicker>
               
              </mat-form-field>
              <span class="error_msg" *ngIf="endDate">*Please select end date</span>
              <span class="error_msg" *ngIf="greater">*Select Vaild End date</span> 
            </div>
            <div class="col-lg-1 col-md-1 col-sm-12 pl-4 mt-2 mr-3">
              <!-- class="btn btn-primary btm-sm"
              style="position: relative; top: 5px; font-size: 14px" -->
              <button
                type="button"
                (click)="search()"
                class="normal-button"
                mat-flat-button
              >
                Search
              </button>
            </div>
            <div class="col-lg-1 col-md-1 col-sm-12 pl-1 mt-2 ml-2">
              <!-- class="btn btn-primary btm-sm"
              style="position: relative; top: 5px; font-size: 14px;" -->
              <button
                type="button"
                (click)="clear()"
                class="clear-button"
                mat-flat-button
              >
                Clear
              </button>
            </div>
            <div class="col-lg-1 col-md-1 col-sm-12 pl-1 ml-auto text-right" *ngIf="attendanceList.length>0">
              <button
                type="button ml-auto"
                class="btn btn-primary btm-sm"
                style="position: relative; top: 10px; font-size: 14px;background: #c35e4c;color: #fff;border-color: #c35e4c;"
                (click)="ExportGeofenceAttendance()"
              >
                Export
              </button>
            </div>
          </div>
  
          <div class="table-responsive m-2" *ngIf="attendanceList.length>0">
            <table class="table table-bordered border-light">
              <thead>
                <tr *ngIf="employeeData.length > 0">
                  <th class="align-middle text-center">S.No</th>
                  <th class="align-middle text-center">Employee ID</th>
                  <th class="align-middle text-center">Employee Name</th>
                  <th class="align-middle text-center">Position</th>
                  <ng-container class="align-middle text-center"
                    *ngFor="let d of normalDates"
                  >
                    <th>
                        {{ d.date | date: 'd' }}
                    </th>
                  </ng-container>
  
                  <th class="align-middle text-center">Present</th>
                  <!-- <th class="align-middle text-center">OT</th> -->
                  <th class="align-middle text-center">Absent</th>
                  <th class="align-middle text-center">Total Hours Worked</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let employee of attendanceList; let i = index">
                  <td class="align-middle text-center">{{ i + 1 }}</td>
                  <td class="align-middle text-center">
                    {{ employee.empid }}
                  </td>
                  <td class="align-middle text-center">
                    {{ employee.empname }}
                  </td>
                  <td class="align-middle text-center">
                    {{ employee.position }}
                  </td>
                  <ng-container *ngFor="let d of employee.empData">
                    <td class="align-middle text-center" *ngIf="d.status">{{ d.status?d.status:0 }}</td>
                  </ng-container>
  
                  <td class="align-middle text-center">
                    {{
                      employee?.totalcounts?.present
                        ? employee?.totalcounts?.present
                        : 0
                    }}
                  </td>
                  <!-- <td class="align-middle text-center">
                    {{
                      employee?.totalcounts?.otcount
                        ? employee?.totalcounts?.otcount
                        : 0
                    }}
                  </td> -->
                  <td class="align-middle text-center">
                    {{
                      employee?.totalcounts?.absent
                        ? employee?.totalcounts?.absent
                        : 0
                    }}
                  </td>
                  <td class="align-middle text-center">
                    {{
                      employee?.total_worked_hours
                        ? employee?.total_worked_hours
                        : 0
                    }}
                  </td>
                </tr>
                <tr class="align-middle text-center" *ngIf="employeeData.length > 0">
                  <td colspan="3" class="align-middle text-center"></td>
                  <td class="align-middle text-center">Present</td>
  
                  <td
                    *ngFor="let d of this.totalpresentList; let i = index"
                    class="align-middle text-center"
                  >
                    {{ d.countData ? d.countData : 0 }}
                  </td>
                  <td colspan="4" class="align-middle text-center"></td>
                </tr>
                <tr class="align-middle text-center" *ngIf="employeeData.length > 0">
                  <td colspan="3" class="align-middle text-center"></td>
                  <td class="align-middle text-center">Absent</td>
  
                  <td
                    *ngFor="let d of this.empabsentList; let i = index"
                    class="align-middle text-center"
                  >
                    {{ d.countData ? d.countData : 0 }}
                  </td>
                  <td colspan="4" class="align-middle text-center"></td>
                </tr>
  
                <tr class="align-middle text-center" *ngIf="employeeData.length > 0">
                  <td colspan="3" class="align-middle text-center"></td>
                  <td class="align-middle text-center">Leave</td>
  
                  <td
                    *ngFor="let d of this.empleaveList; let i = index"
                    class="align-middle text-center"
                  >
                    {{ d.countData ? d.countData : 0 }}
                  </td>
                  <td colspan="4" class="align-middle text-center"></td>
                </tr>
  
              <!-- <tr class="align-middle text-center" *ngIf="employeeData.length > 0">
                  <td colspan="3" class="align-middle text-center"></td>
                  <td class="align-middle text-center">OT</td>
  
                  <td
                    *ngFor="let d of this.empotList; let i = index"
                    class="align-middle text-center"
                  >  
                    {{ d.otcountData ? d.otcountData : 0 }}
                  </td>
                  <td colspan="4" class="align-middle text-center"></td>
                </tr>  -->
              </tbody>
            </table>
          </div> 
  
          <div class="p-3" *ngIf="nodata">
            <div class="no-data card text-center" style="text-align: center !important;
            background-color: #d44c39fa;
            border-radius: 5px;">
              <div class="card-body">No Data</div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </ng-template>
