<ng-container *ngIf="isLoading; else elseTemplate">
  <div class="loading-wrapper">
    <div class="loading">
      <app-loading></app-loading>
      <br />
      <h1>Please Wait Loading...</h1>
    </div>
  </div>
</ng-container>

<ng-template #elseTemplate>
  <div class="container">
    <div class="mat-elevation-z8">
      <form [formGroup]="detailsFilterForm">
        <div class="mat-elevation-z8" style="padding: 15px">
          <!-- <h2 class="pl-5 pt-3">Attendence Reports</h2> -->
          <div class="row" style="padding: 5px 5px 5px 5px">
            <!-- Adjusting the h2 tag to align properly -->
            <h2 class="pl-5 pt-3">Login and Logout Reports</h2>
          </div>
          <div *ngIf="userTypeId == 3; else secondary">
            <div class="row ml-3" style="padding: 5px 5px 5px 5px">
              <div class="col-lg-2 col-md-2 col-sm-12 mb-3">
                <mat-form-field
                  class="example-full-width"
                  appearance="outline"
                  style="display: inline"
                >
                  <mat-label>From Date</mat-label>
                  <input
                    matInput
                    placeholder="Choose a Date"
                    [matDatepicker]="picker1"
                    formControlName="fromDate"
                    (dateChange)="sdate($event)"
                    (dateChange)="startDateChange($event)"
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="picker1"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #picker1></mat-datepicker>
                </mat-form-field>
                <span
                  style="color: red; font: 12px !important"
                  *ngIf="fromDateValidation"
                >
                  Select a valid From Date.</span
                >
              </div>

              <div class="col-lg-2 col-md-2 col-sm-12 mb-3">
                <mat-form-field
                  class="example-full-width"
                  appearance="outline"
                  style="display: inline"
                >
                  <mat-label>To Date</mat-label>
                  <input
                    matInput
                    placeholder="Choose a Date"
                    [matDatepicker]="picker2"
                    formControlName="toDate"
                    (dateChange)="endDateChange($event)"
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="picker2"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #picker2></mat-datepicker>
                </mat-form-field>
                <span style="color: red" *ngIf="toDateValidation"
                  >Select a valid ToDate.</span
                >
              </div>

              <div
                class="col-lg-2 col-md-2 col-sm-12 today me-4 mt-2"
                style="height: 40px; width: 150px"
              >
                <button
                  type="button"
                  mat-flat-button
                  class="normal-button d-flex align-items-center justify-content-center p-2"
                  style="height: 40px; width: 150px"
                  (click)="todaysDate()"
                >
                  Today
                </button>
              </div>

              <div
                class="col-lg-2 col-md-1 col-sm-12 today me-4 mt-2"
                style="height: 40px; width: 150px"
              >
                <button
                  type="button"
                  mat-flat-button
                  class="normal-button d-flex align-items-center justify-content-center"
                  (click)="yesterdayDate()"
                  style="height: 40px; width: 150px"
                >
                  Yesterday
                </button>
              </div>

              <div class="col-lg-1 col-md-2 col-sm-12 col-12 me-3 mt-2">
                <button
                  type="button"
                  mat-flat-button
                  class="normal-button d-flex align-items-center justify-content-center"
                  (click)="searchFilters()"
                >
                  Search
                </button>
              </div>

              <div class="col-lg-1 col-md-2 col-sm-12 col-12 clear-m mt-2">
                <button
                  type="reset"
                  mat-flat-button
                  (click)="clear()"
                  class="clear-button"
                >
                  Clear
                </button>
              </div>
            </div>
          </div>
          <ng-template #secondary>
            <div class="row ml-3" style="padding: 5px">
              <div class="col-lg-2 col-md-2 col-sm-12 mb-3">
                <mat-form-field
                  class="example-full-width mat-form-field-infix-other"
                  appearance="outline"
                  style="display: inline"
                >
                  <mat-icon matSuffix>search</mat-icon>
                  <mat-label>Employee</mat-label>
                  <input
                    matInput
                    type="text"
                    matInput
                    (input)="validateInput($event)"
                    placeholder="Search Employee"
                    formControlName="employeeId"
                    [matAutocomplete]="auto"
                    (keydown)="handleBackspace($event)"
                    (keyup)="empSearch($event)"
                  />
                  <mat-autocomplete #auto="matAutocomplete">
                    <mat-option
                      *ngFor="let emp of searchEmployees"
                      [value]="emp?.employeeId"
                      (click)="empSelect(emp)"
                    >
                      {{ emp?.employee?.employeeNumber }} - {{ emp?.name }}
                    </mat-option>
                    <mat-option
                      [value]="null"
                      *ngIf="employeeNotFound"
                      (click)="noEmployeeFound()"
                    >
                      No user Found
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </div>

              <div class="col-lg-2 col-md-2 col-sm-12">
                <mat-form-field
                  class="example-full-width"
                  appearance="outline"
                  style="display: inline"
                >
                  <mat-label>From Date</mat-label>
                  <input
                    matInput
                    placeholder="Choose a Date"
                    [matDatepicker]="picker1"
                    formControlName="fromDate"
                    (dateChange)="startDateChange($event)"
                    (dateChange)="sdate($event)"
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="picker1"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #picker1></mat-datepicker>
                </mat-form-field>
                <span
                  style="color: red; font: 12px !important"
                  *ngIf="fromDateValidation"
                >
                  Select a valid From Date.</span
                >
              </div>

              <div class="col-lg-2 col-md-2 col-sm-12 mb-3">
                <mat-form-field
                  class="example-full-width"
                  appearance="outline"
                  style="display: inline"
                >
                  <mat-label>To Date</mat-label>
                  <input
                    matInput
                    placeholder="Choose a Date"
                    [matDatepicker]="picker2"
                    formControlName="toDate"
                    (dateChange)="endDateChange($event)"
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="picker2"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #picker2></mat-datepicker>
                </mat-form-field>
                <span style="color: red" *ngIf="toDateValidation"
                  >Select a valid To Date.</span
                >
              </div>

              <div
                class="col-lg-2 col-md-2 col-sm-12 today me-4 mt-2"
                style="height: 40px; width: 150px"
              >
                <button
                  type="button"
                  mat-flat-button
                  class="normal-button d-flex align-items-center justify-content-center p-2"
                  style="height: 40px; width: 150px"
                  (click)="todaysDate()"
                >
                  Today
                </button>
              </div>
              <!-- <div class="col-lg-2 col-md-2 col-sm-6 col-12 mt-2 d-flex justify-content-center">
                <button
                  type="button"
                  mat-flat-button
                  class="normal-button  d-flex align-items-center justify-content-center p-2"
                  style="height: 40px; width: 150px;"
                  (click)="todaysDate()"
                >
                  Today
                </button>
              </div> -->

              <div
                class="col-lg-2 col-md-1 col-sm-12 today me-4 mt-2"
                style="height: 40px; width: 150px"
              >
                <button
                  type="button"
                  mat-flat-button
                  class="normal-button d-flex align-items-center justify-content-center"
                  (click)="yesterdayDate()"
                  style="height: 40px; width: 150px"
                >
                  Yesterday
                </button>
              </div>
              <!-- <div class="col-lg-2 col-md-2 col-sm-6 col-12 mt-2 d-flex justify-content-center">
                <button
                  type="button"
                  mat-flat-button
                  class="normal-button  d-flex align-items-center justify-content-center"
                  (click)=" yesterdayDate()"
                  style="height: 40px; width: 150px;"
                >
                Yesterday
                </button>
              </div> -->

              <div class="col-lg-1 col-md-2 col-sm-12 col-12 me-3 mt-2">
                <button
                  type="button"
                  mat-flat-button
                  class="normal-button d-flex align-items-center justify-content-center"
                  (click)="searchFilters()"
                >
                  Search
                </button>
              </div>

              <div class="col-lg-1 col-md-2 col-sm-12 col-12 clear-m mt-2">
                <button
                  type="reset"
                  mat-flat-button
                  (click)="clear()"
                  class="clear-button"
                >
                  Clear
                </button>
              </div>
            </div>
          </ng-template>
        </div>
      </form>

      <!-- <div class="header_action">
       
            <mat-form-field appearance="outline">
              <mat-icon matPrefix>search</mat-icon>
              <mat-label>Search</mat-label>
              <input matInput (keyup)="applyFilter($event)" placeholder="Search Employee Name" #input>
            </mat-form-field>
          </div> -->

      <div class="col-md-12" *ngIf="filterData.length > 0">
        <div class="" style="float: left">
          <!-- <span>TotalDistance:</span>{{totalDistance}} -->
        </div>
        <div class="" style="float: right; margin-top: 10px">
          <div class="row">
            <!-- <div class="col-md-6">
                        <button *ngIf="filterData?.length > 0 " mat-raised-button type="button" style="background: #c35e4c;color: #fff;height: 35px;" 
                            class="--btn --b1 download-button ml-2 exportDesign" (click)="viewLocation()" >View Location</button>
                    </div> -->
            <div class="col-md-6">
              <button
                *ngIf="filterData.length > 0"
                style="
                  background: #c35e4c;
                  color: #fff;
                  height: 35px;
                  margin-left: 20px !important;
                "
                type="button"
                (click)="exportToExcel()"
                mat-raised-button
                class="download-button ml-2 exportDesign"
              >
                Export
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="table-responsive table-container">
        <table mat-table [dataSource]="dataSource">
          <!-- Position Column -->
          <ng-container matColumnDef="locationId">
            <th mat-header-cell *matHeaderCellDef><b>S.No</b></th>
            <td mat-cell *matCellDef="let element; let i = index">
              {{ currentPageOffset + i + 1 }}
            </td>
          </ng-container>

          <!-- Name Column -->
          <ng-container matColumnDef="latitude">
            <th mat-header-cell *matHeaderCellDef>
              <b>Emp No / Name</b>
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element?.employee?.employeeNumber }} -
              {{ element.employee?.user?.name }}
            </td>
          </ng-container>

          <!-- Total Worked Hours -->

          <ng-container matColumnDef="workedhours">
            <th mat-header-cell *matHeaderCellDef>
              <b>Total Worked Hours</b>
            </th>
            <td mat-cell *matCellDef="let element">
              <span *ngIf="element?.totalWorkedTime"
                >{{ element?.totalWorkedTime }}
              </span>
              <span *ngIf="!element?.totalWorkedTime">00:00:00</span>
            </td>
          </ng-container>

          <!-- Weight Column -->
          <ng-container matColumnDef="longitude">
            <th mat-header-cell *matHeaderCellDef><b>Login Time</b></th>
            <td mat-cell *matCellDef="let element">{{ element.loginTime }}</td>
          </ng-container>

          <!-- Symbol Column -->
          <!-- <ng-container matColumnDef="address">
              <th mat-header-cell *matHeaderCellDef> <b>ADDRESS</b> </th>
              <td mat-cell *matCellDef="let element"> {{element.address}} </td>
            </ng-container> -->

          <!-- Symbol Column -->
          <ng-container matColumnDef="Action">
            <th mat-header-cell *matHeaderCellDef><b>Action</b></th>
            <td mat-cell *matCellDef="let element">
              <button
                mat-icon-button
                aria-label="Example icon-button with a menu"
                [matMenuTriggerFor]="beforeMenu"
              >
                <mat-icon>more_vert</mat-icon>
              </button>
              <div class="side_setting">
                <mat-menu #beforeMenu="matMenu" xPosition="before">
                  <a mat-menu-item (click)="editLocation(element)">
                    <span
                      class="material-icons v"
                      style="
                        transform: translateY(7px);
                        font-size: 22px;
                        margin-right: 3px;
                      "
                      >edit</span
                    >
                    Edit Location</a
                  >
                  <a mat-menu-item (click)="viewaddress(element)">
                    <span
                      class="material-icons v"
                      style="
                        transform: translateY(7px);
                        font-size: 22px;
                        margin-right: 3px;
                      "
                      >visibility</span
                    >View Address</a
                  >
                </mat-menu>
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="login data">
            <th mat-header-cell *matHeaderCellDef><b>Login Date</b></th>
            <td mat-cell *matCellDef="let element">
              {{ element.loginDate | date : "dd/MM/yyyy" }}
            </td>
          </ng-container>

          <ng-container matColumnDef="logout">
            <th mat-header-cell *matHeaderCellDef><b>Logout Time</b></th>
            <td mat-cell *matCellDef="let element">
              {{ element.logoutTime ? element.logoutTime : "--" }}
            </td>
          </ng-container>
          <ng-container matColumnDef="loginAddress">
            <th mat-header-cell *matHeaderCellDef><b>Login Address</b></th>
            <td mat-cell *matCellDef="let element">
              {{ element.loginAddress ? element.loginAddress : "--" }}
            </td>
          </ng-container>

          <ng-container matColumnDef="logoutAddress">
            <th mat-header-cell *matHeaderCellDef><b>Logout Address</b></th>
            <td mat-cell *matCellDef="let element">
              {{ element.logoutAddress ? element.logoutAddress : "--" }}
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

          <!-- Row shown when there is no matching data. -->
          <tr class="mat-row" *matNoDataRow>
            <td
              class="mat-cell"
              colspan="9"
              align="center"
              style="height: 20px; vertical-align: middle; padding: 0"
            >
              <div
                class="alert alert-secondary d-flex justify-content-center align-items-center"
                style="height: 100%; margin: 0"
              >
                <h3 style="font-size: 14px; margin: 0">No Data</h3>
              </div>
            </td>
          </tr>
        </table>

        <!-- <div class="table-container"> -->
    
        <!-- </div> -->
      </div>
      <mat-paginator
      class="paginator"
      (page)="onPageChange($event)"
      [pageSizeOptions]="[5, 10, 20, 30, 50]"
      showFirstLastButtons
    ></mat-paginator>
      <!-- <mat-paginator
        (page)="onPageChange($event)"
        [pageSizeOptions]="[5, 10, 20, 30, 50]"
        showFirstLastButtons
      ></mat-paginator> -->
    </div>
  </div>
</ng-template>
