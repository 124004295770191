<ng-container *ngIf="isLoading; else elseTemplate">
  <div class="loading-wrapper">
    <div class="loading">
      <app-loading></app-loading>
      <br />
      <h1>Please Wait Loading...</h1>
    </div>
  </div>
</ng-container>

<ng-template #elseTemplate>
  <div class="container">
    <div class="mat-elevation-z8">
      <div class="header_action" style="height: 59px !important">
        <!-- <button mat-flat-button>
          <span class="material-icons">
            location_on
          </span>
          <span>
            Assign Location
          </span>
        </button> -->
        <!-- style="background: #b0636a !important; color: white; height: 39px!important"
        mat-flat-button -->
        <button
          mat-flat-button
          (click)="openCreateUserDialog()"
          style="background: #d97b54 !important; color: white; height: 59px!"
        >
          <!-- <span class="material-icons"> add </span> -->
          <span>Create User</span>
        </button>

        <!-- <button mat-flat-button (click)="UploadUserDialog()">
          <span class="material-icons"> add </span>
          <span> User Upload</span>
        </button>

        <button
          mat-flat-button
          href="assets/template/UsersTemplate.csv"
          download="UsersTemplate.csv"
        >
          <span class="material-icons"> download </span>
          <a
            href="assets/template/UsersTemplate.csv"
            download="UsersTemplate.csv"
          >
            Download Template
          </a>
        </button> -->

        <mat-form-field appearance="outline">
          <mat-icon matPrefix>search</mat-icon>
          <mat-label>Search</mat-label>
          <input
            matInput
            (keyup)="applyFilter($event)"
            placeholder="Search name/ Email/ Emp Contact No."
            #input
          />
        </mat-form-field>
      </div>
      <!-- <div class="filter">
    
      </div> -->

      <div class="col-md-12" style="float: right" *ngIf="btn">
        <button type="button" class="btn b1" (click)="viewLocation()">
          View User Location
        </button>
      </div>
      <div class="table-wrapper">
        <div class="table-responsive">
          <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
            <!-- Position Column -->
            <ng-container matColumnDef="userId">
              <th mat-header-cell *matHeaderCellDef><b>S.No</b></th>
              <td mat-cell *matCellDef="let element; let i = index">
                {{ currentPageOffset + i + 1 }}
              </td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef><b>Emp. Name</b></th>
              <td mat-cell *matCellDef="let element">{{ element.name }}</td>
            </ng-container>

            <!-- Emp-ID Column -->
            <ng-container matColumnDef="Empno">
              <th mat-header-cell *matHeaderCellDef><b>Emp-ID</b></th>
              <td mat-cell *matCellDef="let element">
                {{
                  element.employee?.employeeNumber
                    ? element.employee.employeeNumber
                    : "--"
                }}
              </td>
            </ng-container>

            <!-- Email Column -->
            <ng-container matColumnDef="email">
              <th mat-header-cell *matHeaderCellDef><b>Email-ID</b></th>
              <td mat-cell *matCellDef="let element">{{ element.email }}</td>
            </ng-container>

            <!-- Contact Column -->
            <ng-container matColumnDef="phoneNumber">
              <th mat-header-cell *matHeaderCellDef><b>Emp. Contact No.</b></th>
              <td mat-cell *matCellDef="let element">
                {{ element.phoneNumber }}
              </td>
            </ng-container>

            <!-- Client Name Column -->
            <ng-container matColumnDef="clientname">
              <th mat-header-cell *matHeaderCellDef><b>Client Name</b></th>
              <td mat-cell *matCellDef="let element">
                <ng-container
                  *ngIf="element?.employee?.clients?.length > 0; else noclient"
                >
                  {{ element.employee.clients[0]?.clientName }}
                  <br />
                  <a
                    href="javascript:void(0)"
                    *ngIf="element?.employee?.clients?.length > 1"
                    (click)="openDialog(element.employee.clients, 'clientName')"
                    style="font-size: x-small; color: #0a58ca"
                  >
                    Show More
                  </a>
                </ng-container>
                <ng-template #noclient> -- </ng-template>
              </td>
            </ng-container>

            <!-- Supervisor Column -->
            <ng-container matColumnDef="supervisor">
              <th mat-header-cell *matHeaderCellDef><b>Supervisor</b></th>
              <td mat-cell *matCellDef="let element">
                {{ element.buddyUser?.name || "--" }}
              </td>
            </ng-container>

            <!-- Contact Number -->
            <ng-container matColumnDef="supervisor-contactno">
              <th mat-header-cell *matHeaderCellDef><b>Contact No.</b></th>
              <td mat-cell *matCellDef="let element">
                {{ element.buddyUser?.phoneNumber || "--" }}
              </td>
            </ng-container>

            <!-- User Type -->
            <ng-container matColumnDef="userType">
              <th mat-header-cell *matHeaderCellDef><b>User Type</b></th>
              <td mat-cell *matCellDef="let element">
                {{ element.userType[0]?.userTypeName }}
              </td>
            </ng-container>

            <!-- User Status -->
            <ng-container matColumnDef="userstatus">
              <th mat-header-cell *matHeaderCellDef><b>Status</b></th>
              <td
                mat-cell
                *matCellDef="let element"
                [ngStyle]="{
                  color: element.status === 'Active' ? 'green' : 'red'
                }"
              >
                {{ element.status }}
              </td>
            </ng-container>

            <!-- Actions -->
            <ng-container matColumnDef="Action">
              <th mat-header-cell *matHeaderCellDef><b>Action</b></th>
              <td mat-cell *matCellDef="let element">
                <button
                  mat-icon-button
                  aria-label="Actions"
                  [matMenuTriggerFor]="actionMenu"
                >
                  <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #actionMenu="matMenu">
                  <button mat-menu-item (click)="viewUser(element)">
                    <mat-icon>visibility</mat-icon> View
                  </button>
                  <button mat-menu-item (click)="editUser(element)">
                    <mat-icon>edit</mat-icon> Edit
                  </button>
                  <button
                    mat-menu-item
                    *ngIf="element.status === 'Active'"
                    (click)="userupdateStatus(element.userId, 'InActive')"
                  >
                    <mat-icon>block</mat-icon> In-Active
                  </button>
                  <button
                    mat-menu-item
                    *ngIf="element.status === 'InActive'"
                    (click)="userupdateStatus(element.userId, 'Active')"
                  >
                    <mat-icon>check</mat-icon> Active
                  </button>
                </mat-menu>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

            <!-- No Data Row -->
            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="11" align="center">
                <div class="alert alert-secondary">
                  <h3>No User List Found {{ input.value }}</h3>
                </div>
              </td>
            </tr>
          </table>
        </div>
      </div>

      <mat-paginator
        (page)="onPageChange($event)"
        [pageSizeOptions]="[5, 10, 20, 30, 50]"
        showFirstLastButtons
      ></mat-paginator>
    </div>
  </div>
</ng-template>
<!-- <div *ngIf="list_loader" class="align-items-center text-center">
  <div class="spinner-border" 
  style=" width: 5rem;
  height: 5rem;
  color: #732b47;
  position: relative;
  bottom: 0;
  align-items: center;" role="status"></div>
</div> -->
